import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@playful/design_system';
import React from 'react';

import useDisclosure from '../../utils/useDisclosure';
import { useQueryStringActions } from '../../workbench/hooks/useQueryStringActions';
import PresentImage from './welcome-present.svg';

export const WelcomeSubscriptionModal = () => {
  const { isOpen, onClose: closeDialog, onOpen } = useDisclosure();
  const { handleEndAction: onClose } = useQueryStringActions({
    startAction: onOpen,
    endAction: closeDialog,
    actionName: 'subscribe-success',
  });

  return (
    <Modal size='xs' isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box position='relative' h='220px'>
          <Box position='absolute' top='-95px' left='-34px'>
            <PresentImage />
          </Box>
        </Box>
        <ModalHeader textAlign='center'>
          <Heading fontSize='2xl' fontWeight='bold'>
            Welcome to Hatch Pro!
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text textAlign='center' fontSize='sm'>
            More projects, more pages, and more customization are yours! We can’t wait to see what
            you create.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='yellow' variant='solid' w='100%' onClick={onClose}>
            let’s go!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
