import { isClientCtx } from '@playful/utils';
import { useEffect, useState } from 'react';

export function useLocalStorageState<T>(defaultValue: T, key: string) {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    if (!isClientCtx()) return;

    const value = localStorage.getItem(key);

    try {
      if (value === null) return;
      if (value === 'undefined') setValue(undefined as T);

      setValue(JSON.parse(value));
    } catch (e) {
      console.error(e);
    }
  }, [key]);

  useEffect(() => {
    if (!isClientCtx()) return;

    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  }, [key, value]);

  return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T>>];
}
