import type { User } from '@playful/api';
import { ProjectId, ProjectInfo, ResourceId } from '@playful/runtime';
import {
  UnsavedProjectInfo,
  deleteProject,
  getProjectInfoByIdPath,
  getProjectsByUserId,
  duplicateProject,
  rollbackProject,
} from '@playful/workbench/api/projects';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';

import { hasMatchingTags } from '../explorer/tags';

export function useSynchronizeProjects(projectInfos: ProjectInfo[] = []) {
  const { mutate } = useSWRConfig();

  const sync = useCallback(
    async (infos: ProjectInfo[]) => {
      const promises = [];

      for (const info of infos) {
        promises.push(mutate(getProjectInfoByIdPath(info.id), info, { revalidate: false }));
      }

      await Promise.all(promises);

      return infos;
    },
    [mutate],
  );

  useEffect(() => {
    sync(projectInfos);
  }, [projectInfos, sync]);

  return sync;
}

export function useUserProjects(
  userId?: string,
  fallbackData?: ProjectInfo[],
  opts?: SWRConfiguration,
) {
  const { mutate: globalMutate } = useSWRConfig();
  const syncInfoCache = useSynchronizeProjects(fallbackData);

  const { data, mutate, isLoading, error } = useSWR(
    userId && `users/${userId}/projects`,
    async () => (userId ? await syncInfoCache(await getProjectsByUserId(userId)) : []),
    { revalidateOnFocus: false, fallbackData, ...opts },
  );

  const projectInfos = useMemo(() => data ?? [], [data]);

  const newProjectInfo = useCallback(
    (user: User, title: string): UnsavedProjectInfo => {
      const titleSuggestion = suggestTitle(title, projectInfos);

      return {
        owner: user.id,
        ownerName: user.name,
        title: titleSuggestion,
        template: '',
        project: '',
        slug: '',
        publishedUrl: '',
        permissions: {
          locked: false,
          showInGallery: false,
          allowRemixing: false,
          showLogo: true,
        },
        password: '',
      };
    },
    [projectInfos],
  );

  // Duplicate a project
  const duplicateProjectMutation = useCallback(
    async (
      info: ProjectInfo,
      options: {
        title?: string;
        snapshotId?: ResourceId;
      } = {},
    ): Promise<{
      info: ProjectInfo;
    }> => {
      const { title, snapshotId } = options;

      // no Copy of Copy of, only Copy of copy of
      const titleCandidate = info.title.startsWith('Copy of')
        ? info.title.charAt(0).toLowerCase() + info.title.slice(1)
        : info.title;

      const duplicateProjectInfo = await duplicateProject(info.id, snapshotId, {
        title: title ?? `Copy of ${titleCandidate}`,
      });

      if (!duplicateProjectInfo) {
        throw new Error('Failed to duplicate project');
      }

      const updatedInfos = [...projectInfos, duplicateProjectInfo];

      globalMutate(getProjectInfoByIdPath(duplicateProjectInfo.id), duplicateProjectInfo);

      // update the cache, no need to revalidate
      mutate(() => updatedInfos, { populateCache: true, revalidate: false });

      return {
        info: duplicateProjectInfo,
      };
    },
    [mutate, projectInfos, globalMutate],
  );

  // Delete a project
  const deleteProjectMutation = useCallback(
    (id: ProjectId) => {
      const updatedList = projectInfos.filter((projectInfo) => projectInfo.id !== id);

      return mutate(
        async () => {
          await deleteProject(id);

          return updatedList;
        },
        {
          optimisticData: updatedList,
          rollbackOnError: true,
        },
      );
    },
    [mutate, projectInfos],
  );

  // Rollback a project to a previous snapshot
  const rollbackProjectMutation = useCallback(
    async (projectId: ProjectId, snapshotId: ResourceId) => {
      const rolledbackInfo = await rollbackProject(projectId, snapshotId);
      const filteredInfos = projectInfos.filter((info) => info.id !== rolledbackInfo.id);
      const updatedInfos = [...filteredInfos, rolledbackInfo];

      // update the cache, no need to revalidate
      mutate(() => updatedInfos, { populateCache: true, revalidate: false });
    },
    [mutate, projectInfos],
  );

  return {
    error,
    mutate,
    projectInfos,
    newProjectInfo,
    isLoading: isLoading && !data,
    duplicateProject: duplicateProjectMutation,
    deleteProject: deleteProjectMutation,
    rollbackProject: rollbackProjectMutation,
  };
}

export const useFilteredProjects = (projectInfos: ProjectInfo[], tags?: string[]) => {
  return useMemo(() => {
    return projectInfos
      .filter((info) => {
        if (!tags || tags.length === 0) return true;

        if (hasMatchingTags(['_blocked'], info.tags)) return false;

        return hasMatchingTags(tags, info.tags);
      })
      .sort((a, b) => b.modified - a.modified);
  }, [projectInfos, tags]);
};

function suggestTitle(title: string, projectInfos: ProjectInfo[]): string {
  const suffix = suggestSuffix(
    title,
    projectInfos.map((info) => info.title),
    false,
    true,
  );
  return title + (suffix ? ' ' + suffix : '');
}

function suggestSuffix(
  baseName: string,
  takenNames: string[],
  alwaysSuffix = false,
  space = false,
  delim = ' ',
): string {
  let n = 1;

  while (true) {
    const candidateName = !alwaysSuffix ? baseName : baseName + (space ? delim : '') + n;
    if (!takenNames.includes(candidateName)) {
      return !alwaysSuffix ? '' : String(n);
    }
    alwaysSuffix = true;
    n++;
  }
}
