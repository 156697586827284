import { forEachObject, getDOMWindow } from '@playful/utils';
import { registerMigration } from '../registry.js';
const TEXT_TYPES = [
    'Play Kit/Text',
    'Play Kit/Subheading',
    'Play Kit/BodyText',
    'Play Kit/Heading',
];
const migration = {
    description: 'Find links that are supposed to be websites but didnt get the href applied in previous migration, fix links',
    draft: false,
    async migrate(state) {
        const { DOMParser } = getDOMWindow();
        forEachObject(state, (obj) => {
            if (TEXT_TYPES.includes(obj.componentType)) {
                if (!obj.linkTo && obj.linkAddress) {
                    const url = obj.linkAddress;
                    const doc = new DOMParser().parseFromString(obj.html, 'text/html');
                    doc.body.childNodes.forEach((node) => {
                        // look for links without an href
                        node.querySelectorAll('a:not([href])').forEach((link) => {
                            const linkToAttribute = link.getAttribute('data-linkto') || '';
                            // double-check we're only dealing with links that are websites
                            if (!['email', 'phone', 'page'].includes(linkToAttribute)) {
                                link.setAttribute('href', url);
                                link.setAttribute('data-linkaddress', url);
                                link.setAttribute('data-linkto', 'website');
                            }
                        });
                    });
                    obj.html = doc.body.innerHTML;
                }
            }
        });
    },
};
registerMigration(migration, import.meta.url);
