import { Badge, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import type { PriceInfoObject } from '@playful/api';
import { getConfig } from '@playful/config';
import { Box, Button, HStack, Heading, Switch, Text } from '@playful/design_system';
import { projectDesignState } from '@playful/workbench/project/projectDesignState';
import React, { useState } from 'react';
import { useSnapshot } from 'valtio';

import { redirectToManageSubscription } from '../user/stripeRedirects';
import { useUserContext } from '../user/UserContext';

type PricingChoosePlanProps = {
  handleSelectProduct: (product: string) => void;
  handleClickFreePlan: () => void;
  priceInfo?: PriceInfoObject;
};

export const PricingChoosePlan = ({
  handleSelectProduct,
  handleClickFreePlan,
  priceInfo: stripePriceInfo,
}: PricingChoosePlanProps) => {
  const { stripeYearlyPlan, stripeMonthlyPlan } = getConfig();
  const { dirtyCount } = useSnapshot(projectDesignState);
  const { isLoggedInUser, user, hasActiveSubscription } = useUserContext();

  const [isYearly, setIsYearly] = useState(false);

  // If price info does not load, using as default
  const defaultPriceInfo = {
    monthlyPrice: 9,
    yearlyPrice: 108,
    annualSavingsPercent: 0,
  };

  const priceInfo = stripePriceInfo ?? defaultPriceInfo;
  const { monthlyPrice, yearlyPrice, annualSavingsPercent } = priceInfo;
  const proPrice = isYearly ? yearlyPrice : monthlyPrice;

  const handleProClick = () => {
    // short circuit and redirect pro users to manage subscription
    if (hasActiveSubscription) {
      if (user.stripeCustomerId === undefined) return;
      return redirectToManageSubscription(user.stripeCustomerId, user.id);
    }

    const plan = isYearly ? stripeYearlyPlan : stripeMonthlyPlan;
    handleSelectProduct(plan);
  };

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const upgradeOrRenewText = !!user.subscriptionStatus ? 'renew subscription' : 'upgrade to pro';

  return (
    <>
      <Heading size='lg' textAlign={{ base: 'left', lg: 'center' }} py={5} pl={{ base: 10, lg: 0 }}>
        choose a plan
      </Heading>
      <Box
        display='flex'
        justifyContent={{ base: 'flex-start', lg: 'center' }}
        pl={{ base: 10, lg: 0 }}
        mb={10}
      >
        <HStack spacing={3}>
          <Text fontWeight='medium'>monthly</Text>
          <Switch
            size='lg'
            colorScheme={'purple'}
            isChecked={isYearly}
            onChange={(e) => setIsYearly(e.target.checked)}
          />
          <Box display='flex' position='relative'>
            <Text fontWeight='medium' mr={2}>
              yearly
            </Text>
            {annualSavingsPercent ? (
              <Badge colorScheme='purple' size='sm'>
                save {annualSavingsPercent}%
              </Badge>
            ) : undefined}
          </Box>
        </HStack>
      </Box>
      <Box display='flex' w='100%' justifyContent='space-between' flexWrap='wrap-reverse'>
        <Box flex={1} p={10} w={{ base: '100%', lg: 'auto' }}>
          <VStack spacing={4} align='left'>
            <Text fontSize='lg' fontWeight='bold'>
              Hatch Free
            </Text>
            <Box>
              <Heading size='2xl'>{currency.format(0)}</Heading>
            </Box>
            <UnorderedList style={{ marginLeft: '1em' }} fontSize='lg' stylePosition='outside'>
              <ListItem>
                Hatch hosting & subdomain<div>(yourname.hatch.one)</div>
              </ListItem>
              <ListItem>Hatch watermark</ListItem>
              <ListItem>3 published projects</ListItem>
              <ListItem>3 pages per project</ListItem>
              <ListItem>Unlimited community projects</ListItem>
              <ListItem>2GB media storage</ListItem>
              <ListItem>Standard customer support</ListItem>
            </UnorderedList>
            {!hasActiveSubscription && (
              <Box>
                <Button size='lg' display='inline' onClick={handleClickFreePlan}>
                  {!isLoggedInUser ? 'sign up' : 'stick with free'}
                </Button>
              </Box>
            )}
          </VStack>
        </Box>
        <Box
          flex={1}
          w={{ base: '100%', lg: 'auto' }}
          p={10}
          backgroundColor='purple.600'
          borderRadius={{ base: 0, lg: 'lg' }}
          boxShadow='lg'
          color='white'
        >
          <VStack spacing={4} align='left'>
            <Text fontSize='lg' fontWeight='bold'>
              Hatch Pro (early bird pricing)
            </Text>
            <Box display='flex' alignItems='center'>
              {proPrice && (
                <>
                  <Heading size='2xl' mr={2}>
                    {currency.format(proPrice)} <s>{currency.format(isYearly ? 156 : 13)}</s>
                  </Heading>
                  <Box display='flex' flexDirection='column'>
                    <Text fontSize='xs'>per {isYearly ? 'year' : 'month'}</Text>
                  </Box>
                </>
              )}
            </Box>
            <UnorderedList style={{ marginLeft: '1em' }} fontSize='lg' stylePosition='outside'>
              <ListItem>Hatch hosting</ListItem>
              <ListItem>Free custom domain name</ListItem>
              <ListItem>Bring your own domain</ListItem>
              <ListItem>Removable Hatch watermark</ListItem>
              <ListItem>Add your Google Analytics ID</ListItem>
              <ListItem>Add site title, meta description</ListItem>
              <ListItem>Set search engine visibility</ListItem>
              <ListItem>Add password protection</ListItem>
              <ListItem>20 published projects</ListItem>
              <ListItem>Unlimited pages per project</ListItem>
              <ListItem>Unlimited community projects</ListItem>
              <ListItem>10GB media storage</ListItem>
              <ListItem>Priority customer support</ListItem>
            </UnorderedList>
            <Box>
              <Button
                display='inline-block'
                variant='solid'
                size='lg'
                backgroundColor='yellow.500'
                _hover={{ backgroundColor: 'yellow.200' }}
                _active={{ backgroundColor: 'yellow.300' }}
                onClick={handleProClick}
                isLoading={dirtyCount !== 0}
              >
                {hasActiveSubscription ? 'manage subscription' : upgradeOrRenewText}
              </Button>
            </Box>
          </VStack>
        </Box>
      </Box>
    </>
  );
};
