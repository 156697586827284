import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Moves Physics properties from effect into container component - attempt #3',
    //
    // This migration is a follow-up to the previous migration 202402231327-move-physics-from-effect-into-container-try2.ts
    // It fixes a logic bug that migrated action properties values incorrectly.
    async migrate(state) {
        const physicsObjectIds = {};
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page' || obj.componentType === 'Play Kit/Container') {
                // look for Physics children components (aka gravity canvas elements)
                const physics = obj.children?.find((c) => c.componentType === 'Play Kit/Physics');
                if (physics) {
                    physicsObjectIds[physics[ID]] = obj[ID];
                }
            }
        });
        function migrateActions(actions) {
            if (!actions)
                return;
            for (const action of actions) {
                if (physicsObjectIds[action.targetId]) {
                    // this was previously outside of the above if block and run too often
                    if (action?.args?.property?.value === 'enabled') {
                        action.args.property.value = 'physicsEnabled';
                    }
                }
                else {
                    // restore the old value from incorrect logic in previous migration
                    if (action?.args?.property?.value === 'physicsEnabled') {
                        action.args.property.value = 'enabled';
                    }
                }
            }
        }
        forEachObject(state, (obj) => {
            if (obj.key && obj.actions) {
                migrateActions(obj.actions);
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
