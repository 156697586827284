import { isSection } from './component.js';
export function isOnlyPage(reactor) {
    const pages = reactor.project?.pages;
    if (!pages) {
        return false;
    }
    return pages.length === 1 && pages[0] === reactor;
}
export function isOnlySection(reactor) {
    const sections = reactor?.project?.rootView?.children;
    if (!sections) {
        return false;
    }
    return sections.length === 1 && sections[0] === reactor;
}
export function rootViewOnlyContainsOneSection(reactor) {
    const sections = reactor?.project?.rootView?.children;
    if (!sections) {
        return false;
    }
    return sections.length === 1 && isSection(sections[0]);
}
export function selectionContainsAllPagesInProject(selection) {
    const pagesInSelection = selection.filter((component) => isPage(component));
    if (!pagesInSelection[0])
        return false;
    const pagesInProject = pagesInSelection[0].project.pages;
    const pagesNotInSelection = pagesInProject.filter((page) => !pagesInSelection.includes(page));
    return pagesNotInSelection.length < 1;
}
export function selectionContainsAllSectionsOnPage(selection) {
    const sectionsInSelection = selection.filter((component) => isSection(component));
    if (!sectionsInSelection[0])
        return false;
    const sectionsOnPage = sectionsInSelection[0].project.rootView.children;
    const sectionsNotInSelection = sectionsOnPage.filter((section) => !sectionsInSelection.includes(section));
    return sectionsNotInSelection.length < 1;
}
export function isPage(component) {
    return component.componentType === 'Play Kit/Page';
}
// Return true if this project has content that should be hidden from LogRocket recordings
export function hasPrivateContent(info) {
    const allowedUsers = ['play', 'gallery', 'hatch', 'remixables', 'links'];
    return !allowedUsers.includes(info.ownerName) && !info.permissions.showInGallery;
}
