import { getAuth, getFirebaseAuthToken } from '@playful/api';
import { onAuthError } from '@playful/telemetry';
import {
  AuthError,
  AuthErrorCodes,
  AuthProvider,
  EmailAuthCredential,
  OAuthCredential,
  OAuthProvider,
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  linkWithCredential,
  linkWithPopup,
  linkWithRedirect,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';

import type { AuthSchema } from './AuthFlow/authSchema';
import { keyCopyFromToken } from './AuthFlow/localStorage';
import { completeAuth } from './completeAuth';

export type AuthPayload = {
  provider?: AuthProvider;
  credential?: OAuthCredential | EmailAuthCredential;
  email?: string;
  password?: string;
  isNewUser: boolean;
  redirect?: boolean;
  mobileProjectLink?: string;
};
export type SignInSchema = Pick<AuthSchema, 'email' | 'password'>;
export type SignUpSchema = Pick<AuthSchema, 'email' | 'password'>;

export async function authenticateWithCredential({
  credential,
  email,
  password,
  isNewUser,
  redirect,
  provider,
  mobileProjectLink,
}: AuthPayload) {
  let err;

  const auth = getAuth();
  if (auth.currentUser?.isAnonymous) {
    // If the user previously has another account AND is currently signed into an anonymous account,
    // we must copy the project from the anonymous account. Grab the current firebaseAuthToken for the pending
    // `copy_from` API request
    //
    // NOTE: because we may redirect to complete the login, we MUST set the copy from token in localstorage
    // before we authenticate
    const copyFromToken = await getFirebaseAuthToken();
    if (copyFromToken) localStorage.setItem(keyCopyFromToken, copyFromToken);

    // If linkWithCredential returns the same user id, we can skip the copy
    const anonUserId = auth.currentUser.uid;
    try {
      if (credential) {
        const usercred = await linkWithCredential(auth.currentUser, credential);
        if (anonUserId === usercred.user.uid) {
          // We've simply linked accounts, skip copy.
          localStorage.removeItem(keyCopyFromToken);
        }

        return completeAuth(usercred, mobileProjectLink);
      } else {
        const method = redirect ? linkWithRedirect : linkWithPopup;
        if (!provider) {
          console.error('missing provider');
          return;
        }
        const result = await method(auth.currentUser, provider, browserPopupRedirectResolver);
        if (anonUserId === result.user.uid) {
          // We've simply linked accounts, skip copy.
          localStorage.removeItem(keyCopyFromToken);
        }
        if (result) return completeAuth(result);
      }
    } catch (e) {
      err = e as AuthError;
      if (
        err.code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE ||
        err.code === AuthErrorCodes.EMAIL_EXISTS
      ) {
        const errCredential = OAuthProvider.credentialFromError(err);
        return await signIn({
          credential: errCredential || credential,
          isNewUser: false,
          provider,
          redirect,
        });
      } else {
        onAuthError(
          {
            code: err.code,
            method: credential?.providerId || provider?.providerId || 'unknown_provider',
          },
          isNewUser,
        );
        throw err;
      }
    }
  } else {
    return signIn({ credential, isNewUser, email, password, provider, redirect });
  }
}

async function signIn({ isNewUser, credential, email, password, provider, redirect }: AuthPayload) {
  let err;
  let usercred;

  try {
    if (!isNewUser && credential) {
      usercred = await signInWithCredential(getAuth(), credential);
    } else if (email && password) {
      usercred = await createUserWithEmailAndPassword(getAuth(), email, password);
    } else if (provider) {
      const method = redirect ? signInWithRedirect : signInWithPopup;
      usercred = await method(getAuth(), provider, browserPopupRedirectResolver);
    } else {
      console.error('should not be here: signup as new user without email/password');
    }
  } catch (e) {
    err = e as AuthError;
    // Do they already have an account (or are retrying from an authenticate error)?
    // Um, but also, if they enter the right pw, they get logged in
    if (err?.code === AuthErrorCodes.EMAIL_EXISTS && email && password) {
      try {
        usercred = await signInWithEmailAndPassword(getAuth(), email, password);
        err = undefined;
      } catch (signInErr) {
        // ignore
      }
    }
  }
  if (err) {
    onAuthError(
      {
        code: err.code,
        method: credential?.providerId || provider?.providerId || 'unknown_provider',
      },
      isNewUser,
    );
    throw err;
  }
  if (usercred) return completeAuth(usercred);
}
