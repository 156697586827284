import { defineStyleConfig } from '@chakra-ui/react';
export const tabsTheme = defineStyleConfig({
    defaultProps: { size: 'md', variant: 'line', colorScheme: 'blue' },
    baseStyle: ({ orientation }) => ({
        root: { display: 'block' },
        tab: {
            fontWeight: 700,
            transitionProperty: 'common',
            transitionDuration: 'normal',
            _focus: { zIndex: 1, boxShadow: 'outline' },
        },
        tablist: {
            justifyContent: 'flex-start',
            flexDirection: orientation === 'vertical' ? 'column' : 'row',
        },
        tabpanel: { p: 4 },
    }),
    variants: {
        line: {
            tablist: { borderBottom: '0.125rem solid', borderColor: 'gray.100' },
            tab: {
                borderBottom: '0.125rem solid',
                borderColor: 'transparent',
                marginBottom: '-0.125rem',
                _selected: { color: 'blue.500', borderColor: 'currentColor' },
                _active: { bg: 'gray.100' },
                _disabled: { opacity: 0.4, cursor: 'not-allowed' },
            },
        },
        solid: {
            tab: {
                fontWeight: 'medium',
                borderRadius: 'full',
                bg: 'transparent',
                color: 'gray.500',
                _hover: { bg: 'gray.50' },
                _focus: { boxShadow: 'none' },
                _focusVisible: {
                    outline: '0.125rem solid',
                    outlineColor: 'gray.800',
                    outlineOffset: '0.125rem',
                },
                _selected: { bg: 'blue.500', color: 'white', fontWeight: 'bold' },
            },
        },
        contentToolbar: {
            tab: {
                bg: { base: 'gray.900', md: 'gray.50' },
                color: { base: 'gray.200', md: 'gray.500' },
                w: { base: 'auto', md: '72px' },
                h: { base: '80px', md: '56px' },
                flex: 'none',
                '.caret': { opacity: 0 },
                _hover: { color: { base: null, md: 'gray.700' } },
                _focus: { boxShadow: 'none' },
                _focusVisible: {
                    bg: { base: 'gray.900', md: 'gray.50' },
                    outline: 'none',
                    outlineColor: { base: null, md: 'gray.800' },
                },
                _selected: {
                    color: { base: 'blue.400', md: 'blue.500' },
                    '.caret': { opacity: 1 },
                },
            },
        },
    },
    sizes: {
        sm: { tab: { fontSize: 'sm', lineHeight: '0.875rem', py: 2, px: 4 } },
        md: { tab: { fontSize: 'md', py: 2, px: 4 } },
        lg: { tab: { fontSize: 'lg', py: 3, px: 4 } },
    },
});
