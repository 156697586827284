import { DESCRIPTION } from './component.js';
import { ID, isReactor, } from './reactor.js';
// Private internals
const _proxy = Symbol('_proxy');
const _target = Symbol('_target');
export const factory = Symbol('factory');
const dirty = Symbol('dirty');
const changeListeners = Symbol('changeListeners');
const notifyListeners = Symbol('notifyListeners');
const updateListeners = Symbol('updateListeners');
const notifyUpdateListeners = Symbol('notifyUpdateListeners');
export const updatePrototypeChain = Symbol('updatePrototypeChain');
export const setReactorStoredProperty = Symbol('setReactorStoredProperty');
export const getReactorStoredProperty = Symbol('getReactorStoredProperty');
export const hasReactorStoredProperty = Symbol('hasReactorStoredProperty');
export const deleteReactorStoredProperty = Symbol('deleteReactorStoredProperty');
const evaluateStoredProperty = Symbol('evaluateExpressionProperty');
export const META = Symbol('META');
export const defaults = Symbol('defaults');
// Return true if a given property should trigger invalidation/notification
function shouldNotify(property) {
    // Always notify for metadata related props
    if (property === META || property === '_meta' || property === '$_meta') {
        return true;
    }
    // Underscore-prefixed or symbol properties don't cause notifications or invalidation.
    if (typeof property === 'symbol' || property[0] === '_') {
        return false;
    }
    return true;
}
// Proxy handlers (AKA "traps")
// this = the handlers object
// target = The underlying object that we're proxying
// receiver = the proxy
// NOTE: When a proxy is on a prototype chain its set trap is called with the
// inheriting object as the receiver and the proxy as the target.
const reactorObjectTraps = {
    // TODO: rewrite as defineProperty trap? More comprehensive and bullet proof?
    set(target, property, value, receiver) {
        if (property === ID) {
            throw `ID is readonly`;
        }
        if (typeof property === 'string' && property[0] === '$' && property !== '$children') {
            console.assert(false, `Cannot set stored properties directly. ${property} on ${target?.componentType ?? target?.name}`);
            receiver[setReactorStoredProperty](property.slice(1), value);
            return true;
        }
        if (property === '$children') {
            receiver[setReactorStoredProperty](property.slice(1), value);
        }
        const oldValue = target[property];
        if (value !== oldValue || (value === undefined && !target.hasOwnProperty(property))) {
            // Figure out what kind of change it is before we change it!
            const changeType = property in target ? 'change' : 'add';
            // Use Reflect.defineProperty instead of Relect.set will cause the set trap to be
            // called again the proxy is on a prototype chain.
            Reflect.defineProperty(receiver, property, {
                value,
                writable: true,
                configurable: true,
                enumerable: true,
            });
            if (shouldNotify(property)) {
                receiver.invalidate(property);
                target[notifyListeners](property, value, oldValue, changeType);
            }
            // If we're replacing a reactor, dispose the old value
            // TODO(jjhuff): This is commented out since it triggers bugs in projectDesigner
            /*      if (oldValue && isReactor(oldValue)) {
              oldValue.dispose();
            }*/
        }
        return true;
    },
    get(target, property, receiver) {
        if (typeof property === 'string' &&
            property[0] === '$' &&
            property !== '$children' &&
            property !== '$$typeof' // React debug isValidElement() looks for this.
        ) {
            console.assert(false, `Cannot get stored properties directly. ${property} on ${target?.componentType ?? target?.name}`);
            return Reflect.get(target, property, receiver);
        }
        return Reflect.get(target, property, receiver);
    },
    deleteProperty(target, property) {
        if (typeof property === 'string' && property[0] === '$') {
            // delete the evaluated version
            // This causes the deleteProperty trap to be called again (case below).
            console.assert(false, `Cannot delete stored properties directly. ${property} on ${target?.componentType ?? target?.name}`);
            return target[deleteReactorStoredProperty](property.slice(1));
        }
        const oldValue = target[property];
        const success = Reflect.deleteProperty(target, property);
        if (success && shouldNotify(property)) {
            target[_proxy].invalidate(property);
            target[notifyListeners](property, undefined, oldValue, 'remove');
        }
        return success;
    },
    // TODO: is this even needed?
    getOwnPropertyDescriptor(target, property) {
        // Reactors don't have any unscopables.
        if (property === Symbol.unscopables) {
            return undefined;
        }
        const descriptor = Reflect.getOwnPropertyDescriptor(target, property);
        if (descriptor) {
            return descriptor;
        }
        return undefined;
    },
    // TODO: do this in getOwnPropertyDescriptor trap instead?
    // Filter out properties end-users did not explicitly create (aka "surprise properties").
    // This includes stored ($-prefixed) properties, project, etc.
    ownKeys(target) {
        const keys = Reflect.ownKeys(target);
        return keys.filter((key) => {
            if (typeof key === 'symbol' || (typeof key === 'string' && key[0] === '$')) {
                return false;
            }
            return true;
        });
    },
};
export const ReactorObjectPrototype = {
    iam: 'ReactorObjectPrototype',
    // This the prototype so all these live on the instance.
    // We declare them here anyway to make Typescript happy.
    _isReactor: true,
    [ID]: undefined,
    [_proxy]: undefined,
    [_target]: undefined,
    [factory]: undefined,
    [changeListeners]: undefined,
    [updateListeners]: undefined,
    // Return a POJO copy of the Reactor with the "live" values of the "own stored" properties
    // of the original. Child objects and arrays are recursively copied the same way.
    // Inherited properties are not included unless they have been overwritten by the inheritor.
    // Default values for described properties (including all those inherited) can optionally be
    // incorporated into the copy. Reactor and ReactorArray IDs are included in the copy.
    // Dynamic properties (i.e. added at runtime), _meta and all other "_" properties are not copied
    // as they are not "own stored" or "described".
    // The primary use case for getState is to snapshot the state of a Reactor for serialization or
    // change detection.
    // NOTE: Non-Reactor child objects and arrays are copied by reference!
    getState(includeDefaultValues = false) {
        return this._getState({ initial: true, defaults: includeDefaultValues });
    },
    // Returns the current/running state of the object.
    getCurrentState() {
        return this._getState({ initial: false, defaults: true });
    },
    // TODO: option to removeIds?
    _getState(options) {
        const { defaults, initial } = options;
        const state = {
            [ID]: this[ID],
        };
        const expressions = this.getOwnStoredProperties();
        for (const property in expressions) {
            let value = initial ? expressions[property] : this[property];
            // Only return Component Object property default values by request.
            if (!defaults && this[META]) {
                const defaultValue = this[META]?.properties?.[property]?.default;
                // TODO: Not smart enough to filter out objects, arrays.
                if (value === defaultValue) {
                    continue;
                }
            }
            if (Array.isArray(value)) {
                const id = value[ID];
                value = value.map((v) => {
                    if (isReactor(v)) {
                        return v._getState(options);
                    }
                    else {
                        return v;
                    }
                });
                // Do not lose the ID of the array. Undo needs it.
                if (id !== undefined) {
                    value[ID] = id;
                }
            }
            else if (isReactor(value)) {
                value = value._getState(options);
            }
            state[property] = value;
        }
        return state;
    },
    dispose() {
        if (this[ID] === 0) {
            return;
        }
        const expressions = this.getStoredProperties();
        for (const key in expressions) {
            const value = expressions[key];
            if (isReactor(value)) {
                value.dispose();
            }
        }
        this[factory].removeReactor(this[ID]);
        // Set on target directly
        this[_target][ID] = 0;
    },
    onPropertyChange(listener) {
        const listeners = this[changeListeners] || [];
        listeners.push(listener);
        this[changeListeners] = listeners;
        return {
            dispose: () => {
                const index = listeners.indexOf(listener);
                if (index === -1) {
                    console.warn('Attempt to remove non-existent onPropertyChange listener');
                    return;
                }
                listeners.splice(index, 1);
            },
        };
    },
    onUpdate(listener) {
        const listeners = this[updateListeners] || [];
        listeners.push(listener);
        this[updateListeners] = listeners;
        return {
            dispose: () => {
                listeners.splice(listeners.indexOf(listener), 1);
            },
        };
    },
    getStoredProperties() {
        let expressions = this.getOwnStoredProperties();
        let prototype = Reflect.getPrototypeOf(this);
        while (prototype && isReactor(prototype)) {
            // First occurrence of a property 'wins'.
            expressions = Object.assign({}, prototype.getOwnStoredProperties(), expressions);
            prototype = Reflect.getPrototypeOf(prototype);
        }
        return expressions;
    },
    getOwnStoredProperties() {
        const ownExpressionPropertyNames = Object.keys(this[_target] || this)
            .filter((property) => property[0] === '$')
            .map((p) => p.slice(1));
        const ownExpressions = {};
        for (const property of ownExpressionPropertyNames) {
            ownExpressions[property] = this[getReactorStoredProperty](property);
        }
        return ownExpressions;
    },
    // If the Reactor is dirty call its update method with the dirty properties.
    // Clear the dirty property tracking object.
    validate() {
        // We don't want to inherit the dirty properties object.
        const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
        const _dirty = descriptor?.value;
        if (_dirty) {
            this[dirty] = undefined;
            try {
                this.update?.(_dirty || {});
                this[notifyUpdateListeners](_dirty || {});
            }
            catch (err) {
                // TODO: make this apparent in the Workbench somehow
                console.error(err);
            }
        }
    },
    // Invalidate all properties or just a specific one.
    invalidate(property) {
        // We don't want to inherit the dirty properties object.
        const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
        const _dirty = descriptor?.value || {};
        if (property) {
            _dirty[property] = true;
        }
        else {
            // No property specified. Invalidate all enumerable ones (including inherited).
            for (const property in this) {
                // Underscore prefixed properties aren't dirty tracked.
                if (property[0] !== '_') {
                    _dirty[property] = true;
                }
            }
        }
        this[dirty] = _dirty;
    },
    // unmark specific properties as dirty, or clear all properties if none are specified.
    clearDirty(properties) {
        if (!properties?.length) {
            this[dirty] = undefined;
            return;
        }
        for (const property of properties) {
            if (this[dirty]?.[property])
                delete this[dirty][property];
        }
    },
    evaluateStoredProperties() {
        console.assert(this === this[_proxy]); // Want to execute in context of the proxy.
        const expressions = this.getOwnStoredProperties();
        for (const property in expressions) {
            this[evaluateStoredProperty](property);
        }
    },
    [evaluateStoredProperty](property) {
        console.assert(this === this[_proxy]); // Want to execute in context of the proxy.
        const value = this[getReactorStoredProperty](property);
        this[property] = value;
    },
    [notifyListeners](property, newValue, oldValue, type) {
        // TODO: batch? asyncify?
        if (this[changeListeners]) {
            for (const listener of this[changeListeners]) {
                listener(this[_proxy], property, newValue, oldValue, type);
            }
        }
    },
    [notifyUpdateListeners](changed) {
        if (this[updateListeners]) {
            for (const listener of this[updateListeners]) {
                listener(this[_proxy], changed);
            }
        }
    },
    [updatePrototypeChain]() {
        const description = this[DESCRIPTION];
        // Create the prototype chain:
        // Reactor/Component instance -> defaults -> description.prototype -> ReactorObjectPrototype
        let chain = this;
        // Add the component defaults to the chain. By inheriting them we can tell
        // when they are being overridden, even if overridden with the default value.
        if (this[defaults]) {
            Object.setPrototypeOf(chain, this[defaults]);
            chain = this[defaults];
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        if (description?.prototype) {
            appendPrototype(description.prototype, ReactorObjectPrototype);
            Reflect.setPrototypeOf(chain, description.prototype);
        }
        else {
            Reflect.setPrototypeOf(chain, ReactorObjectPrototype);
        }
    },
    [setReactorStoredProperty](property, value) {
        if (property[0] === '$') {
            throw new Error('$ prefix is deprecated');
        }
        if (typeof property === 'symbol') {
            throw new Error('setReactorStoredProperty of symbols is deprecated.');
        }
        property = `$${property}`;
        const proxy = this[_proxy];
        const oldValue = this[_target][property];
        this[_target][property] = value;
        // Notify that the expression has changed.
        const changeType = property in this ? 'change' : 'add';
        this[notifyListeners](property, value, oldValue, changeType);
        proxy[evaluateStoredProperty](property.slice(1));
        // If we're replacing a reactor, dispose the old value
        // TODO(jjhuff): This is commented out since it triggers bugs in projectDesigner
        /*if (oldValue && isReactor(oldValue)) {
          oldValue.dispose();
        }*/
    },
    [getReactorStoredProperty](property) {
        if (property[0] === '$') {
            throw new Error('$ prefix is deprecated');
        }
        console.assert(typeof property[0] !== 'symbol', 'getReactorStoredProperty of symbols is deprecated.');
        // add $ to the property.
        if (typeof property !== 'symbol') {
            property = `$${property}`;
        }
        return this[_target][property];
    },
    [hasReactorStoredProperty](property) {
        if (property[0] === '$') {
            throw new Error('$ prefix is deprecated');
        }
        console.assert(typeof property[0] !== 'symbol', 'hasReactorStoredProperty of symbols is deprecated.');
        // add $ to the property.
        if (typeof property !== 'symbol') {
            property = `$${property}`;
        }
        return property in this[_target];
    },
    [deleteReactorStoredProperty](property) {
        if (property[0] === '$') {
            throw new Error('$ prefix is deprecated');
        }
        if (typeof property === 'symbol') {
            throw new Error('deleteReactorStoredProperty of symbols is deprecated.');
        }
        // add $ to the property.
        property = `$${property}`;
        const target = this[_target];
        const proxy = this[_proxy];
        const oldValue = target[property];
        const success = Reflect.deleteProperty(target, property);
        if (success) {
            target[notifyListeners](property, undefined, oldValue, 'remove');
            delete proxy[property.slice(1)];
        }
        return success;
    },
};
// Make all the internal Reactor properties non-enumerable (same behavior as Object).
const descriptors = Object.getOwnPropertyDescriptors(ReactorObjectPrototype);
for (const property in descriptors) {
    descriptors[property].enumerable = false;
    descriptors[property].configurable = false; // Supposedly helps perf.
}
Object.defineProperties(ReactorObjectPrototype, descriptors);
export function createReactorObject(_factory, properties, options) {
    const target = Object.create(ReactorObjectPrototype);
    target[_target] = target;
    const proxy = new Proxy(target, reactorObjectTraps);
    target[_proxy] = proxy;
    target[factory] = _factory;
    target[ID] = _factory.addReactor(proxy, properties?.[ID]);
    // Retain the root Reactor. This is the one assumed to be the Project and that
    // Reactor paths are relative to. Augment the project with ReactorFactory methods.
    if (_factory.rootReactor === undefined) {
        _factory.rootReactor = proxy;
    }
    // Always ensure we can reach the project
    // TODO: this does clobber any property with this name. Move to a symbol prop?
    target.project = _factory.rootReactor;
    Object.defineProperty(target, 'project', {
        enumerable: false,
        configurable: true,
        writable: true,
    });
    // Copy initial properties as expressions and recursively create child objects as Reactors.
    if (properties) {
        for (const property in properties) {
            target['$' + property] = properties[property];
        }
    }
    proxy[updatePrototypeChain]();
    proxy.evaluateStoredProperties();
    proxy.invalidate();
    return proxy;
}
// Return the last link of the prototype chain (the one that has Object as its prototype).
// May return the passed-in object.
function getLastNonObjectPrototype(link, terminator) {
    // Even Object has a prototype (it's Object) so this can't loop forever.
    while (true) {
        const proto = Reflect.getPrototypeOf(link);
        if (proto === terminator) {
            return proto;
        }
        if (proto === Object.prototype || proto === null) {
            return link;
        }
        link = proto;
    }
}
// Append the prototype to the chain if it isn't already on it.
export function appendPrototype(chain, prototype) {
    console.assert(chain !== undefined);
    const proto = getLastNonObjectPrototype(chain, prototype);
    // If this chain already has this prototype on it, do nothing.
    if (proto === prototype) {
        return;
    }
    Object.setPrototypeOf(proto, prototype);
}
export function hasDescribedProperty(component, property) {
    return component[META]?.properties?.[property] !== undefined;
}
