import { getCheckoutSession, getCustomerPortalSession } from '@playful/api';

// TODO: Put in the right location? Would this go into api/stripe?
export const redirectToManageSubscription = async (stripeCustomerId: string, userId: string) => {
  const stripeUrl = await getCustomerPortalSession(userId, stripeCustomerId, window.location.href);
  window.location.assign(stripeUrl);
};

export const redirectToCheckout = async (
  planId: string,
  returnPath: string,
  returnTo?: string[],
  successModal?: string,
) => {
  const stripeUrl = await getCheckoutSession(planId, returnPath, returnTo, successModal);
  window.location.assign(stripeUrl);
};
