import { Box, BoxProps } from '@playful/design_system';
import { Loading } from '@playful/frontend/Loading';
import React from 'react';

export function Scrim(props: BoxProps) {
  return (
    <Box
      zIndex='overlay'
      bg='rgba(255,255,255,.85)'
      position='absolute'
      h='full'
      w='full'
      left={0}
      top={0}
      display='flex'
      flexDir='column'
      justifyContent='center'
      alignItems='center'
      {...props}
    >
      <Loading />
    </Box>
  );
}
