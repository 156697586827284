import { Alert, AlertDescription, AlertTitle } from '@chakra-ui/react';
import { Button, GoogleIcon } from '@playful/design_system';
import { isInAppBrowser, isMobile } from '@playful/utils';
// import { GoogleLogin } from '@react-oauth/google';
import { GoogleAuthProvider } from 'firebase/auth';
import React, { useState } from 'react';

import { useUserContext } from '../UserContext';
import { AuthSchema, FieldError, getErrorMsg } from './authSchema';

const providerLookup: Record<string, string> = {
  'google.com': 'Google',
};

export function getProviderLabelById(id: string) {
  return providerLookup[id];
}

export function SocialButtonStack({ isNewUser }: { isNewUser: boolean }) {
  const { authenticateWithCredential } = useUserContext();
  const [authErr, setAuthErr] = useState<FieldError<Partial<AuthSchema>> | null>(null);

  async function handleSocialAuth() {
    setAuthErr(null);
    const redirect = isMobile();
    const provider = new GoogleAuthProvider();
    const [err] = await authenticateWithCredential({ isNewUser, redirect, provider });
    const formattedErr = getErrorMsg(err);
    if (formattedErr) setAuthErr(formattedErr);
  }

  return !isInAppBrowser() ? (
    <>
      <Button
        id='continue-with-google'
        leftIcon={<GoogleIcon />}
        onClick={() => handleSocialAuth()}
        variant='outline'
        w='full'
      >
        Continue with Google
      </Button>

      {authErr?.message && (
        <Alert status='error'>
          <AlertTitle>Oops!</AlertTitle>
          <AlertDescription>{authErr?.message}</AlertDescription>
        </Alert>
      )}
    </>
  ) : null;
}
