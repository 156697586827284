import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const selfObjectId = -1;
const parentObjectId = -2;
const pageObjectId = -3;
const migration = {
    description: 'Migrate legacy interactions to hatch JavaScript',
    async migrate(proj) {
        let needHelpers = false;
        // Migrate interactions to JavaScript
        forEachObject(proj, (obj) => {
            if (!obj.componentType ||
                !obj.interactions ||
                obj.interactions.length === 0 ||
                !Array.isArray(obj.interactions)) {
                return;
            }
            const js = obj.interactions.map((interaction) => renderInteraction(interaction)).join('\n\n');
            // Debug logging
            /*console.group(obj.name);
            console.log(js);
            console.groupEnd();*/
            obj.javascript = js + (obj.javascript || '');
            delete obj.interactions;
            needHelpers = true;
        });
        if (needHelpers) {
            proj.javascript = `${proj.javascript ?? ''}
// Generate a scope object containing:
// * This object's siblings (if it has a parent, it's the parent's components)
// * This object's properties, etc
//
const scopeTraps = {
  has(target, p) {
    const hasProperty = Reflect.has(target, p);
    if (hasProperty) {
      return true;
    }
    if ('parent' in target && target.parent.children) {
      return target.parent.children.find((c) => c.name===p) !== undefined
    }
    return false;
  },

  get(target, p, receiver) {
    if (p in target) {
      return target[p];
    }

    if ('parent' in target && target.parent.children) {
      return target.parent.children.find((c) => c.name===p);
    }
  },

  ownKeys(target) {
    const ret = Reflect.ownKeys(target);
    if ('parent' in target && target.parent.children) {
      for (const component of target.parent.children) {
        if (ret.includes(component.name)) {
          // Own properties take precedence over sibling components.
          continue;
        }
        ret.push(component.name);
      }
    }
    return ret;
  },

  getOwnPropertyDescriptor(target, prop) {
    const desc = Object.getOwnPropertyDescriptor(target, prop);
    if (desc) {
      if (prop === 'project') {
        desc.enumerable = true;
      }
    } else {
      if ('parent' in target && target.parent.children) {
        for (const component of target.parent.children) {
          if (component.name === prop) {
            return { configurable: true, enumerable: true, writable: false, value: component };
          }
        }
      }
    }
    return desc;
  },
};

project._getLegacyScope = function(comp) {
  return new Proxy(comp||{}, scopeTraps);
}
`;
        }
        log(`Migrated ${migration.description}`);
    },
};
function renderInteraction(interaction) {
    if (!interaction.trigger) {
        throw new Error('Interaction must have a trigger');
    }
    if (interaction.trigger.targetId !== selfObjectId) {
        throw new Error('Interaction trigger must target self');
    }
    if (!interaction.trigger.event) {
        throw new Error('Interaction trigger must have an event');
    }
    // No actions, don't render anything
    if (!interaction.actions || interaction.actions.length === 0) {
        return '';
    }
    let eventName = interaction.trigger.event;
    if (eventName === 'always') {
        eventName = 'animationframe';
    }
    const actions = renderActions(interaction.actions);
    if (actions.trim() === '') {
        return '';
    }
    // If there are any object references in the actions, we need to bind them to the target object
    // This is done at this level since `this` is the target object only in the context of the event handler
    let object = '';
    if (actions.includes('object')) {
        object = indent('const object = this;\n');
    }
    return `this.on('${eventName}', async (event) => {\n${object}${actions}\n});\n`;
}
function indent(lines, level = 1) {
    const indent = '  '.repeat(level);
    return lines
        .split('\n')
        .map((line) => (line ? `${indent}${line}` : ''))
        .join('\n');
}
function renderActions(actions = []) {
    return indent(actions.map((action) => renderAction(action)).join('\n'));
}
function renderAction(action) {
    const targetRef = renderReference(action.targetId);
    let js = '';
    switch (action.method) {
        case 'setProperty': {
            if (!action.args.property) {
                // No property? Skip it
                return '';
            }
            const { object, property } = parseQualifiedProperty(targetRef, action.args.property.value);
            const animation = action.args.animation?.value;
            const valueString = renderVariant(action.args.value);
            if (animation) {
                js = `${animation.wait ? 'await ' : ''}animate(${object}, {${property}: ${valueString} }, ${JSON.stringify(animation)});`;
            }
            else {
                js = `${object}.${property} = ${valueString};`;
            }
            break;
        }
        case 'ifAction':
        case 'ifElseAction': {
            const condition = renderVariant(action.args.condition);
            if (action.args.trueActions?.value || action.args.falseActions?.value) {
                const trueActions = indent(renderActions(action.args.trueActions?.value));
                const falseActions = indent(renderActions(action.args.falseActions?.value));
                js = `if (${condition}) {\n${trueActions}\n} else {\n${falseActions}\n}`;
            }
            else {
                js = '';
            }
            break;
        }
        case 'toggle': {
            const { object, property } = parseQualifiedProperty(targetRef, action.args.property.value);
            const animation = action.args.animation?.value;
            const value1String = renderVariant(action.args.value1);
            const value2String = renderVariant(action.args.value2);
            const statements = [
                '{',
                `let oldValue = ${object}.${property};`,
                `if (oldValue === undefined) { oldValue = ${value1String} }`,
                `let newValue = ${value1String};`,
                `if (oldValue === ${value1String}) { newValue = ${value2String} }`,
            ];
            if (animation) {
                statements.push(`${animation.wait ? 'await ' : ''}animate(${object}, {${property}: newValue }, ${JSON.stringify(animation)} );`);
            }
            else {
                statements.push(`${object}.${property} = newValue;`);
            }
            statements.push('}');
            js = indent(statements.join('\n'));
            break;
        }
        case 'wait':
            js = `await wait(${renderVariant(action.args.duration)});`;
            break;
        case 'logAction':
            js = `console.log(${renderVariant(action.args.text)})`;
            break;
        case 'reset':
            js = `${targetRef}.reset()`;
            break;
        case 'gotoPage': // Project
            js = `gotoPage(${renderVariant(action.args.page)})`;
            break;
        case 'gotoLink': // Project
            js = `window.open(${renderVariant(action.args.link)}, ${renderVariant(action.args.target)})`;
            break;
        case 'show':
            js = `${targetRef}.visible = true;`;
            break;
        case 'hide':
            js = `${targetRef}.visible = false;`;
            break;
        case 'repeat': {
            const statements = [
                `for (let i = 0; i < ${renderVariant(action.args.count)}; i++) {`,
                renderActions(action.args.actions?.value),
                `}`,
            ];
            js = indent(statements.join('\n'));
            break;
        }
        case 'insert': {
            //Container
            const object = renderVariant(action.args.object);
            js = `${targetRef}.insertChild(${object}, (${renderVariant(action.args.index)})||0)`;
            break;
        }
        case 'remove': {
            // Container
            const compIndex = renderVariant(action.args.index);
            js = `${targetRef}.removeChild(${targetRef}.children[${compIndex}])`;
            break;
        }
        case 'forEach': {
            // Container
            const statements = [
                `${targetRef}.children.forEach((item, index) => {`,
                renderActions(action.args.actions?.value),
                `})`,
            ];
            js = indent(statements.join('\n'));
            break;
        }
        case 'formula':
            js = renderVariant(action.args.formula);
            break;
        default: {
            let args = Object.entries(action.args ?? {})
                .map(([p, v]) => `${p}: ${renderVariant(v)}`)
                .join(', ');
            args = args ? `{${args}}` : '';
            js = `await ${targetRef}.${action.method}(${args});`;
            break;
        }
    }
    let hasExpression = false;
    for (const key in action.args) {
        if (action.args[key].type === 'expression') {
            hasExpression = true;
            break;
        }
    }
    if (hasExpression) {
        // wrap in the scope helper
        return `with(project._getLegacyScope(${targetRef})) { \n${indent(js)}\n}`;
    }
    else {
        return js;
    }
}
function renderVariant(value) {
    if (value === undefined) {
        return 'undefined';
    }
    // Some projects (e.g. Gravity) have "=<whatever>" strings meant to be used as expressions.
    if (value.type === 'string' && value.value.startsWith('=')) {
        value = {
            type: 'expression',
            value: value.value.slice(1),
        };
    }
    switch (value.type) {
        case 'undefined':
            return 'undefined';
        case 'number':
            return value.value;
        case 'string':
            return `'${value.value.replace(/'/g, "\\'")}'`;
        case 'boolean':
            return value.value ? 'true' : 'false';
        case 'object':
            return JSON.stringify(value.value);
        case 'expression': {
            let valueString = value.value;
            // Remove  comments
            valueString = valueString.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, ' ');
            // Fix up getState calls in createComponent
            const getState = valueString.match(/\s*\.\.\.(\w+)\s*\.\s*getState\s*\(\)[\s,]+/);
            if (getState) {
                // This are the props we need to override
                const props = valueString.replace(getState[0], '');
                const comp = getState[1];
                valueString = `
        (function() {
          const comp = parent.children.find((c) => c.name === '${comp}');
          const clone = comp.clone();
          Object.entries(${props}).forEach(([key, value]) => {
            clone[key] = value;
          });
          return clone;
        }
        )()`;
                return valueString;
            }
            // Fix up getComponentByName calls
            valueString = valueString.replaceAll(/getComponentByName\(([^)]+)\)/g, 'children.find((c) => c.name === $1)');
            // Fix up getEffect calls
            valueString = valueString.replaceAll(/getEffect\(["']Play Kit\/([^"']+)["']\)/g, '$1');
            valueString = valueString.replaceAll('PhysicsProperties', 'Physics');
            // Fix up effects. All the cases I found are of the form: effects[0].<physics property>
            valueString = valueString.replaceAll(/effects\[0\]\.(type|impulseStrength|impulseStrengthX|impulseStrengthY|impulseAngle)/g, 'Physics.$1');
            // Physics migrated to the container, so we need to fix up the references
            // This is a bit of a hack, but it works for the cases I've seen
            valueString = valueString.replaceAll('physics1', 'parent');
            // Fix up some property names
            valueString = valueString.replaceAll('Physics.type', 'Physics.physicsType');
            // A few projects use getBoundingClientRect(), but only in a few narrow cases
            valueString = valueString.replaceAll(/\(event.clientX\s*-\s*event.target.getBoundingClientRect\(\).left\s*-\s*(\w+).width\/2\)/g, '(localPointFromClient(event.clientX, event.clientY).x - $1.width/2)');
            valueString = valueString.replaceAll(/\(event.clientY\s*-\s*event.target.getBoundingClientRect\(\).top\s*-\s*(\w+).height\/2\)/g, '(localPointFromClient(event.clientX, event.clientY).y - $1.height/2)');
            // Fix @darrin/tile
            valueString = valueString.replaceAll('translate(${x+(localPointFromClient(event.clientX, event.clientY).x - tilt.width/2)/4}px, ${y+(localPointFromClient(event.clientX, event.clientY).y - tilt.height/2)/4}px)', 'translate(${(localPointFromClient(event.clientX, event.clientY).x - tilt.width/2)/8}px, ${(localPointFromClient(event.clientX, event.clientY).y - tilt.height/2)/8}px)');
            return valueString;
        }
        default:
            console.warn(`Unknown variant type: ${value.type}`);
            return `/*${JSON.stringify(value.value)}*/`;
    }
}
function renderReference(objectId) {
    switch (objectId) {
        case selfObjectId:
            return 'this';
        case parentObjectId:
            return 'this.parent';
        case 1: // Project is always id 1
        case pageObjectId:
            return 'this.page';
        default:
            return `get(${objectId})`;
    }
}
// Play Kit/Spin.speed -> { object: 'Spin', property: 'speed' }
function parseQualifiedProperty(target, prop) {
    const parts = prop.replace('Play Kit/', '').split('.');
    if (parts.length === 1) {
        return { object: target, property: parts[0] };
    }
    else {
        return {
            object: `${target}.${parts.slice(0, -1).join('.')}`,
            property: parts[parts.length - 1],
        };
    }
}
registerMigration(migration, import.meta.url);
