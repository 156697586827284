import {
  ResourceUploadOptions,
  apiRequest,
  apiRequestWithRetry,
  corsProxyRequest,
  getFirebaseAuthToken,
  uploadFile,
} from '@playful/api';
import {
  IResource,
  IResourceJson,
  ResourceData,
  ResourceId,
  ResourceUrlOptions,
  getResourceUrl,
} from '@playful/runtime';
import { generateUUID, filterT } from '@playful/utils';

export const MAX_RESOURCE_SIZE_IN_BYTES = 1024 * 1024 * 32; // 32 mb

// Well known Paths
export const PROJECT_JSON = 'project.json';
export const PREVIEW = 'preview';
export const PREVIEW_CUSTOM = 'preview_custom';
export const PREVIEW_AUTO = 'preview_auto';
export const ORIG = 'orig';
export const THUMB = 'thumb';

// Resource Types
// TODO: use an enum?
export const USER_UPLOAD = 'user_library';
export const PROJECT_STATE = 'project_state';

export const buildPath = (...args: any[]) => filterT(args).join('/');

export const buildPreviewPath = (view: string, name: string, ...args: any[]) =>
  buildPath(view, name, PREVIEW, ...args);

// Capture the shape of the resource json data
export type ResourceJson = IResourceJson & {
  owner: string;
  type: string;
};

export type ResourcePermissions = {
  public: boolean;
};

export const isErrPreconditionFailed = (err: any) => {
  return err?.status === 412;
};

type ResourceDataResponse = { resourceData: ResourceData; etag: string | null };

export class Resource implements IResource {
  readonly id: ResourceId;
  secret?: string;
  name?: string;
  keys: Array<ResourceData>;
  created: Date;

  // Local created stores the date a resource *began* being created on the client side.
  // It is not persisted.
  localCreated?: Date;
  modified: Date;

  /**
   * Create a new resource on the server
   * @param type Resource type
   * @param name Optional name for the resource
   */
  static async create(type: string, name?: string): Promise<Resource> {
    const data = { type, name };
    const ret = await apiRequest('resources', {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return this.fromJSON(resourceJson);
  }

  /**
   * Get a resource
   * @param id Resource id
   */
  static async get(id: string): Promise<Resource> {
    const ret = await apiRequest(`resources/${id}`, {
      method: 'GET',
    });
    const resourceJson: ResourceJson = await ret.json();
    return this.fromJSON(resourceJson);
  }

  /**
   * Return an authenticated url to the resource + key
   * @param id Resource id
   * @param key Resource key
   */
  static async getDataUrlAuth(
    id: string,
    key: string,
    options?: ResourceUrlOptions,
  ): Promise<string> {
    const auth = await getFirebaseAuthToken();
    return getResourceUrl(id, key, '', { ...options, auth });
  }

  /**
   * Create a Resource from server JSON
   */
  static fromJSON(json: IResourceJson, res?: Resource, mergeKeys?: boolean): Resource {
    json.keys = json.keys || [];
    if (!res) {
      res = new Resource(json.id, json.name);
      res.keys = json.keys.map((rd) => ({
        ...rd,
        created: rd.created && new Date(rd.created),
      }));
      res.created = new Date(json.created);
    } else {
      if (mergeKeys) {
        res.keys = Resource.mergeKeys(res.keys, json.keys);
      } else {
        res.keys = json.keys;
      }
    }
    res.modified = new Date(json.modified);
    res.secret = json.secret;
    return res;
  }

  static toJSON(res: Resource): IResourceJson {
    return {
      keys: res.keys,
      id: res.id,
      name: res.name || '',
      modified: res.modified.toString(),
      secret: res.secret || '',
      created: res.created.toString(),
    };
  }

  /**
   * Given two arrays of ResourceData, attempt to merge them based on the key.
   * keysB will override keysA if both have the same property.
   */
  static mergeKeys(keysA: ResourceData[], keysB: ResourceData[]): ResourceData[] {
    const merged = keysA.map((aData) => {
      const jsonKeyIndex = keysB.findIndex((bData) => bData.key === aData.key);
      if (jsonKeyIndex !== -1) {
        const d = keysB[jsonKeyIndex];
        keysB.splice(jsonKeyIndex, 1);
        return {
          ...aData,
          ...d,
        };
      } else {
        return aData;
      }
    });
    // Spread the merged keys followed by the keys found in B that were not found in A
    return [...merged, ...keysB];
  }

  /**
   * Constructor
   * @param id ResourceId
   */
  constructor(id: ResourceId, name?: string) {
    this.id = id;
    this.name = name;
    this.created = new Date();
    this.modified = this.created;
    this.keys = [];
  }

  /**
   * Upload to a resource key
   * @param key Resource key
   * @param blob Blob or File to upload
   * @param onUpdateProgress Called when the resource is updated while uploading, like when the progress changes
   * @param options
   */
  async uploadDataBlob(
    key: string,
    blob: Blob | File,
    onUpdateProgress?: (res: Resource) => any,
    options: ResourceUploadOptions = {},
  ): Promise<ResourceDataResponse> {
    const opts: ResourceUploadOptions = { ...options };
    if (blob instanceof File && !options.name) {
      opts.name = blob.name;
    }

    const uploadUrl = getResourceUrl(
      this.id,
      key,
      this.secret,
      opts.name ? { name: opts.name } : undefined,
    );
    if (blob.size > MAX_RESOURCE_SIZE_IN_BYTES) {
      throw new Error('The file is too large');
    }
    // Construct the ResourceData
    const rd: ResourceData = {
      key: key,
      mimeType: blob.type,
      size: blob.size,
      name: opts.name,
      url: URL.createObjectURL(blob),
    };
    this.keys = [...this.keys.filter((d) => d.key !== rd.key), rd];

    const updateProgress = (progress: number | undefined) => {
      rd.progress = progress;
      if (onUpdateProgress) {
        onUpdateProgress(this);
      }
    };

    const { uploadedResourceHash, projectResourceData } = await uploadFile(
      uploadUrl,
      blob,
      blob.type,
      updateProgress,
      opts,
    );

    // If we don't want duplicates, we need to check if the keys already have a resource with this same hash
    // that was just uploaded.
    if (opts.noDuplicates) {
      const respRDHash = projectResourceData.keys.find(
        (e: ResourceData) => e.hash === uploadedResourceHash,
      );
      // Update the object in-place with the data from the server including the data Hash
      Object.assign(rd, respRDHash);
      // Find all keys with this hash and remove them, then re-add this one
      this.keys = [...this.keys.filter((d) => d.hash !== rd.hash), rd];
    } else {
      // If we don't care about duplicates, just find the key and update it with the hash
      const respRD = projectResourceData.keys.find((e: ResourceData) => e.key === key);
      // Update the object in-place with the data from the server including the data Hash
      Object.assign(rd, respRD);
    }

    // Upload is totally done, so clear the progress indicator
    updateProgress(undefined);

    return { resourceData: rd, etag: uploadedResourceHash };
  }

  /**
   * Upload to a resource key
   * @param key Resource key
   * @param buffer Data to upload
   * @param mimeType MIME type of the data
   * @param onUpdate
   * @param options
   */
  async uploadDataBuffer(
    key: string,
    buffer: ArrayBuffer,
    mimeType: string,
    onUpdate?: (res: Resource) => any,
    options?: ResourceUploadOptions,
  ): Promise<ResourceDataResponse> {
    const blob = new Blob([buffer], { type: mimeType });
    return this.uploadDataBlob(key, blob, onUpdate, options);
  }

  /**
   * Return ResourceData for a given key
   * @param key Optional key (defaults to the root key)
   */
  getData(key: string): ResourceData | undefined {
    return this.keys.find((e) => e.key === key);
  }

  getDataForHash(hash: string): ResourceData | undefined {
    if (hash.startsWith('sha256:')) {
      hash = hash.split(':')[1];
    }
    return this.keys.find((e) => e.hash === hash);
  }

  /**
   * Return the URL for a resource + key
   * TODO: we may want to think about revoking these blob urls in the future when we have better
   * image uploading states. the returned uploaded image should be the source of truth if
   * we ever have server-side image post-processing, and using the blob as a placeholder only
   * makes sense if we can guarantee the image returned from server will be the same as the blob.
   * @param key Optional key (defaults to the root key)
   * @param method Optional method (defaults to get)
   */
  getDataUrl(key: string, options?: ResourceUrlOptions): string {
    const data = this.getData(key);

    if (data?.hash) return getResourceUrl(this.id, `sha256:${data.hash}`, this.secret, options);
    if (data?.url) return data.url;

    return getResourceUrl(this.id, key, this.secret, options);
  }

  /**
   * Get the mime type for a specific key
   * @param key Optional key (defaults to the root key)
   */
  getDataMimeType(key: string): string | undefined {
    const data = this.getData(key);
    if (data) {
      return data.mimeType;
    } else {
      return undefined;
    }
  }

  /**
   * Get the progress for a specific key
   * @param key Optional key (defaults to the root key)
   */
  getProgress(key: string): number | undefined {
    const data = this.getData(key);
    if (data) {
      return data.progress;
    } else {
      return undefined;
    }
  }

  /**
   * Delete the resource
   */
  async remove() {
    await apiRequest(`resources/${this.id}`, {
      method: 'DELETE',
    });
  }

  /**
   * Delete the resource
   * @param key
   */
  async removeData(key: string) {
    this.keys = this.keys.filter((k) => k.key !== key);
    await apiRequest(`resources/${this.id}/data/${key}`, {
      method: 'DELETE',
    });
  }

  /**
   * Clone a resource into a newly created resource
   */
  async clone(): Promise<Resource> {
    const data = {};
    const ret = await apiRequest(`resources/${this.id}/clone`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return Resource.fromJSON(resourceJson);
  }

  /**
   * Clone from another resource
   * Takes the source resource (optionally just one key) and clones it
   * to this resource under targetKey
   */
  async cloneFrom(
    targetKey: string,
    srcId: string,
    srcKey?: string,
    noDuplicates?: boolean,
    targetData?: ResourceData,
  ): Promise<Resource> {
    const data = {
      src: srcId,
      keys: [
        {
          src: srcKey,
          target: targetKey,
        },
      ],
    };

    const params = new URLSearchParams({});

    if (noDuplicates) {
      params.append('noDuplicates', 'true');
    }

    const ret = await apiRequestWithRetry(`resources/${this.id}/clone_keys?${params}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();

    const resource = Resource.fromJSON(resourceJson, this, true);

    if (noDuplicates && targetData) {
      this.keys = [...this.keys.filter((d) => d.hash !== targetData.hash), targetData];
    }

    return resource;
  }

  /**
   * Clone multiple keys from another resource
   */
  async cloneFromBatch(srcId: string, keys: { src: string; target: string }[]): Promise<Resource> {
    const data = {
      src: srcId,
      keys,
    };
    const ret = await apiRequestWithRetry(`resources/${this.id}/clone_keys`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return Resource.fromJSON(resourceJson, this, true);
  }
}

///////////////////////////////////////////////////////////
/**
 * Given a DataTransfer pull out an array of Files.
 * @param dataTransfer
 */
export function getDataTransferFiles(dataTransfer: DataTransfer): File[] {
  const files: File[] = [];
  if (dataTransfer.items) {
    const items = dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      // If dropped items aren't files, reject them
      if (items[i].kind === 'file') {
        const file = items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  } else {
    for (let i = 0; i < dataTransfer.files.length; i++) {
      files.push(dataTransfer.files[i]);
    }
  }
  return files;
}

/**
 * @param mimeType: string
 */
export function validateLibraryMimeType(mimeType: string) {
  // TODO: Comprehensive list of valid mimeTypes
  if (
    mimeType.startsWith('image/') ||
    mimeType.startsWith('audio/') ||
    mimeType.startsWith('video/')
  ) {
    return true;
  }
  return false;
}

/**
 * Fetch a URL as a blob, possibly using a CORS proxy
 * @param url URL to fetch
 */
export async function fetchUrlData(url: string): Promise<Blob> {
  let response;
  try {
    response = await fetch(url);
  } catch (err) {
    // Maybe a Cross Origin problem? Try with our CORS proxy.
    response = await corsProxyRequest(url);
  }
  return response.blob();
}

export async function getBlobFromUrl(uri: string, name: string | undefined) {
  const blob = await fetchUrlData(uri);
  const u = new URL(uri);
  let filename = name ?? u.pathname.split('/').pop();
  if (!filename) {
    filename = generateUUID();
  }

  return new File([blob], filename, { type: blob.type });
}
