import { Box, BoxProps, Text } from '@playful/design_system';
import React from 'react';

import hatchSpinner from '../assets/hatchAnimated.gif';

export function HatchSpinner({ message, ...props }: BoxProps & { message: string }) {
  return (
    <Box
      display='flex'
      height='100vh'
      flexDir='column'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <img width='150px' height='150px' src={hatchSpinner.src} />
      <Text mt={2} fontSize='large' fontWeight='bold'>
        {message}
      </Text>
    </Box>
  );
}
