import {
  CustomDomain,
  User,
  addCustomDomain,
  deleteCustomDomain,
  getCustomDomains,
} from '@playful/api';
import { FromPromiseReturn, fromPromise } from '@playful/utils';
import { useRef } from 'react';
import useSWR from 'swr';

export function useCustomDomains(user: User) {
  const isLoadingRef = useRef<boolean>(true);
  const { data, error, mutate, ...restSWRProps } = useSWR(
    user?.id ? `users/${user.id}/custom_domains` : null,
    async () => {
      const data = await getCustomDomains(user.id);
      // sort, otherwise order may be random between page loads
      data.sort((a: CustomDomain, b: CustomDomain) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return data;
    },
    {
      revalidateOnFocus: false,
    },
  );
  isLoadingRef.current = restSWRProps.isLoading;

  const deleteCustomDomainMutation = (domainName: string) => {
    const filteredDomains =
      data === undefined ? [] : data.filter((domain: CustomDomain) => domain.name !== domainName);
    return mutate(
      async () => {
        await deleteCustomDomain(user.id, domainName);
        return filteredDomains;
      },
      {
        optimisticData: filteredDomains,
        populateCache: true,
        revalidate: true,
        rollbackOnError: true,
      },
    );
  };

  const addCustomDomainMutation = async (domainName: string) => {
    const newDomainPromises: Promise<CustomDomain>[] = [addCustomDomain(user.id, domainName)];

    if (domainName.split('.').length === 2) {
      // Add a www redirect for domains, but not subdomains
      newDomainPromises.push(addCustomDomain(user.id, `www.${domainName}`, domainName));
    } else if (domainName.startsWith('www.')) {
      // Add a non-www redirect for www domains
      newDomainPromises.push(addCustomDomain(user.id, domainName.slice(4), domainName));
    }

    const newDomains = await Promise.all(newDomainPromises);

    return mutate(
      async (data) => {
        if (data === undefined) {
          return newDomains;
        }
        // Merge and de-dupe
        const domains = Object.fromEntries([
          ...data.map((d) => [d.name, d]),
          ...newDomains.map((d) => [d.name, d]),
        ]);
        return Object.values(domains);
      },
      {
        populateCache: true,
        revalidate: true,
        rollbackOnError: true,
      },
    );
  };

  return {
    customDomains: data ?? [],
    addCustomDomain: async (domainName: string): FromPromiseReturn<CustomDomain[] | undefined> =>
      fromPromise(addCustomDomainMutation(domainName)),
    deleteCustomDomain: (domainName: string): FromPromiseReturn<CustomDomain[] | undefined> =>
      fromPromise(deleteCustomDomainMutation(domainName)),
    error,
    ...restSWRProps,
  };
}
