import type { Tags } from '@playful/runtime';
import { isClientCtx } from '@playful/utils';

// Convert query arg tags=<tag1>,<tag2>,... into [ "<tag1>, "<tag2>", ...].
export function getTagsFromQueryString(): string[] {
  if (!isClientCtx()) return [];

  const queryParams = new URL(window.location.href).searchParams;
  const tags = queryParams.get('tags');
  if (!tags) return [];
  return tags.split(',');
}

// Return true if the TagObject has all the tags listed.
export function hasMatchingTags(tags: string[], tagObject: Tags | undefined): boolean {
  if (!tagObject) {
    return false;
  }
  for (const tag of tags) {
    if (!tagObject[tag]) {
      return false;
    }
  }
  return true;
}
