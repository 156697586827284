import { redeemHoverVoucher } from '@playful/api';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@playful/design_system';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useCustomDomains } from '../../user/useCustomDomains';
import { useUserContext } from '../../user/UserContext';
import useDisclosure from '../../utils/useDisclosure';
import { useQueryStringActions } from '../../workbench/hooks/useQueryStringActions';
import PresentImage from './domain-claimed.svg';

export const WelcomeFromHover = () => {
  const { isOpen, onClose: closeDialog, onOpen } = useDisclosure();
  const { user, refreshUser } = useUserContext();
  const { addCustomDomain } = useCustomDomains(user);
  const { handleEndAction: onClose } = useQueryStringActions({
    startAction: onOpen,
    endAction: closeDialog,
    actionName: 'subscribe-success-hover',
  });
  const [domain, setDomain] = useState('');
  const promises = useRef<Promise<any>[]>();

  const setupHover = useCallback(async () => {
    if (domain && user.id && !promises.current) {
      promises.current = [redeemHoverVoucher(user.id), addCustomDomain(domain)];
      await Promise.all(promises.current);
      refreshUser();
    }
  }, [addCustomDomain, domain, user.id, refreshUser]);

  useEffect(() => {
    const searchParams = new URL(window.location.toString()).searchParams;
    setDomain(searchParams.get('domain') || '');
  }, []);

  useEffect(() => {
    if (domain) {
      setupHover();
    }
  }, [domain, setupHover]);

  return (
    <Modal size='xs' isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box position='relative' h='180px'>
          <Box position='absolute' top='-86px' left='-33px'>
            <PresentImage width='400px' />
          </Box>
        </Box>
        <ModalHeader textAlign='center'>
          <Heading fontSize='lg' fontWeight='bold'>
            {domain}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text textAlign='center' fontSize='sm'>
            Your domain has been registered. Begin publishing your Hatch projects to it now.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='yellow' variant='solid' w='100%' onClick={onClose}>
            start publishing
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
