import { axiosRequest } from '@playful/api';
import { getConfig } from '@playful/config';
import { useUserContext } from '@playful/frontend/user/UserContext';
import { useCallback } from 'react';

const PARTNER_ID = 'hatch';

const getReturnUrl = (returnUrl: string): string => {
  const origin = window.location.origin;
  return `${origin}${returnUrl}?domain=%domain%&returnTo=subscribe-success-hover`;
};

async function createHoverVoucher(userId: string) {
  const { data } = await axiosRequest(`users/${userId}/create_hover_voucher`, {
    method: 'POST',
  });
  return data.voucherCode;
}

export const useHoverDomains = ({ returnUrl }: { returnUrl?: string }) => {
  const { user, hoverVoucherState } = useUserContext();
  const userHasClaimedFreeDomain = hoverVoucherState === 'redeemed';

  const getVoucher = useCallback(async () => {
    const voucherCode = await createHoverVoucher(user.id);
    return voucherCode;
  }, [user.id]);

  const redirectToHover = useCallback(async () => {
    if (!user?.id) {
      return;
    }
    let voucherCode = null;
    let voucherRedemptionPath = '';

    const { hoverBaseUrl } = getConfig();

    if (!userHasClaimedFreeDomain) {
      voucherCode = await getVoucher();
    }

    if (voucherCode) {
      voucherRedemptionPath = `/redeem/${voucherCode}`;
    }

    let hoverUrl = hoverBaseUrl + voucherRedemptionPath;
    const successUrl = getReturnUrl(returnUrl || location.pathname);
    hoverUrl += `?partner=${PARTNER_ID}&param=${user.id}&success=${encodeURIComponent(successUrl)}`;

    window.location.assign(hoverUrl);
  }, [user.id, returnUrl, getVoucher, userHasClaimedFreeDomain]);

  return {
    redirectToHover,
  };
};
