import { getComponentProperties } from '../component.js';
import { deserializeEvent, isPlaySerializedEvent } from './events.js';
export function getHostApi(component, ep, { comlinkListeners, onDispatchEvent: handleDispatchEvent, }) {
    const sandbox = component._iframe;
    const listeners = {
        onAction: [],
        onCommand: [],
        onReady: [],
        onUpdate: [],
    };
    const client = {
        init(action, command, update) {
            if (action)
                listeners.onAction.push(action);
            if (command)
                listeners.onCommand.push(command);
            // This adds the listener AFTER the one established in client.ts awaitBridgeReady
            // which updates play.properties with the updated property values.
            if (update)
                listeners.onUpdate.push(update);
            // "assuming there will be an update call with initial properties I think
            // that’ll be good enough until we inject them up front" - darrin
            // Yes! Trigger the initial update notification which also matches the
            // behavior of the onUpdate() form.
            update?.(...getPropertiesAsChanges(component));
        },
        /**
         * Callback to be triggered when the Play bridge has connected. If called
         * after the bridge is active, the callback is immediately executed.
         */
        ready(cb) {
            if (listeners.onReady === undefined) {
                cb();
                return;
            }
            listeners.onReady.push(cb);
        },
        notifyReady() {
            listeners.onReady?.forEach((it) => it());
            listeners.onReady = undefined;
        },
        // Sandbox-derived components use this to deliver events to Interactions.
        notifyEvent({ type, ...rest }) {
            component.dispatchEvent(new CustomEvent(type, rest));
        },
        /**
         * Retrieves the properties of the current project
         */
        getProject() {
            const { project, project: { info }, } = component;
            // TODO: what if any of these are useful. The ID is probably useful if the
            // author of the sandbox component uses it to separate stored data for
            // example.
            return {
                // created: info.created,
                id: info.id,
                // locked: info.locked,
                // modified: info.modified,
                // name: info.name,
                // owner: info.owner,
                // ownerName: info.ownerName,
                // project: info.project,
                // published: info.published,
                // publishedHistory: info.publishedHistory,
                // publishedProject: info.publishedProject,
                // publishedVersion: info.publishedVersion,
                // sharing: info.sharing,
                // tags: info.tags,
                title: info.title,
                // version: info.version,
                designMode: project.designMode,
                scale: project.rootView.getEffectiveScale(),
            };
        },
        /**
         * Allows for inner sandbox to perform updates to a component’s properties.
         *
         * @param properties
         */
        setProperties(properties) {
            // TODO: update play.properties
            for (const key in properties) {
                component[key] = properties[key];
            }
        },
        /**
         * Allows in inner runtime to dispatch an event which can be the initial
         * trigger for an interaction.
         *
         * @param detail the payload to dispatch
         */
        dispatchEvent(detail) {
            if (detail && detail.type) {
                handleDispatchEvent(detail);
            }
            else if (isPlaySerializedEvent(detail)) {
                handleDispatchEvent(deserializeEvent(sandbox, detail));
            }
            else {
                handleDispatchEvent(new CustomEvent('sandbox', { detail }));
            }
        },
        // TODO
        // If commands are going to be supported we should decide if the sandbox
        // code is responsible for registering the command (play.registerCommand)
        // or if we provide UI affordance. It seems like if custom commands make
        // sense for no code user components, then UI seems sensible. But if we
        // don't need them, or we only have that ability in the Sandbox component.
        // then programiatic registration seems sensible (and gives conditionality).
        //
        // There's a very similar question regarding if a sandbox can expose custom
        // actions which can be triggered by other components. That could be awesome.
        /**
         * Allows inner runtime to listen for an action to be triggered
         *
         * @param callback
         */
        onAction(callback) {
            listeners.onAction.push(callback);
        },
        /**
         * Allows inner runtime to listen for an command to be triggered
         *
         * @param callback
         */
        onCommand(callback) {
            listeners.onCommand.push(callback);
        },
        onUpdate(callback) {
            listeners.onUpdate.push(callback);
            callback(...getPropertiesAsChanges(component));
        },
        //--------------------------------------------------------------------------
        log(...entry) {
            console.log(...['[Hatch]', `(${component.name}) =>`, ...entry]);
        },
        ping() {
            return 'PONG';
        },
    };
    const host = {
        detach() {
            //console.log('[Hatch] Detaching:', component.name);
            comlinkListeners.forEach((it) => ep.removeEventListener('message', it));
        },
        // for now a string, later maybe a more rich payload
        dispatchAction(type, payload) {
            const properties = getComponentProperties(component);
            const event = { type, payload, properties };
            // TODO: This has been overengineered and should be reduced down to how it is actually used
            // which is that there is only one listener for actions.
            let result;
            listeners.onAction.forEach((it) => {
                const ret = it(event);
                result = ret ?? result;
            });
            // Return the result of the last (and only!) action handler.
            return result;
        },
        // TODO - sandboxes don't have custom commands, play.registerCommand?
        dispatchCommand(command) {
            listeners.onCommand.forEach((it) => it(command));
        },
        dispatchUpdate(changed) {
            const props = getComponentProperties(component);
            const changes = {};
            for (const key in changed) {
                const value = props[key];
                if (typeof value !== 'function') {
                    changes[key] = props[key];
                }
            }
            listeners.onUpdate.forEach((it) => {
                it(props, changed);
            });
        },
    };
    return {
        client,
        host,
    };
}
/**
 * For the initial update call that contains all the initial properties, we have
 * this function to grab all the relevant properties and then hand them off
 * paired with a changes object representing all of them to match the shape
 * that will triggered by subsequent updates.
 *
 * @param component
 * @param changes
 */
function getPropertiesAsChanges(component) {
    const properties = getComponentProperties(component);
    const allPropsAsChanges = Object.keys(properties).reduce((changes, key) => {
        changes[key] = true;
        return changes;
    }, {});
    return [properties, allPropsAsChanges];
}
