import type { User } from '@playful/api';

export const initialUser: User = {
  id: '',
  name: '',
  email: '',
  userType: 'user',
  uploadsResourceId: '',
  isAnonymous: false,
};
