import './000000000008-the-cataclysm.js';
import './000000000010-project-migrations.js';
import './000000000011-convert-actions-targets-to-use-symbolic-references-to-parent.js';
import './000000000012-fix-projects-whose-pages-and-components-have-been-moved.js';
import './000000000015-html-on-sandbox-transformation.js';
import './000000000016-runtime-mixins-to-effects.js';
import './000000000017-migrate-effect-names-from-guid-to-component-type.js';
import './000000000018-migrate-physics-properties.js';
import './202207181546-remove-project-effects.js';
import './202208311230-upgrade-p5-js.js';
import './202210191031-set-text-components-to-fixed.js';
import './202212051649-update-shape-component-defaults.js';
import './202302091707-image-video-cover-default.js';
import './202303291709-3899-component-styles.js';
import './202305041516-fix-slideshow-name.js';
import './202305171411-remove-unsupported-effects.js';
import './202305231454-remove-unsupported-effects.js';
import './202306010852-add-instruction-ids.js';
import './202306090126-add-uid-to-instructions.js';
import './202307141620-atmentioningv2.js';
import './202309151408-fix-double-mount-animations.js';
import './202309201451-rte-text-valign.js';
import './202309261808-remove-project-transforms.js';
import './202311011137-interactions-javascript-to-javascript.js';
import './202311011728-default-borderradius-undefined.js';
import './202311141225-add-event-object-to-javascript-interactions.js';
import './202311271534-block-conditional-type.js';
import './202311281732-conditions-either-js-or-array.js';
import './202312061805-block-set-prop-instructions.js';
import './202401031308-block-instruction-to-block-action.js';
import './202401220936-prefer-self-references-in-blocks.js';
import './202402191327-move-physics-from-effect-into-container.js';
import './202402231327-move-physics-from-effect-into-container-try2.js';
import './202402261556-move-pointer-events.js';
import './202402281106-refactor-set-properties-actions-to-presets.js';
import './202402291157-update-old-dark-theme-projects.js';
import './202403081142-deunify-begin-contact-events.js';
import './202403110900-move-physics-from-effect-into-container-try3.js';
import './202404262239-set-text-outline-default.js';
import './202405131510-migrate-text-to-global-styles.js';
import './202405150914-fix-local-point-from-client.js';
import './202405180859-add-section.js';
import './202406051334-fixed-position-to-position-type.js';
import './202406211430-set-gradient-effect-type.js';
import './202407011233-fix-text-with-link.js';
import './202407111514-background-image-updates.js';
import './202407180826-legacy-interactions.js';
import './202407130213-set-appear-effect-slide-direction.js';
import './202408091345-sortkey-sections.js';
import './202408121345-init-sortkey.js';
import './202408220007-sortkey-again.js';
import './202408121841-set-responsive-behavior.js';
