import { useCallback, useEffect, useState } from 'react';

import queryStringActionsState from './queryStringActions';

type QueryStringOpensDialogParams = {
  startAction: () => void;
  endAction: () => void;
  actionName: string;
};

export function useQueryStringActions({
  startAction,
  endAction,
  actionName,
}: QueryStringOpensDialogParams) {
  const [currentDialog, setCurrentDialog] = useState<string>();

  useEffect(() => {
    queryStringActionsState.subscribe(setCurrentDialog);
    return () => {
      queryStringActionsState.unsubscribe(setCurrentDialog);
    };
  }, []);

  useEffect(() => {
    if (currentDialog === actionName) {
      startAction();
    }
  }, [currentDialog, actionName, startAction]);

  const handleEndAction = useCallback(() => {
    endAction();
    queryStringActionsState.removeAction(actionName);
  }, [endAction, actionName]);

  return { handleEndAction };
}
