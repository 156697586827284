import { extendDeep } from '@playful/utils';
import { isPage } from './runtime.js';
import { isReactor, removeIds, ID, parseComponentType, } from './reactor.js';
import { appendPrototype, defaults, getReactorStoredProperty, META, updatePrototypeChain, } from './reactorObject.js';
import { isContainer } from './container.js';
// Use Symbols to hide private Component properties we don't want to confuse anyone with.
export const DESCRIPTION = Symbol('DESCRIPTION');
export const MASTER = Symbol('MASTER');
export const INITIAL_STATE = Symbol('INITIAL_STATE');
// Symbolic Object ids.
export const selfObjectId = -1;
export const parentObjectId = -2;
export const pageObjectId = -3;
export function isComponent(obj) {
    return typeof obj === 'object' && DESCRIPTION in obj;
}
export function getDefaultMetaData() {
    return {
        exported: false,
        commands: {},
        events: {},
        properties: {},
    };
}
// Attach listeners to detect metadata changes
function attachMetaListeners(target, source) {
    const ret = [];
    if (!isReactor(source)) {
        return ret;
    }
    // Watch for a change to source._meta
    ret.push(source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
        property = property;
        if (property === '$_meta') {
            // Defer promoteToComponent to the next tick to avoid recursing since property changes are emitted immediately.
            setTimeout(() => promoteToComponent(target, target.project));
        }
    }));
    return ret;
}
// Attach listeners to detect property changes
function attachPropertyListeners(target, source) {
    const ret = [];
    if (isReactor(source)) {
        ret.push(
        // Handle changes to the source component
        source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
            inheritProperties(target, source);
        }));
    }
    ret.push(
    // Handle cases where the target component has a prop set to undefined
    target.onPropertyChange((reactor, property, newValue, oldValue, type) => {
        // Only trigger when a prop is being cleared
        if (newValue === undefined) {
            inheritProperties(target, source);
        }
    }));
    return ret;
}
export function initComponent(reactor, project) {
    // Hook Component up with its ComponentDescription.
    promoteToComponent(reactor, project);
    // Now that all initial values, methods, inheritance, etc are set let the Component
    // further initialize itself (unless Project loading is going to do it).
    reactor.init?.();
    // Container.init (at least) creates a new stored property that needs to be evaluated
    // so do this after calling init.
    reactor.evaluateStoredProperties?.();
    reactor.invalidate?.();
}
export function promoteToComponent(reactor, project) {
    const componentType = reactor.componentType;
    const component = reactor;
    // When processing $_meta, $componentType triggers calls to promoteToComponent
    // Remove componentType from $_meta.properties
    if (typeof componentType !== 'string') {
        return;
    }
    //console.log(`promoteToComponent: ${reactor.name} ${componentType}`);
    //console.log('  component:', component);
    let master = resolveComponentType(componentType, project);
    if (!master) {
        //console.warn(`No master for componentType ${componentType} (substituting Play Kit/Orphan)`);
        master = resolveComponentType('Play Kit/Orphan', project);
    }
    component[MASTER] = master;
    //console.log('  master:', master);
    // Attach the ComponentDescription to the Reactor -- now it is a Component!
    const description = getComponentDescription(master, project);
    if (!description) {
        console.warn(`No ComponentDescription for componentType ${componentType}`);
        return;
    }
    component[DESCRIPTION] = description;
    //console.log('  description:', description);
    // Build up META
    const meta = getDefaultMetaData();
    extendDeep(meta, component[getReactorStoredProperty]('_meta'), master._meta, description._meta);
    removeIds(meta);
    // Hide private props for instances of custom components
    if ('componentType' in master) {
        for (const name in meta.properties) {
            const prop = meta.properties[name];
            if (prop.private) {
                prop.hidden = true;
            }
        }
    }
    // Update META
    component[META] = meta;
    //console.log('  META: ', component[META]);
    if (component._metaListeners) {
        component._metaListeners.forEach((o) => o.dispose());
    }
    component._metaListeners = [
        ...attachMetaListeners(component, component),
        ...attachMetaListeners(component, master),
        ...attachPropertyListeners(component, master),
    ];
    inheritProperties(component, master);
}
// Return a ComponentDescription for a given component
function getComponentDescription(component, project) {
    while (isReactor(component)) {
        if (isComponent(component)) {
            // the component is already a Component, so just use its ComponentDescription
            return component[DESCRIPTION];
        }
        else {
            // Walk up the inheritance chain
            component = resolveComponentType(component.componentType, project);
            if (!component) {
                return undefined;
            }
        }
    }
    // the component is already JS ComponentDescription
    return component;
}
export function getComponentProperties(component) {
    const props = {
        id: component[ID],
    };
    const propertyDescriptions = component[META].properties;
    if (!propertyDescriptions) {
        return props;
    }
    for (const key in propertyDescriptions) {
        const description = propertyDescriptions[key];
        if (description === undefined) {
            continue;
        }
        const value = component[key] ?? description.default;
        const { type: propType } = description;
        if (typeof value === 'function') {
            continue;
        }
        else if (propType === 'object') {
            if (isComponent(value)) {
                props[key] = getComponentProperties(value);
            }
            else {
                props[key] = convertToPlainObject(value);
            }
        }
        else if (propType === 'array') {
            props[key] = [];
            for (const el of value) {
                if (isComponent(el)) {
                    props[key].push(getComponentProperties(el));
                }
                else {
                    props[key].push(convertToPlainObject(el));
                }
            }
        }
        else {
            props[key] = value;
        }
    }
    return props;
    function convertToPlainObject(ob) {
        const props = {};
        for (const key in ob) {
            const value = ob[key];
            if (typeof value !== 'function') {
                props[key] = value;
            }
        }
        return props;
    }
}
// Merge inherited properties from a master (optional) and per-property defaults into a component
function inheritProperties(component, master) {
    const defProps = {};
    const meta = component[META];
    for (const property in meta.properties) {
        const defaultValue = meta.properties[property].default;
        if (master.hasOwnProperty(property)) {
            // Only inherit if the property is actually defined on the master
            // TODO: For Play Kit components this results in properties being pulled from the base component's
            // description (i.e. "name"). I don't think this is intentional or desirable.
            defProps[property] = master[property];
        }
        else if (defaultValue !== undefined) {
            // Only inherit if the default value is set
            defProps[property] = defaultValue;
        }
    }
    // Save the default properties as an object that will be added to the component's prototype chain
    // (by ReactorObject.updatePrototypeChain). We can then use OwnProperties to tell when a property
    // has been overridden from its default, even if by the same value.
    component[defaults] = defProps;
    // Some default properties, like style, are objects which won't behave as desired if inherited.
    // The default value will be changed instead of an instance value.
    // Therefore, we apply object values directly to the component instance.
    for (const property in defProps) {
        if (typeof defProps[property] === 'object') {
            // inheritProperties is called at different times during a component instance's life.
            // Don't overwrite existing properties.
            if (component[property] === undefined) {
                component[property] = defProps[property];
            }
        }
    }
    // Put the new defaults object on the component's prototype chain.
    component[updatePrototypeChain]();
    // TODO: The prior implementation set the default property values on the instance itself
    // which sent property change notifications. We should probably send change notifications here.
    // Merge blocks into _runtimeBlocks
    // Order:
    //  1. master blocks
    //  2. local blocks
    const blocks = [...(master?.blocks ?? []), ...(component.blocks ?? [])];
    component._runtimeBlocks = blocks;
}
export function getDescription(component) {
    return component[DESCRIPTION];
}
// Follow an import path through a projects import hierarchy
function resolveImport(importPath, root) {
    const path = importPath.split('/');
    for (const name of path) {
        root = root.imports?.[name];
        if (!root) {
            break;
        }
    }
    return root;
}
export function getBeforeCreatePromptProps(componentType, project) {
    const description = resolveComponentType(componentType, project);
    if (description?.['_meta']?.beforeCreatePrompt) {
        return description?.['_meta']?.beforeCreatePrompt;
    }
    return undefined;
}
// Return the ComponentMaster or ComponentDescription for a componentType
export function resolveComponentType(componentType, project) {
    let root = project;
    const { importPath, unqualifiedType } = parseComponentType(componentType);
    if (importPath) {
        // Component is referencing an imported project
        root = resolveImport(importPath, project);
        if (!root) {
            // console.warn(`Project has no ${importPath} import for "${componentType}".`);
            return undefined;
        }
    }
    if (isReactor(root)) {
        // This is a Component master
        const master = root.Components?.[unqualifiedType];
        if (!master) {
            // console.warn(`Component "${unqualifiedType}" doesn't exist. importPath:${importPath}.`);
            return undefined;
        }
        return master;
    }
    else {
        // This is a JS module, import the Description directly
        let description = root[unqualifiedType + 'Description'];
        if (!description) {
            // console.warn(`Module ${importPath} does not export ${unqualifiedType}Description`);
            return undefined;
        }
        description = buildComponentDescription(description, project);
        return description;
    }
}
// Return a ComponentDescription with protype/extends processing
function buildComponentDescription(description, project) {
    // Don't alter the original.
    description = {
        ...description,
    };
    // If the description "extends" another perform a manual inheritance.
    // Also treate componentType the same
    if (description.extends) {
        const protoDescription = resolveComponentType(description.extends, project);
        if (protoDescription && !isReactor(protoDescription)) {
            // TODO: Do we really want ALL properties inherited?
            description = { ...protoDescription, ...description };
            // Inherit the protoDescription's prototype.
            if (description.prototype &&
                description.prototype !== protoDescription?.prototype &&
                protoDescription?.prototype) {
                appendPrototype(description.prototype, protoDescription.prototype);
            }
            // Extend the metadata too
            extendDeep(description._meta, protoDescription._meta);
        }
        else {
            console.error(`Invalid Component Description for ${description.extends} while processing ${description.name}`);
        }
    }
    return description;
}
// Find, in the current project, the component at the specified path.
// Return undefined if it none exists.
// TODO: scope?
export function getComponentByPath(path) {
    if (path === undefined) {
        return undefined;
    }
    // TODO:
    return undefined;
}
export function isCustomComponentInstance(component) {
    if (!component.componentType) {
        return false;
    }
    return isReactor(component[MASTER]);
}
export function isRelativelyPositioned(component) {
    return component.positionType === 'relative';
}
export function isSection(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Section';
}
export function isProjectView(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Project';
}
export function isViewContainer(component) {
    return !!component?.componentType && !!component.children;
}
export function isFlexContainer(component) {
    // Some containers, e.g. Emitter, don't have isFlex method.
    return !!component && isContainer(component) && component.isFlex?.();
}
export function isOrderedItem(component) {
    return (!!component && isSection(component)) || isFlexItem(component);
}
export function isFlexItem(component) {
    return !!component && isFlexContainer(component?.parent);
}
export function isSectionOnlyChild(component) {
    return (isSection(component) && !!component?.parent?.children && component.parent.children.length === 1);
}
export function isLayoutComponent(component) {
    return (isSection(component) ||
        isPage(component) ||
        isProjectView(component) ||
        component.name === 'rootViewContainer');
}
// return the Sections that are direct children of the root view
export function getRootViewSections(component) {
    const rootView = component.project.rootView;
    return rootView.children.filter((child) => isSection(child));
}
// If a component is relatively positioned we want to use an absolute positioned ancestor
// for certain math. This function returns the closest ancestor that is absolute positioned.
function getClosestPositionedAncestor(component) {
    let parent = component.parent;
    while (parent) {
        if (parent.positionType === 'absolute') {
            return parent;
        }
        parent = parent.parent;
    }
    return undefined;
}
// converts an array of components into an array of the closest ancestor where the position
// is known. Either a flex item or an absolute positioned component.
export function convertToOnlyPositioned(components) {
    return components
        .map((c) => {
        if (isRelativelyPositioned(c) && !isFlexItem(c)) {
            return getClosestPositionedAncestor(c);
        }
        return c;
    })
        .filter(Boolean);
}
export function eventNameFromHandlerName(handlerName) {
    return lowerFirstLetter(handlerName.slice(2));
}
function lowerFirstLetter(s) {
    return s[0].toLowerCase() + s.slice(1);
}
// A component supports design mode if its description says it does or if any of its parents do.
export function componentSupportsDesignMode(component) {
    while (component) {
        const supportComponentDesignMode = !!component[META]?.supportComponentDesignMode;
        if (supportComponentDesignMode) {
            return true;
        }
        component = component.parent;
    }
    return false;
}
export function componentSupportsCropping(component) {
    // TODO: Make this more extensible
    return component?.componentType === 'Play Kit/Image';
}
export function isEffect(componentOrEffect) {
    return componentOrEffect[DESCRIPTION]._meta.isEffect === true;
}
export function isStyle(reactor) {
    return reactor?.componentType === 'Play Kit/Style';
}
export function isAncestor(view, ancestor) {
    let c = view.parent;
    while (c) {
        if (ancestor === c) {
            return true;
        }
        c = c.parent;
    }
    return false;
}
// TODO: META may have the fully default merged values without needing to walk the tree.
export function getDefaultPropertyValue(project, componentType, property) {
    while (componentType) {
        const description = resolveComponentType(componentType, project);
        const value = description._meta.properties?.[property]?.default;
        if (value !== undefined)
            return value;
        componentType = description.extends;
    }
    return undefined;
}
