import { getPrices } from '@playful/api';
import {
  SubscriptionModalProps,
  subscriptionPaywalls,
} from '@playful/workbench/SubscriptionPaywalls';
import React, { useCallback, useEffect, useRef } from 'react';
import useSWR from 'swr';

import { useUserProjects } from '../hooks/useProjects';
import { useRouter } from '../hooks/useRouter';
import { useUserContext } from '../user/UserContext';
import useDisclosure from '../utils/useDisclosure';
import { SubscriptionFlow } from './SubscriptionFlow';

export const GlobalSubscriptionModal = () => {
  const { user, hasFlag, userFlags } = useUserContext();
  const { projectInfos } = useUserProjects(user.id);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { asPath } = useRouter();
  const modalPropsRef = useRef<SubscriptionModalProps>();

  const handleOpenModal = useCallback(
    ({ modalProps }: { modalProps: SubscriptionModalProps }) => {
      modalPropsRef.current = modalProps;
      onOpen();
    },
    [onOpen],
  );

  useEffect(() => {
    const { dispose } = subscriptionPaywalls.onOpenModal(handleOpenModal);

    return () => {
      dispose();
    };
  }, [handleOpenModal]);

  useEffect(() => {
    subscriptionPaywalls.setup({
      user,
      projects: projectInfos,
      userFlags,
    });

    return () => {
      subscriptionPaywalls.teardown();
    };
  }, [user, projectInfos, hasFlag, userFlags]);

  function handleOnClose() {
    onClose();
    modalPropsRef.current?.onClose?.();
    modalPropsRef.current = undefined;
  }

  function handleClickFreePlan() {
    onClose();
    modalPropsRef.current?.handleClickFreePlan?.();
    modalPropsRef.current = undefined;
  }

  const { data: priceInfo } = useSWR('prices', getPrices, {
    revalidateOnFocus: false,
  });

  return (
    <SubscriptionFlow
      returnPath={modalPropsRef.current?.returnPath || asPath}
      returnTo={modalPropsRef.current?.returnTo}
      isOpen={isOpen}
      onFreePlanClicked={handleClickFreePlan}
      onClose={handleOnClose}
      priceInfo={priceInfo}
      successModal={modalPropsRef.current?.successModal}
    />
  );
};
