import { useCallbackRef } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/**
 * This allows an updating of an initial SSR value with a client-only value.
 *
 * Normally, you could use swr for this with a default value, and update on load,
 * but there are times when you want to use server-side data and switch to
 * client side, sometimes after a synchronous callback or setState.
 *
 * once useClientValue is toggled to true, and the hook detects we're in the client,
 * the value will switch over, and not cause errors with nextjs hydration.
 *
 * NOTE: setting useClientValue back to false will not cause it to switch back to the SSR value.
 */
export function useTransferSSRState<T>({
  SSRVal,
  clientVal,
  useClientVal,
  onBrowser,
}: {
  SSRVal: T;
  clientVal: T;
  useClientVal: boolean;
  onBrowser?: () => void;
}) {
  const [inBrowser, setInBrowser] = useState(false);
  const [shouldSwitch, setSwitch] = useState(false);
  const safeCb = useCallbackRef(onBrowser);

  useEffect(() => {
    if (useClientVal && !shouldSwitch) setSwitch(true);
  }, [useClientVal, shouldSwitch]);

  // runs cb when in client
  useEffect(() => {
    setInBrowser(true);
    safeCb?.();
  }, [safeCb]);

  return shouldSwitch && inBrowser ? clientVal : SSRVal;
}
