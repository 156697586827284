import type { ChildPanel } from '@playful/frontend/workbench/content/ContentPanel';
import type { ProjectId } from '@playful/runtime';
import { Tutorials } from '@playful/sanity';
import { customEvent } from '@playful/telemetry';
import { isObjectNotArray } from '@playful/utils';
import cloneDeep from 'lodash/cloneDeep';
import { proxy, snapshot, subscribe } from 'valtio';
import { derive, watch } from 'valtio/utils';

export const splitterSize = 1;
export const defaultFontSize = 12;

const defaultContentTab = 'kits';

export type InspectorSubPanel = 'addEffect' | 'javascript' | 'styles' | 'blocks';

const initialProjectWorkbenchState: ProjectWorkbenchState = {
  expandedNodes: [],
  lastUpdated: new Date().getTime(),
};

type ChildPanels = Record<string, ChildPanel>;

const initialState = {
  workbench: {
    hasSeenKits: false,
    hasSeenWorkbenchTourTip: false,
    panelWidths: {
      javascriptInspector: 0,
    },
  },
  projectWorkbenchStateCollection: {} as Record<string, ProjectWorkbenchState>,
};

export type ProjectWorkbenchState = { expandedNodes: string[]; lastUpdated: number };

export const contentPanel = proxy({
  activeTab: defaultContentTab as string | undefined,
  isExpanded: false,
  childPanels: {} as ChildPanels,
  flashCount: 0, // increments if the user attempts to open a content panel already open
  tutorials: null as Tutorials | null,
  tutorialVideo: null as {
    title: string;
    id: string;
  } | null,
});

const inspectorPanelInitialState = {
  subPanel: undefined as InspectorSubPanel | undefined,
  isExpanded: true,
  isExtraWide: false,
  addedEffect: undefined as string | undefined,
};

export const inspectorPanel = proxy<typeof inspectorPanelInitialState & { width?: number }>(
  inspectorPanelInitialState,
);

derive(
  {
    width: (get) => {
      const state = get(inspectorPanel);
      let width = state.isExpanded ? 312 : 0;
      if (state.subPanel === 'blocks') {
        width = 578;
      }
      return width;
    },
  },
  {
    proxy: inspectorPanel,
  },
);

watch((get) => {
  const subPanel = get(inspectorPanel).subPanel;
  if (subPanel === 'blocks') {
    customEvent('vs-open');
  }
});

export function resetInspectorPanel() {
  const resetState = cloneDeep(inspectorPanelInitialState);
  Object.keys(resetState).forEach((key) => {
    (inspectorPanel as any)[key] = (resetState as any)[key];
  });
}

export const objectTree = proxy({
  collapsedNodes: [] as string[],
});

export const globalState = proxy(initialState);

export const designerHoverComponents = proxy({
  hoveredComponentIds: [] as number[],
});

// Persist the global state as it changes.
// TODO: Global state we don't want persisted.
subscribe(globalState, () => saveGlobalState());

export function loadGlobalState(): void {
  if (typeof localStorage !== 'undefined') {
    const json = localStorage.getItem('globalState');
    if (json) {
      const loadedState = JSON.parse(json);
      mergeObjects(globalState, loadedState);
      mergeProjectWorkbenchState(globalState, loadedState);

      // Make sure everyone sees the kits at least once.
      if (!globalState.workbench.hasSeenKits) {
        globalState.workbench.hasSeenKits = true;
        contentPanel.activeTab = defaultContentTab;
      }
    }
  }
}

export function saveGlobalState(): void {
  localStorage.setItem('globalState', JSON.stringify(globalState, null, 2));
}

// Preserves the projectWorkbenchState when a project is copied
export function cloneProjectWorkbenchState(oldId: ProjectId, newProjectId: ProjectId) {
  if (globalState.projectWorkbenchStateCollection[oldId]) {
    globalState.projectWorkbenchStateCollection[newProjectId] = {
      ...globalState.projectWorkbenchStateCollection[oldId],
    };
  }
}

// Recursively merge object b's properties on top of object a's.
function mergeObjects(a: any, b: any): void {
  for (const key in a) {
    if (key in b) {
      if (isObjectNotArray(a[key])) {
        mergeObjects(a[key], b[key]);
      } else {
        a[key] = b[key];
      }
    }
  }
}

// Merge the designState in a special-ish way to deal with the dynamic project indexing.
function mergeProjectWorkbenchState(a: any, b?: any): void {
  if (!b) {
    return;
  }
  for (const key in b.projectWorkbenchStateCollection) {
    if (!a.projectWorkbenchStateCollection[key]) {
      a.projectWorkbenchStateCollection[key] = initialProjectWorkbenchState;
    }

    for (const propertyKey in b.projectWorkbenchStateCollection[key]) {
      if (propertyKey in initialProjectWorkbenchState) {
        a.projectWorkbenchStateCollection[key][propertyKey] =
          b.projectWorkbenchStateCollection[key][propertyKey];
      }
    }
  }
}

export function toggleInspector(show: boolean) {
  const { isExpanded } = snapshot(inspectorPanel);
  if (isExpanded !== show) {
    customEvent(show ? 'inspector-expanded' : 'inspector-minimized');
    inspectorPanel.isExpanded = show;
  }
  // reset to default width
  inspectorPanel.isExtraWide = false;
}

export function toggleWideInspector(wide: boolean) {
  const { isExtraWide } = snapshot(inspectorPanel);
  if (isExtraWide !== wide) {
    inspectorPanel.isExtraWide = wide;
  }
}

export function showInspectorPanel(subTab: InspectorSubPanel | undefined = undefined): void {
  inspectorPanel.isExpanded = true;
  inspectorPanel.subPanel = subTab;
  return;
}

export const hideContentPanel = () => (contentPanel.isExpanded = false);
export const showContentPanel = () => (contentPanel.isExpanded = true);
export const toggleContentPanel = () => (contentPanel.isExpanded = !contentPanel.isExpanded);

declare module 'valtio' {
  function useSnapshot<T extends object>(p: T): T;
}
