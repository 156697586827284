import { shallowEqual, isObjectNotArray, isObjectOrArray } from '@playful/utils';
import { isSafeToRunProject, migrateProjectState } from '@playful/migrator';
import { INITIAL_STATE, initComponent, isComponent } from './component.js';
import { importProjectOrModule, importProjectsAndModules, unimportProjectOrModule, } from './importer.js';
import { perfTracker } from './performance.js';
import { ID, parseComponentType } from './reactor.js';
import { createReactorFactory } from './reactorFactory.js';
import { META, setReactorStoredProperty } from './reactorObject.js';
export async function loadProject(args) {
    const { state, info, mainProject, resourceRoot, basePath, playkit } = args;
    // Calculate the max in-use reactor id
    let maxReactorId = 0;
    forEachObject(state, (obj) => {
        if (obj[ID] > maxReactorId) {
            maxReactorId = obj[ID];
        }
    });
    const reactorFactory = createReactorFactory(maxReactorId + 1);
    const project = reactorFactory.createReactor({
        Imports: [],
        ...state,
        pages: undefined,
        Components: undefined,
        style: undefined,
        effects: undefined, // Ensure projects don't get effects
    });
    // Setup project-level functions
    project.createReactor = reactorFactory.createReactor.bind(reactorFactory);
    project.getReactorById = reactorFactory.getReactorById.bind(reactorFactory);
    project.hasReactor = reactorFactory.hasReactor.bind(reactorFactory);
    project.forEachReactor = reactorFactory.forEachReactor.bind(reactorFactory);
    project.createComponent = (state) => createComponent(project, state);
    project.getComponentById = (id) => reactorFactory.getReactorById(id);
    // Initialize Project imports
    project.imports = {};
    // All projects have a reference to the main (root/host/global/etc) project.
    project.mainProject = mainProject || project;
    project.resourceRoot = resourceRoot || mainProject?.resourceRoot || '';
    project.basePath = basePath || mainProject?.basePath || '';
    // TODO:
    if (info) {
        // Funky cast to override readonly.
        project.info = { ...info }; // TODO: not updated as info changes (e.g. after Project save)
    }
    // Wait for all imports before updating Reactor prototypes which may come from them.
    perfTracker?.startMark('imports');
    await importProjectsAndModules(project, playkit);
    perfTracker?.endMark('imports');
    project.onPropertyChange(async (reactor, property, newValue, oldValue, type) => {
        property = property;
        if (property === 'Imports') {
            processImportChange(project, newValue, oldValue, playkit);
        }
    });
    // Init the project component (has to be done after Imports so that we can find Play Kit)
    perfTracker?.startMark('project-init-component');
    initComponent(project, project);
    perfTracker?.endMark('project-init-component');
    // Setup Components array
    project[setReactorStoredProperty]('Components', project.createReactor({}));
    for (const name in state.Components) {
        const comp = createComponent(project, state.Components[name]);
        project.Components[setReactorStoredProperty](name, comp);
    }
    // TODO: It's annoying to have to do this stuff here. Find a better way.
    // Setup pages
    project[setReactorStoredProperty]('pages', project.createReactor(state.pages?.map(project.createComponent) || []));
    // Style
    if (state.style) {
        project[setReactorStoredProperty]('style', project.createComponent(state.style));
    }
    return project;
}
/**
 * Create (and init) a component from a bag of properties
 *
 * This also recurses and creates any child components.
 */
function createComponent(project, properties) {
    const component = project.createReactor({ [ID]: properties[ID] });
    component[INITIAL_STATE] = properties;
    for (const property in properties) {
        let value = properties[property];
        // TODO: remove this hack
        if (['children', 'pages'].includes(property)) {
            const t = value.map((v) => createComponent(project, v));
            // createComponent is called by undo which needs children[ID]s to be stable.
            if (value[ID] !== undefined) {
                t[ID] = value[ID];
            }
            value = project.createReactor(t);
        }
        else if (Array.isArray(value)) {
            // Look for components in arrays
            value = value.map((v) => (v.componentType ? createComponent(project, v) : v));
        }
        else if (isObjectNotArray(value)) {
            if (value.componentType && !isComponent(value)) {
                value = createComponent(project, value);
            }
        }
        component[setReactorStoredProperty](property, value);
    }
    initComponent(component, project);
    return component;
}
async function processImportChange(project, newValue, oldValue, playkit) {
    const newImports = newValue.reduce((obj, cur) => ({ ...obj, [cur.name]: cur }), {});
    const oldImports = oldValue.reduce((obj, cur) => ({ ...obj, [cur.name]: cur }), {});
    const allImportNames = new Set([...Object.keys(newImports), ...Object.keys(oldImports)]);
    const changedImports = new Set();
    // Diff the imports and take action
    for (const name of allImportNames) {
        const newImp = newImports[name];
        const oldImp = oldImports[name];
        if (newImp === undefined) {
            // Remove the import
            unimportProjectOrModule(oldImp, project);
            changedImports.add(name);
        }
        else if (!shallowEqual(newImp, oldImp)) {
            // Added or changed the import
            await importProjectOrModule(newImp, project, playkit);
            changedImports.add(name);
        }
    }
    // Re-init components from any change imports
    project.forEachReactor((component) => {
        if (isComponent(component)) {
            const { importPath } = parseComponentType(component.componentType);
            if (changedImports.has(importPath)) {
                initComponent(component, project);
            }
        }
        return true;
    });
}
// migrateProject alters the ProjectState in place.
export async function migrateProject(state) {
    perfTracker.startMark('migrate');
    const currentMigrations = state.appliedMigrations || [];
    await migrateProjectState(state);
    const errors = [];
    if (!isSafeToRunProject(state, errors)) {
        const error = new Error(`Unable to load project. It may have migrations applied to it that are known to another branch. ${JSON.stringify(errors)}`);
        console.error(error);
        throw error;
    }
    perfTracker.endMark('migrate');
    return {
        newMigrations: state.appliedMigrations?.filter((x) => !currentMigrations.includes(x)),
    };
}
// Iterate and recurse down an (acyclic) object hierarchy, calling back for each one.
export function forEachObject(obj, callback) {
    if (Array.isArray(obj)) {
        for (const element of obj) {
            if (isObjectOrArray(element)) {
                forEachObject(element, callback);
            }
        }
    }
    else {
        for (const property in obj) {
            const value = obj[property];
            if (isObjectOrArray(value)) {
                forEachObject(value, callback);
            }
        }
    }
    callback(obj);
}
// TODO: DRY with other isProject
export function isProject(component) {
    return component?.mainProject && component?.mainProject === component;
}
export function componentIsSizable(component) {
    if (component.parent) {
        return component.parent[META].childrenAreSizable ?? true;
    }
    return true;
}
