import { isNil } from '@playful/utils';
import maxBy from 'lodash/maxBy.js';
import minBy from 'lodash/minBy.js';
import { applyToPoint, compose, rotateDEG, scale } from './matrix.js';
export function rotatedScaledPointsFromRectangle(rect) {
    const center = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
    return pointsFromRectangle(rect).map((point) => {
        const { x, y } = rotateScalePointAroundOrigin(point, center, rect.scale, rect.rotation);
        return { x, y };
    });
}
export function viewStatesIntersect(r1, r2) {
    const rotR1 = rotatedScaledPointsFromRectangle(r1);
    const rotR2 = rotatedScaledPointsFromRectangle(r2);
    const axes = [...orthogonals(rotR1), ...orthogonals(rotR2)];
    for (const axis of axes) {
        const range1 = getProjectedRange(rotR1, axis);
        const range2 = getProjectedRange(rotR2, axis);
        if (range1.max < range2.min || range2.max < range1.min) {
            return false;
        }
    }
    return true;
}
export function viewCornersIntersect(r1, r2) {
    const r1Points = pointsFromCorners(r1);
    const r2Points = pointsFromCorners(r2);
    const axes = [...orthogonals(r1Points), ...orthogonals(r2Points)];
    for (const axis of axes) {
        const range1 = getProjectedRange(r1Points, axis);
        const range2 = getProjectedRange(r2Points, axis);
        if (range1.max < range2.min || range2.max < range1.min) {
            return false;
        }
    }
    return true;
}
export function getRectangleFromCorners(corners) {
    const { tl, tr, bl, br } = corners;
    const x1 = Math.min(tl.x, tr.x, bl.x, br.x);
    const y1 = Math.min(tl.y, tr.y, bl.y, br.y);
    const x2 = Math.max(tl.x, tr.x, bl.x, br.x);
    const y2 = Math.max(tl.y, tr.y, bl.y, br.y);
    return { x: x1, y: y1, w: x2 - x1, h: y2 - y1 };
}
export function getRectangleWithContactSegmentsFromRectangle(rect) {
    return getRectangleWithContactSegmentsFromCorners(getCornersFromRectangle(rect));
}
// returns an AABB and points/lines of intersection with original rect
export function getRectangleWithContactSegmentsFromCorners(corners) {
    const { tl, tr, bl, br } = corners;
    const x1 = Math.min(tl.x, tr.x, bl.x, br.x);
    const y1 = Math.min(tl.y, tr.y, bl.y, br.y);
    const x2 = Math.max(tl.x, tr.x, bl.x, br.x);
    const y2 = Math.max(tl.y, tr.y, bl.y, br.y);
    const points = [tr, tl, bl, br];
    // is Axis Aligned Bounding Box?
    const isAABB = tl.y === tr.y || tl.y == bl.y;
    let top, right, left, bottom;
    if (isAABB) {
        top = { start: x1, end: x2 };
        left = { start: y1, end: y2 };
        bottom = { start: x1, end: x2 };
        right = { start: y1, end: y2 };
    }
    else {
        const minY = minBy(points, (p) => p.y);
        top = { start: minY.x, end: minY.x };
        const maxY = maxBy(points, (p) => p.y);
        bottom = { start: maxY.x, end: maxY.x };
        const minX = minBy(points, (p) => p.x);
        left = { start: minX.y, end: minX.y };
        const maxX = maxBy(points, (p) => p.x);
        right = { start: maxX.y, end: maxX.y };
    }
    return { x: x1, y: y1, w: x2 - x1, h: y2 - y1, top, bottom, left, right };
}
export function getCornersFromViewState(view) {
    return {
        tl: { x: view.x, y: view.y },
        tr: { x: view.x + view.width, y: view.y },
        bl: { x: view.x, y: view.y + view.height },
        br: { x: view.x + view.width, y: view.y + view.height },
    };
}
export function getCornersFromRectangle(rect) {
    return {
        tl: { x: rect.x, y: rect.y },
        tr: { x: rect.x + rect.w, y: rect.y },
        bl: { x: rect.x, y: rect.y + rect.h },
        br: { x: rect.x + rect.w, y: rect.y + rect.h },
    };
}
export function rotateScalePointAroundOrigin(point, origin, _scale, rotDegrees) {
    return applyToPoint(compose(rotateDEG(rotDegrees, origin.x, origin.y), scale(_scale, _scale, origin.x, origin.y)), point);
}
// Return a Rectangle with a top-left origin and integer values.
export function normalizeRect(x1, y1, x2, y2) {
    return {
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        w: Math.abs(x1 - x2),
        h: Math.abs(y1 - y2),
    };
}
export function unionAndNormalizeRect(a, b) {
    if (!a)
        return b;
    if (!b)
        return a;
    const x = Math.min(a.x, b.x);
    const y = Math.min(a.y, b.y);
    const x2 = Math.max(a.x + a.w, b.x + b.w);
    const y2 = Math.max(a.y + a.h, b.y + b.h);
    return normalizeRect(x, y, x2, y2);
}
function pointsFromRectangle(rect) {
    return [
        { x: rect.x, y: rect.y },
        { x: rect.x + rect.width, y: rect.y },
        { x: rect.x + rect.width, y: rect.y + rect.height },
        { x: rect.x, y: rect.y + rect.height },
    ];
}
function getProjectedRange(points, axis) {
    const range = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };
    for (const point of points) {
        const projected = axis.x * point.x + axis.y * point.y;
        if (projected < range.min) {
            range.min = projected;
        }
        if (projected > range.max) {
            range.max = projected;
        }
    }
    return range;
}
function orthogonals(points) {
    const ret = [];
    for (let i = 0; i < points.length; i++) {
        const p1 = points[i];
        const p2 = points[(i + 1) % points.length];
        ret.push(orthogonal({
            x: p2.x - p1.x,
            y: p2.y - p1.y,
        }));
    }
    return ret;
}
function orthogonal(vector) {
    return normalize({ x: -vector.y, y: vector.x });
}
function normalize(vector) {
    const magnitude = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
    return { x: vector.x / magnitude, y: vector.y / magnitude };
}
export function pointsFromCorners(corners) {
    const { tl, tr, br, bl } = corners;
    return [tl, tr, br, bl];
}
/**
 * Returns all the component properties related to it's location, size, scale, and rotation
 */
export function getViewState(view) {
    return {
        /* Helpful for debugging.
        type: view.componentType,
        name: view.name,
        */
        x: view.x,
        y: view.y,
        width: view.width,
        height: view.height,
        rotation: view.rotation,
        scale: view.scale,
        sizeMode: view.sizeMode,
        fontSize: view.fontSize,
        fontScaleOperation: view.fontScaleOperation,
        html: view.html,
    };
}
export function getViewBoundingBox(views, { rootRelative } = {}) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (const view of views) {
        // pages don't have x or y.
        // in general there's no absolute guarantee any given view will have all critical geometry, so we
        // should set defaults out of an abundance of caution.
        const { x = 0, y = 0 } = rootRelative ? view.geometry.getGlobalCoordinates() : view;
        if (isNil(view.width) || isNil(view.height))
            console.error(`View ${view.id} has either an undefined width (${view.width}) or height (${view.height}). "Sensible" fallbacks will be used, but this should't happen.`);
        const viewWidth = view.width ?? 100;
        const viewHeight = view.height ?? 100;
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + viewWidth);
        maxY = Math.max(maxY, y + viewHeight);
    }
    return { minX, minY, maxX, maxY };
}
export function getViewBoundingBoxCorners(views, opts) {
    const { minX, minY, maxX, maxY } = getViewBoundingBox(views, opts);
    return {
        tl: { x: minX, y: minY },
        tr: { x: maxX, y: minY },
        bl: { x: minX, y: maxY },
        br: { x: maxX, y: maxY },
    };
}
export function calculateVisibilityPercent(viewport, elementRect) {
    const xOverlap = Math.max(0, Math.min(viewport.right, elementRect.right) - Math.max(viewport.left, elementRect.left));
    const yOverlap = Math.max(0, Math.min(viewport.bottom, elementRect.bottom) - Math.max(viewport.top, elementRect.top));
    const overlapArea = xOverlap * yOverlap;
    const elementArea = (elementRect.right - elementRect.left) * (elementRect.bottom - elementRect.top);
    return overlapArea / elementArea;
}
