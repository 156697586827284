/**
 * This class gathers and logs performance stats for the project.
 * It logs the avg, min, max, and p90 for each measure.
 *
 * You can enable it by passing the ?logPerformance=true query param.
 * or by setting localstorage to true: localStorage.setItem('logPerformance', 'true')
 *
 * The query param method doesn't work in the Workbench
 */
export class PerformanceTracker {
    _tickId = 0;
    _collecting = false;
    constructor() {
        this._collecting = shouldLogPerformance();
        if (this._collecting) {
            this.startLogging();
        }
    }
    // Start logging performance stats to the console
    startLogging(interval = 1000) {
        this.stopLogging();
        this._tickId = window.setInterval(() => {
            const stats = this.getStats();
            console.table(stats);
        }, interval);
    }
    // Stop logging performance stats to the console
    stopLogging() {
        if (this._tickId) {
            window.clearInterval(this._tickId);
        }
    }
    // Collect performance stats for a given time period
    collect(time = 5000) {
        if (this._collecting)
            return Promise.resolve({});
        this._collecting = true;
        return new Promise((resolve) => {
            window.setTimeout(() => {
                this._collecting = false;
                resolve(this.getStats());
            }, time);
        });
    }
    startMark(name) {
        if (!this._collecting)
            return;
        performance.mark(`hatch:${name}:start`);
    }
    endMark(name) {
        if (!this._collecting)
            return;
        performance.measure(`hatch:${name}`, `hatch:${name}:start`);
    }
    getStats() {
        const emptyStats = {
            sum: 0,
            count: 0,
            min: 0,
            max: 0,
            p90: 0,
            avg: 0,
            durations: [],
        };
        const entryStats = performance.getEntriesByType('measure').reduce((acc, entry) => {
            acc[entry.name] = acc[entry.name] || { ...emptyStats, durations: [] };
            acc[entry.name].sum += entry.duration;
            acc[entry.name].count++;
            acc[entry.name].min = Math.min(acc[entry.name].min, entry.duration);
            acc[entry.name].max = Math.max(acc[entry.name].max, entry.duration);
            acc[entry.name].durations.push(entry.duration);
            return acc;
        }, {});
        Object.keys(entryStats).forEach((key) => {
            // Clear our accumulated data
            performance.clearMeasures(key);
            performance.clearMarks(`${key}:start`);
            // Calculate the average and p90
            const entry = entryStats[key];
            entryStats[key].avg = entry.sum / entry.count;
            entryStats[key].p90 = getPercentile(entry.durations, 90);
            entryStats[key].durations = [];
        });
        return entryStats;
    }
}
const shouldLogPerformance = () => {
    if (typeof window === 'undefined')
        return false;
    const params = new URLSearchParams(window.location.search);
    if (params.has('logPerformance'))
        return true;
    return false;
};
function getPercentile(arr, percentile) {
    const sortedArr = arr.slice().sort((a, b) => a - b);
    const index = Math.ceil((percentile / 100) * sortedArr.length) - 1;
    return sortedArr[index];
}
export function flattenStats(stats) {
    return Object.keys(stats).reduce((acc, key) => {
        const entry = stats[key];
        if (entry.count === 1) {
            acc[key] = entry.avg;
        }
        else {
            acc[`${key}_count`] = entry.count;
            acc[`${key}_avg`] = entry.avg;
            acc[`${key}_min`] = entry.min;
            acc[`${key}_max`] = entry.max;
            acc[`${key}_p90`] = entry.p90;
        }
        return acc;
    }, {});
}
export const perfTracker = new PerformanceTracker();
