import { useCallback, useMemo, useState } from 'react';

// a la: https://chakra-ui.com/docs/hooks/use-disclosure
// but also supports async closing and opening states and indicators
export type DisclosureProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  defaultIsOpen?: boolean;
  defaultIsLoading?: boolean;
  onClose?(...args: unknown[]): void;
  onDismiss?(...args: unknown[]): void;
  onConfirm?(...args: unknown[]): void;
  onClosing?(...args: unknown[]): void;
  onOpening?(...args: unknown[]): void;
  onOpen?(...args: unknown[]): void;
};

export default function useDisclosure(props?: Partial<DisclosureProps> | null) {
  const {
    onClose,
    onDismiss,
    onConfirm,
    onOpen,
    onClosing,
    onOpening,
    defaultIsOpen,
    defaultIsLoading,
  } = props ?? {};
  const [isOpen, setOpen] = useState(defaultIsOpen ?? false);
  const [isLoading, setLoading] = useState(defaultIsLoading ?? false);

  const handleOnClose = useCallback(
    (...args: unknown[]) => {
      setOpen(false);

      onClose?.(...args);
    },
    [setOpen, onClose],
  );

  const handleOnOpen = useCallback(
    (...args: unknown[]) => {
      setOpen(true);

      onOpen?.(...args);
    },
    [setOpen, onOpen],
  );

  const handleOnConfirm = useCallback(
    (...args: unknown[]) => {
      setOpen(false);

      onConfirm?.(...args);
      onClose?.(...args);
    },
    [setOpen, onConfirm, onClose],
  );

  const handleOnDismiss = useCallback(
    (...args: unknown[]) => {
      setOpen(false);

      onDismiss?.(...args);
      onClose?.(...args);
    },
    [setOpen, onClose, onDismiss],
  );

  const handleLoading = useCallback(async (fn?: any) => {
    setLoading(true);
    await fn?.();
    setLoading(false);
  }, []);

  const handleOnClosing = useCallback(
    async (promise?: Promise<any>, ...args: unknown[]) => {
      await handleLoading(promise || onClosing);

      handleOnClose(...args);
    },
    [handleOnClose, handleLoading, onClosing],
  );

  const handleOnOpening = useCallback(
    async (promise?: Promise<any>, ...args: unknown[]) => {
      await handleLoading(promise || onOpening);

      handleOnOpen(...args);
    },
    [handleLoading, handleOnOpen, onOpening],
  );

  return useMemo(
    () => ({
      isOpen: !!isOpen,
      isLoading: !!isLoading,
      onOpen: handleOnOpen,
      onDismiss: handleOnDismiss,
      onConfirm: handleOnConfirm,
      onOpening: handleOnOpening,
      onClose: handleOnClose,
      onClosing: handleOnClosing,
      onToggle: isOpen ? handleOnClose : handleOnOpen,
    }),
    [
      isOpen,
      isLoading,
      handleOnOpen,
      handleOnDismiss,
      handleOnConfirm,
      handleOnOpening,
      handleOnClose,
      handleOnClosing,
    ],
  );
}
