import { createGenericContext } from '@playful/utils';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { HatchSpinner } from './components/HatchSpinner';
import { UseRandLoadingReturn, useRandLoadingMsg } from './utils/getRandomLoadingMsg';

type LoadingContext = UseRandLoadingReturn & {
  /** Delay before the component is displayed (in ms). Defaults to 0. */
  delay?: number;
};

type LoadingProps = Pick<Partial<LoadingContext>, 'message' | 'delay'>;

// pass in either children, or a message to display.
export function Loading({ message, delay }: LoadingProps) {
  const [display, setDisplay] = useState(false);
  const {
    message: ctxMsg = 'Loading...',
    delay: ctxDelay = 0,
    startCycling,
    stopCycling,
  } = useLoadingContext();

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), delay ?? ctxDelay);
    return () => clearTimeout(timeout);
  }, [delay, ctxDelay]);

  // start and stop rotation through random loading messages once we're displaying
  useEffect(() => {
    if (!display) return;

    startCycling();
    return stopCycling;
  }, [startCycling, stopCycling, display]);

  return display ? <HatchSpinner message={message ?? ctxMsg} /> : null;
}

const [hook, GenericProvider] = createGenericContext<LoadingContext>();

export const useLoadingContext = hook;

/** A light wrapper over the random message generator */
export function LoadingProvider({
  delay = 400,
  interval = 2500,
  ...props
}: PropsWithChildren<{ delay?: number; interval?: number }>) {
  const randLoadingMsgReturn = useRandLoadingMsg({ interval });

  return <GenericProvider value={{ delay, ...randLoadingMsgReturn }} {...props} />;
}
