import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export const toggleTheme = defineStyleConfig({
    defaultProps: {
        size: 'md',
    },
    baseStyle: {
        group: {
            backgroundColor: 'gray.50',
            display: 'flex',
            alignItems: 'center',
        },
        activeBG: {
            transition: 'left 0.2s ease-in-out, width 0.2s ease-in-out',
            backgroundColor: 'blue.500',
        },
        activeButton: {
            color: 'white',
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            color: 'gray.700',
        },
    },
    variants: {
        full: defineStyle(() => {
            return {
                group: {
                    width: '100%',
                },
                button: {
                    flexGrow: 1,
                    justifyContent: 'center',
                    fontWeight: 'medium',
                },
                activeBG: {
                    backgroundColor: 'blue.100',
                },
                activeButton: {
                    color: 'blue.800',
                },
            };
        }),
    },
    sizes: {
        lg: () => {
            return {
                group: {
                    borderRadius: 'md',
                },
                activeBG: {
                    borderRadius: 'md',
                },
                button: {
                    h: 12,
                    minW: 12,
                    fontSize: 'lg',
                    px: 6,
                    svg: { w: 7, h: 7 },
                },
            };
        },
        md: () => {
            return {
                group: {
                    borderRadius: 'md',
                },
                activeBG: {
                    borderRadius: 'md',
                },
                button: {
                    h: 10,
                    minW: 10,
                    fontSize: 'md',
                    px: 4,
                    svg: { w: 6, h: 6 },
                },
            };
        },
        sm: () => {
            return {
                group: {
                    borderRadius: 'md',
                },
                activeBG: {
                    borderRadius: 'md',
                },
                button: {
                    h: 8,
                    minW: 8,
                    px: 3,
                    fontSize: 'sm',
                    svg: { w: 5, h: 5 },
                },
            };
        },
        xs: () => {
            return {
                group: {
                    borderRadius: 'md',
                },
                activeBG: {
                    borderRadius: 'md',
                },
                button: {
                    h: 6,
                    minW: 6,
                    px: 2,
                    fontSize: 'xs',
                    svg: { w: 4, h: 4 },
                },
            };
        },
    },
});
