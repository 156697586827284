// Reactor
// - encapsulates state and exposes as expression ("$" prefixed) and value properties
// - evaluates expression properties to produce value properties
// - tracks dirty state (invalidate, validate, update)
// - property changes can be observed
import { isObjectNotArray, isObjectOrArray, ID } from '@playful/utils';
export { ID } from '@playful/utils';
export function isReactor(object) {
    if (!object) {
        return false;
    }
    return object._isReactor === true;
}
export function parseComponentType(qualifiedType) {
    if (qualifiedType.indexOf('/') === -1) {
        return { unqualifiedType: qualifiedType };
    }
    else {
        const s = qualifiedType.split('/');
        const unqualifiedType = s.pop();
        const importPath = s.join('/');
        return { importPath, unqualifiedType };
    }
}
export function apiComponentType(componentType) {
    const { importPath, unqualifiedType } = parseComponentType(componentType);
    // All unqualified types are found in Play Kit.
    // TODO: what about project-defined custom components?
    if (!importPath || importPath === 'Play Kit') {
        switch (unqualifiedType) {
            case 'Heading':
            case 'Subheading':
            case 'BodyText':
                return 'Text';
            default:
                return unqualifiedType;
        }
    }
    // Flying Emojis has been added to Play Kit but we're keeping the old one around for compatibility.
    if (importPath === 'Flying Emojis') {
        return 'FlyingEmojisHtml';
    }
    // TODO: Temp until we move Confetti to Play Kit (or maybe we'll handle like Flying Emojis above).
    if (importPath === 'Confetti') {
        return unqualifiedType;
    }
    return 'View';
    // return componentType;
}
export function qualifyComponentType(importPath, unqualifiedType) {
    return importPath !== undefined ? importPath + '/' + unqualifiedType : unqualifiedType;
}
// Remove ids from an object hierarchy or array in place.
export function removeIds(state) {
    delete state[ID];
    // Recurse on child objects and arrays;
    if (Array.isArray(state)) {
        for (const value of state) {
            if (isObjectOrArray(value)) {
                removeIds(value);
            }
        }
    }
    else {
        for (const key in state) {
            const value = state[key];
            if (isObjectOrArray(value)) {
                removeIds(value);
            }
        }
    }
    return state;
}
// TODO: dates, regex
export function serialize(state) {
    return JSON.stringify(
    // create a copy of the state before serializing to avoid mutating the original
    state, (_key, value) => {
        // ReactorArray ids are encoded as an extra element at the end of the array.
        if (Array.isArray(value) && value[ID]) {
            const valuePlusId = value.slice();
            valuePlusId.push(`__id:${value[ID]}`);
            return valuePlusId;
        }
        else if (isObjectNotArray(value) && value[ID]) {
            return { ...value, id: value[ID] };
        }
        else {
            return value;
        }
    }, 2);
}
function transformIdToSymbol(value) {
    if (Array.isArray(value) && value.length > 0) {
        // ReactorArray ids are encoded as an extra element at the end of the array.
        const idString = value[value.length - 1];
        if (typeof idString === 'string') {
            if (idString.startsWith('__id:')) {
                value[ID] = parseInt(idString.split(':')[1]);
                value.pop();
            }
        }
    }
    else if (isObjectNotArray(value) && value.id !== undefined) {
        // Translate old id values to new symbol value
        value[ID] = value.id;
        delete value.id;
    }
    return value;
}
// TODO: dates, regex
export function deserialize(s) {
    return JSON.parse(s, (_key, value) => {
        return transformIdToSymbol(value);
    });
}
export function cleanState(states) {
    const cleanedState = deserialize(serialize(states));
    removeIds(cleanedState);
    return cleanedState;
}
