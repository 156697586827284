import { getAuth } from '@playful/api';
import { onAuthError } from '@playful/telemetry';
import { AuthError, browserPopupRedirectResolver, getRedirectResult } from 'firebase/auth';

import { completeAuth } from './completeAuth';

// Called to process the result of a federated auth redirect
export async function checkRedirectResult() {
  try {
    const credential = await getRedirectResult(getAuth(), browserPopupRedirectResolver);
    if (credential) {
      return completeAuth(credential);
    }
  } catch (err: any) {
    onAuthError({ code: (err as AuthError).code, method: 'unknown' }, false);
    throw new Error(err.message, { cause: err });
  }
}
