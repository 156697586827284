/**
 * Joins an array into a sentence:
 * - `semanticJoin(['one'], 'and')` -> `'one'`
 * - `semanticJoin(['one', 'two'], 'and')` -> `'one and two'`
 * - `semanticJoin(['one', 'two', 'three'], 'or')` -> `'one, two, or three'`
 */
export function semanticJoin(arr: string[], conjunction: string) {
  const arrCopy = arr.slice();
  const last = arrCopy.pop();
  const oxfordComma = arrCopy.length > 1 ? ',' : ''; // i'm not a monster...

  return arrCopy.length > 0 ? `${arrCopy.join(', ')}${oxfordComma} ${conjunction} ${last}` : last;
}
