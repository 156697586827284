import type { Content, ContentCollection } from '@playful/runtime';
import { proxy, snapshot } from 'valtio';

export const projectDesignState = proxy({
  dirtyCount: 0,
  draggingContent: {} as ContentCollection,
});

export const incrementDirtyCount = () => {
  const { dirtyCount } = snapshot(projectDesignState);
  projectDesignState.dirtyCount = dirtyCount + 1;
};

export const resetDirtyCount = () => {
  projectDesignState.dirtyCount = 0;
};

const add = (stateKey: 'draggingContent', content: Content) => {
  const { [stateKey]: state } = snapshot(projectDesignState);
  const contentId = content.id as string;

  const existing = state?.[contentId] as Content;
  if (existing) {
    content = {
      ...existing,
      ...content,
      options: {
        ...existing?.options,
        ...content?.options,
      },
    };
  }

  projectDesignState[stateKey] = {
    ...state,
    [contentId]: content,
  } as ContentCollection;
};

const remove = (stateKey: 'draggingContent', content?: Content) => {
  if (!content) {
    projectDesignState[stateKey] = {};
  } else {
    const contentId = content.id as string;
    const { [stateKey]: state } = snapshot(projectDesignState);
    const { [contentId]: omit, ...rest } = state;
    projectDesignState[stateKey] = rest as ContentCollection;
  }
};

export const addDraggingContent = (content: Content) => {
  add('draggingContent', content);
};

export const removeDraggingContent = (content?: Content) => {
  remove('draggingContent', content);
};
