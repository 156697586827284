import React from 'react';

import { WelcomeFromDomainCTAModal } from './WelcomeFromDomainCTA';
import { WelcomeFromHover } from './WelcomeFromHover';
import { WelcomeSubscriptionModal } from './WelcomeSubscriptionModal';

export const WelcomeModals = () => {
  return (
    <>
      <WelcomeSubscriptionModal />
      <WelcomeFromDomainCTAModal />
      <WelcomeFromHover />
    </>
  );
};
