import { User, UserFlags } from '@playful/api';
import type { Component, Project, ProjectInfo, Properties } from '@playful/runtime';
import { customEvent } from '@playful/telemetry';
import { Emitter } from '@playful/vsc';

import type { Resource } from './project/resources';

const UPLOAD_SIZE_LIMIT = 10 * 1024 * 1024 * 1024; // 10GB

export type SubscriptionModalProps = {
  isPage?: boolean;
  onClose?: () => void;
  handleClickFreePlan?: () => void;
  returnPath?: string;
  returnTo?: string[];
  reason?: string;
  successModal?: string;
};

class OpenSubscriptionModalEvent {
  constructor(public readonly modalProps: SubscriptionModalProps) {}
}

class SubscriptionPaywalls {
  private _user: User | undefined;
  private _projects: ProjectInfo[] | undefined;
  private _userFlags: UserFlags | undefined;
  private readonly openSubscriptionModal = new Emitter<OpenSubscriptionModalEvent>();

  hasActiveSubscription = () => {
    return this._user?.subscriptionStatus === 'active';
  };

  onOpenModal(listener: (event: any) => void) {
    return this.openSubscriptionModal.event(listener);
  }

  openModal(modalProps: SubscriptionModalProps = {}) {
    customEvent('hatchpro-open', {
      location: modalProps.reason || 'unknown',
      url: window.location.href,
    });
    this.openSubscriptionModal.fire(new OpenSubscriptionModalEvent(modalProps));
  }

  uploadMedia(userResource: Resource, files: File[] | FileList) {
    if (this.hasActiveSubscription()) return true;
    if (!userResource) {
      console.error('No user resource provided');
      return true;
    }

    const newFileSize = [...files]
      .map((file: File) => file.size)
      .reduce((a: number, b: number) => a + b, 0);

    const existingFileSize = userResource.keys
      .map((resource) => resource.size || 0)
      .reduce((a, b) => a + b, 0);

    if (existingFileSize + newFileSize < UPLOAD_SIZE_LIMIT) return true;
    return false;
  }

  publishProject(projectInfo: ProjectInfo) {
    if (this.hasActiveSubscription()) return true;
    if (projectInfo.published) return true;

    const publishedProjects = this._projects?.filter((project) => project.published);
    if (!publishedProjects || publishedProjects.length < 3) return true;
    return false;
  }

  customDomain() {
    if (this.hasActiveSubscription()) return true;

    return false;
  }

  addPage(project: Project, selection?: Component[] | Properties[]) {
    if (this.hasActiveSubscription()) return true;

    let numberAdding = 1;

    if (selection) {
      const pagesInSelection = selection.filter((comp) => comp.componentType === 'Play Kit/Page');
      numberAdding = pagesInSelection.length;
    }

    if (project && project.pages.length + numberAdding < 4) {
      return true;
    }

    return false;
  }

  removeHatchLogo() {
    if (this.hasActiveSubscription()) return true;

    return false;
  }
  addGoogleAnalytics() {
    if (this.hasActiveSubscription()) return true;

    return false;
  }

  addPasswordProtection() {
    if (this.hasActiveSubscription()) return true;

    return false;
  }

  setup({
    user,
    projects,
    userFlags,
  }: {
    user?: User;
    projects?: ProjectInfo[];
    userFlags: UserFlags;
  }) {
    this._user = user;
    this._projects = projects;
    this._userFlags = userFlags;
  }

  teardown() {
    this._user = undefined;
    this._projects = undefined;
    this._userFlags = undefined;
  }
}

export const subscriptionPaywalls = new SubscriptionPaywalls();
