import { withF, withT } from '@playful/utils';
import { useEffect, useState } from 'react';

const verbs = [
  'hoisting',
  'tokenizing',
  'refurbishing',
  'tidying',
  'reticulating',
  'filtering',
  'relocating',
  'confabulating',
  'computing',
  'entangling',
  'traversing',
  'disrupting',
  'converging',
  'recalculating',
  'distinguishing',
  'obfuscating',
  'integrating',
  'minifying',
  'stabilizing',
];

const subjects = [
  'the frontend',
  'the backend',
  'satellites',
  'pixels',
  'buttons',
  'bits',
  'tachyons',
  'the gravitron',
  'crystal assembly',
  'the cloud',
  'web3',
  'the ai',
  'splines',
  'time and space',
  'a PC',
  'Windows 95',
  'ants',
  'subspace',
  'polarity',
];

function getRandomIdx(arr: string[]) {
  return Math.floor(Math.random() * arr.length);
}

export function getRandLoadingMsg() {
  return `${verbs[getRandomIdx(verbs)]} ${subjects[getRandomIdx(subjects)]}...`;
}

export type UseRandLoadingMsgProps = {
  interval?: number;
};

export type UseRandLoadingReturn = {
  /** A loading message to be displayed */
  message: string;
  /** Refresh loading message */
  refreshMsg: () => void;
  /** Start generating random messages */
  startCycling: () => void;
  /** Stop generating random messages */
  stopCycling: () => void;
};

export function useRandLoadingMsg(props: UseRandLoadingMsgProps = {}): UseRandLoadingReturn {
  const { interval = 2500 } = props;
  const [message, setMessage] = useState(getRandLoadingMsg());
  const [isCycling, setCycling] = useState(false);

  const refreshMsg = () => setMessage(getRandLoadingMsg());

  useEffect(() => {
    if (!isCycling) return;

    const intervalId = window.setInterval(refreshMsg, interval);
    return () => window.clearInterval(intervalId);
  }, [interval, isCycling]);

  return {
    message,
    refreshMsg,
    startCycling: withT(setCycling),
    stopCycling: withF(setCycling),
  };
}
