import {
  AppBar,
  AppBarLogo,
  AppBarSection,
  Box,
  BoxProps,
  RouteLink,
} from '@playful/design_system';
import { createGenericContext } from '@playful/utils';
import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useMobileScrollHacks } from '../hooks/useMobileScrollHacks';
import { ROUTE_HOME } from '../routes';

type PortalRenderFn = (getChildren: () => React.ReactNode | null) => React.ReactPortal | null;

const [hook, Provider] = createGenericContext<PortalRenderFn>();
export const useGlobalHeader = hook;

/**
 * The GlobalLayout renders the global header and any children as the main content.
 * The global header content can be set by using the GlobalHeader component, which will portal in its content.
 * This layout is rendered at the _app level, while the PageLayout is rendered at the page level.
 */
export function GlobalLayout({ children }: { children: React.ReactNode }) {
  const { ignoreScroll } = useMobileScrollHacks();
  const headerRef = useRef<HTMLElement>(null);
  const renderHeader = (getChildren: () => React.ReactNode | null) =>
    headerRef.current ? createPortal(getChildren(), headerRef.current) : null;

  return (
    <Provider value={renderHeader}>
      <Box
        onScroll={ignoreScroll}
        color={'#000'}
        display={'flex'}
        flexDirection={'column'}
        userSelect={'text'}
        minHeight='100dvh'
      >
        <Box as='header' position='sticky' top={0} zIndex={1100} ref={headerRef} />
        {children}
      </Box>
    </Provider>
  );
}

/**
 * This component is part of the MainLayout. The MainLayout renders a global header whose contents can be
 * set by using this component, which portals its contents into the MainLayout's header.
 */
export function GlobalHeaderWithLogo({
  children,
  showMark = true,
}: {
  children: React.ReactNode;
  showMark?: boolean;
}) {
  return (
    <GlobalHeader>
      <AppBarSection>
        <RouteLink
          display='flex'
          h='100%'
          justifyContent='center'
          alignItems='center'
          data-cy='appbar-logo'
          aria-label='hatch logo'
          href={ROUTE_HOME}
        >
          <AppBarLogo h={{ base: 7, md: 8 }} showMark={showMark} />
        </RouteLink>
      </AppBarSection>
      <AppBarSection width='100%' justifyContent='right'>
        {children}
      </AppBarSection>
    </GlobalHeader>
  );
}

export function GlobalHeader(props: BoxProps) {
  const portalToHeader = useGlobalHeader();
  const portalCb = useCallback(
    () => <AppBar top={0} minH={{ base: 12, md: 14 }} px={{ base: 3, lg: 4 }} {...props} />,
    [props],
  );

  return portalToHeader(portalCb);
}
