import { UserId, axiosRequest } from '@playful/api';
import {
  ProjectId,
  ProjectInfo,
  ProjectSnapshot,
  ProjectState,
  ResourceId,
  Tags,
  readRemoteProject,
  serialize,
} from '@playful/runtime';
import { ProjectAppliedMigrations } from '@playful/migrator';
import { PROJECT_JSON, Resource } from '@playful/workbench/project/resources';

// A type describing the ProjectInfo fields that can be updated by the Frontend.
export type MutableProjectInfo = Partial<
  Pick<
    ProjectInfo,
    | 'tags'
    | 'title'
    | 'permissions'
    | 'ownerName'
    | 'publishedUrl'
    | 'version'
    | 'modified'
    | 'googleAnalyticsId'
    | 'customHeaderContent'
    | 'pageMetadata'
    | 'password'
  >
>;

type UserByName = {
  name: string;
  id?: never;
};

type UserById = {
  id: UserId;
  name?: never;
};

export type UserOptions = UserByName | UserById;
export type UnsavedProjectInfo = Omit<
  ProjectInfo,
  'id' | 'created' | 'modified' | 'version' | 'publicUrl'
>;

export type ComponentEntry = {
  ownerId: UserId;
  projectId: ProjectId;
  projectTitle?: string;
  name: string;
  title?: string;
  description?: string;
  author?: string;
  public: boolean;
  tags?: Tags;
};

export async function readProject(
  info: ProjectInfo,
  projectResource?: Resource,
): Promise<{ state: ProjectState; etag: string | null; newMigrations?: ProjectAppliedMigrations }> {
  const res = projectResource ?? (await Resource.get(info.project));
  const { state, headers, newMigrations } = await readRemoteProject(res.getDataUrl(PROJECT_JSON));

  const etag = headers.get('ETag');

  return { state, etag, newMigrations };
}

// Fetch components
export async function getComponents() {
  const { data } = await axiosRequest('components', {
    method: 'GET',
  });

  return data as ComponentEntry[];
}

export async function getProjectBySlug({ slug, user }: { slug: string; user: UserOptions }) {
  const { id: userId, name: userName } = user;
  const userIdentifier = userId ?? `@${userName}`;

  const { data } = await axiosRequest(`users/${userIdentifier}/projects/${slug}`);

  return data as ProjectInfo;
}

export const getProjectInfoByIdPath = (id: string) => `projects/${id}`;

export async function getProjectById(id: string) {
  const { data } = await axiosRequest(getProjectInfoByIdPath(id));

  return data as ProjectInfo;
}

export async function updateProjectById(id: string, info: MutableProjectInfo) {
  const { data } = await axiosRequest(getProjectInfoByIdPath(id), { method: 'POST', data: info });

  return data as ProjectInfo;
}

export async function updateProjectState(
  id: string,
  projectState: ProjectState,
  currentEtag?: string,
  snapshot?: { snapshot: string; description: string }, // tells the server to snapshot the project before saving
) {
  const state = serialize(projectState);

  const params = snapshot ? new URLSearchParams(snapshot).toString() : undefined;
  const url = `${getProjectInfoByIdPath(id)}/state${params ? `?${params}` : ''}`;

  const { data } = await axiosRequest(url, {
    method: 'PUT',
    data: state,
    headers: {
      'If-Match': currentEtag ?? '',
    },
  });
  const { etag, updatedProjectInfo } = data;

  return { etag, updatedProjectInfo };
}

export async function optimizeProjectById(id: string) {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/optimize`, {
    method: 'PUT',
  });

  return data as { cleanedKeys: string[] };
}

export async function publishProject(id: ProjectId) {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/publish`, {
    method: 'PUT',
  });

  return data as ProjectInfo;
}

export async function unpublishProject(id: ProjectId) {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/unpublish`, {
    method: 'PUT',
  });

  return data as ProjectInfo;
}

export async function duplicateProject(
  id: ProjectId,
  snapshotId?: ResourceId,
  updates?: { title?: string },
) {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/duplicate`, {
    method: 'POST',
    data: {
      snapshotId,
      ...updates,
    },
  });

  return data as ProjectInfo;
}

export async function rollbackProject(id: ProjectId, resourceId: ResourceId) {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/rollback`, {
    method: 'POST',
    data: { resourceId },
  });

  return data as ProjectInfo;
}

export async function getSnapshots(id: string): Promise<ProjectSnapshot[]> {
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/snapshots`);
  return data as ProjectSnapshot[];
}

export async function setProjectSlug(id: string, slug: string) {
  // Create a new project
  const { data } = await axiosRequest(`${getProjectInfoByIdPath(id)}/slug`, {
    method: 'PUT',
    data: { slug },
  });

  return data as ProjectInfo;
}

export async function deleteProject(id: ProjectId) {
  await axiosRequest(getProjectInfoByIdPath(id), {
    method: 'DELETE',
  });
}

// Create a new project
export async function createProjectInfo(info: UnsavedProjectInfo) {
  const { data } = await axiosRequest(`projects`, {
    method: 'POST',
    data: info,
  });

  return data as ProjectInfo;
}

export async function getProjectsByUserName(name: string) {
  const { data } = await axiosRequest(`users/@${name}/projects`);

  return data as ProjectInfo[];
}

export async function getProjectsByUserId(id: string) {
  const { data } = await axiosRequest(`users/${id}/projects`);

  return data as ProjectInfo[];
}

export async function getProjectsByIdBatch(ids: string[]) {
  const { data } = await axiosRequest(`projects_batch_fetch`, {
    method: 'POST',
    data: { projectIDs: ids },
  });

  return data as ProjectInfo[];
}

export async function getProjects(tag?: string) {
  const { data } = await axiosRequest(`projects${tag ? `?tag=${encodeURIComponent(tag)}` : ''}`);
  return data as ProjectInfo[];
}
