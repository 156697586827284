import { getAuth, getFirebaseAuthToken } from '@playful/api';
import { onAuthError } from '@playful/telemetry';
import { fromPromiseRetry } from '@playful/utils';
import { AuthError, signInAnonymously } from 'firebase/auth';

import type { AuthSchema } from './AuthFlow/authSchema';
import { keyCopyFromToken } from './AuthFlow/localStorage';
import { completeAuth } from './completeAuth';

export type SignInSchema = Pick<AuthSchema, 'email' | 'password'>;

export async function signUpAnonymously() {
  try {
    const [err, credential] = await fromPromiseRetry(signInAnonymously(getAuth()));
    if (err) throw err;
    if (credential) {
      const copyFromToken = await getFirebaseAuthToken();
      if (copyFromToken) localStorage.setItem(keyCopyFromToken, copyFromToken);
      return completeAuth(credential);
    }
  } catch (err) {
    console.error(err);
    onAuthError({ code: (err as AuthError).code, method: 'anonymously' }, false);
    throw err;
  }
}
