import { authenticateUser, updateUserFlags } from '@playful/api';
import { onAuthError, onAuthSuccess } from '@playful/telemetry';
import { AuthError, UserCredential, getAdditionalUserInfo } from 'firebase/auth';

import { parseInitialFlags } from './useUserFlags';

export async function completeAuth(credential: UserCredential, mobileProjectLink?: string) {
  const {
    user: { uid },
  } = credential;
  const additionalUserInfo = getAdditionalUserInfo(credential);
  const isNewUser = additionalUserInfo?.isNewUser || !credential.user.displayName; // when linking accounts from anonymous signup
  const providerId =
    additionalUserInfo?.providerId ?? (credential.user.isAnonymous ? 'anonymous' : 'unknown');

  try {
    await authenticateUser({ userId: uid, mobileProjectLink });
    const flagArr = parseInitialFlags();

    if (isNewUser && flagArr.length) {
      await updateUserFlags(uid, Object.fromEntries(flagArr.map((flag) => [flag, true])));
    }

    onAuthSuccess({ method: providerId }, isNewUser);
    return credential;
  } catch (err) {
    onAuthError({ code: (err as AuthError).code, method: providerId }, isNewUser);
    throw err;
  }
}
