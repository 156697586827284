import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export const informationalUpgradeCTATheme = defineStyleConfig({
    defaultProps: {
        variant: 'yellow',
    },
    baseStyle: {
        box: {
            borderRadius: 'md',
            padding: 3,
            display: 'flex',
            w: '100%',
            position: 'relative',
            zIndex: '1',
            overflow: 'hidden',
            justifyContent: 'space-between',
            textAlign: 'left',
        },
        logoWrap: {
            position: 'absolute',
            zIndex: '-1',
            right: '10px',
            left: '0',
            top: '0',
            bottom: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            overflow: 'hidden',
        },
        logo: {},
    },
    variants: {
        yellow: defineStyle(({ colorScheme }) => {
            return {
                box: {
                    backgroundColor: 'yellow.100',
                    _hover: {
                        outline: 'solid 2px',
                        outlineColor: 'yellow.500',
                    },
                },
                logo: {
                    fill: 'yellow.200',
                },
            };
        }),
    },
    sizes: {},
});
