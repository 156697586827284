export default {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000000',
    white: '#FFFFFF',
    primary: {
        '50': '#F0F4FE', // Not in Figma
        '100': '#DEE7FE',
        '200': '#CBD9FE',
        '300': '#A6BDFD',
        '400': '#82A1FD',
        '500': '#5D84FD',
        '600': '#395BC9',
        '700': '#1D3995',
        '800': '#0B1F61',
        '900': '#010B2D', // Not in Figma
    },
    whiteAlpha: {
        '50': 'rgba(255, 255, 255, 0.04)',
        '100': 'rgba(255, 255, 255, 0.06)',
        '200': 'rgba(255, 255, 255, 0.08)',
        '300': 'rgba(255, 255, 255, 0.16)',
        '400': 'rgba(255, 255, 255, 0.24)',
        '500': 'rgba(255, 255, 255, 0.36)',
        '600': 'rgba(255, 255, 255, 0.48)',
        '700': 'rgba(255, 255, 255, 0.64)',
        '800': 'rgba(255, 255, 255, 0.80)',
        '900': 'rgba(255, 255, 255, 0.92)',
    },
    blackAlpha: {
        '50': 'rgba(0, 0, 0, 0.04)',
        '100': 'rgba(0, 0, 0, 0.06)',
        '200': 'rgba(0, 0, 0, 0.08)',
        '300': 'rgba(0, 0, 0, 0.16)',
        '400': 'rgba(0, 0, 0, 0.24)',
        '500': 'rgba(0, 0, 0, 0.36)',
        '600': 'rgba(0, 0, 0, 0.48)',
        '700': 'rgba(0, 0, 0, 0.64)',
        '800': 'rgba(0, 0, 0, 0.80)',
        '900': 'rgba(0, 0, 0, 0.92)',
    },
    gray: {
        '50': '#F2F2F3',
        '100': '#EBECED',
        '200': '#CBCDD2',
        '300': '#B2B6BD',
        '400': '#9AA2B0',
        '500': '#6F7A90',
        '600': '#4F5869',
        '700': '#394356',
        '800': '#2D3649',
        '900': '#1D2534',
        '950': '#151B27',
    },
    grayRgb: {
        '50': '242, 242, 243',
        '100': '235, 236, 237',
        '200': '203, 205, 210',
        '300': '178, 182, 189',
        '400': '154, 162, 176',
        '500': '111, 122, 144',
        '600': '79, 88, 105',
        '700': '57, 67, 86',
        '800': '45, 54, 73',
        '900': '29, 37, 52',
    },
    red: {
        '50': '#FFF5F5', // Not in Figma
        '100': '#FED7D7', // Not in Figma
        '200': '#FEB2B2', // Not in Figma
        '300': '#FC8181', // Not in Figma
        '400': '#F56565', // Not in Figma
        '500': '#FC5555',
        '600': '#C53030', // Not in Figma
        '700': '#9B2C2C', // Not in Figma
        '800': '#822727', // Not in Figma
        '900': '#63171B', // Not in Figma
    },
    yellow: {
        '50': '#FFFBE6',
        '100': '#FFF2B0',
        '200': '#FFEB8A',
        '300': '#FFE254',
        '400': '#FFDD33',
        '500': '#FFD400',
        '600': '#E8C100',
        '700': '#B59700',
        '800': '#8C7500',
        '900': '#6B5900',
    },
    green: {
        '50': '#F0F9DC',
        '100': '#E9F6CB',
        '200': '#E1F3BA',
        '300': '#D2EC97',
        '400': '#C3E675',
        '500': '#B4E052',
        '600': '#91B542',
        '700': '#6E8A32',
        '800': '#4C5F22',
        '900': '#293412',
    },
    blue: {
        '50': '#EBF6FF',
        '100': '#C2E4FE',
        '200': '#A5D7FE',
        '300': '#7CC5FD',
        '400': '#62BAFD',
        '500': '#3BA9FC',
        '600': '#369AE5',
        '700': '#2A78B3',
        '800': '#205D8B',
        '900': '#19476A',
    },
    purple: {
        '50': '#F1E9FD',
        '100': '#D4B9FA',
        '200': '#C098F8',
        '300': '#A368F4',
        '400': '#914BF2',
        '500': '#751EEF',
        '600': '#6A1BD9',
        '700': '#5315AA',
        '800': '#401183',
        '900': '#310d64',
    },
    pink: {
        '50': '#FEEAF2',
        '100': '#FBBED5',
        '200': '#F99EC1',
        '300': '#F672A5',
        '400': '#F55694',
        '500': '#F22C79',
        '600': '#DC286E',
        '700': '#AC1F56',
        '800': '#851843',
        '900': '#661233',
    },
};
