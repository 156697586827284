import { useRouter as useNextRouter } from 'next/router';
import { useCallback } from 'react';

/**
 * This hook is a workaround for this issue: https://github.com/Playful-Software/play/issues/6744
 * There's an error in our version of next that causes getServerSideProps not to be called after
 * a prior route was visited with a shallow option. This hook is a workaround for that issue until
 * its fixed in next. the same (or similar) issue is tracked in these:
 * - https://github.com/vercel/next.js/issues/67763
 * - https://github.com/vercel/next.js/issues/34365
 * - https://github.com/vercel/next.js/issues/65745
 */
export function useRouter(): ReturnType<typeof useNextRouter> {
  const { push, replace, ...rest } = useNextRouter();

  // clear the shallow option from the history state
  const clearShallow = useCallback(() => {
    const newOptions = window.history.state.options;

    delete newOptions.shallow;

    window.history.replaceState(
      { ...window.history.state, options: newOptions },
      '',
      window.location.href,
    );
  }, []);

  const hackedPush: typeof push = useCallback(
    async (...args) => {
      const ret = await push(...args);

      clearShallow();
      return ret;
    },
    [push, clearShallow],
  );

  const hackedReplace: typeof replace = useCallback(
    async (...args) => {
      const ret = await replace(...args);

      clearShallow();
      return ret;
    },
    [replace, clearShallow],
  );

  return {
    push: hackedPush,
    replace: hackedReplace,
    ...rest,
  };
}
