export const ROUTE_GALLERY = '/gallery';
export const ROUTE_HOME = '/';
export const ROUTE_SIGNIN = '/signin';
export const ROUTE_NEW_PROJECT = '/new-project';
export const ROUTE_REGISTER = '/register';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_WORKBENCH = '/workbench';
export const INTERNAL_ROUTE_DELETE_TEST_USER = '/internal-delete-test-user';
export const ROUTE_FORUM = '/forum';
export const ROUTE_TUTORIALS = '/tutorials';
export const ROUTE_FAQS = '/faqs';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_ABOUT = '/about-us';
export const ROUTE_CAREERS = '/work-here';
export const ROUTE_BLOG = '/blog';
export const ROUTE_SUPPORT = '/support';
export const ROUTE_MY_ACCOUNT = '/account';
export const ROUTE_TEMPLATES = '/templates';
export const ROUTE_PRICING = '/pricing';
export const ROUTE_WHATS_NEW = '/whats-new';
