import { forwardRef } from '@chakra-ui/react';
import { LinkProps, RouteLink } from '@playful/design_system';
import { ROUTE_FORGOT_PASSWORD, ROUTE_REGISTER, ROUTE_SIGNIN } from '../../routes';
import { useRouter } from '../../hooks/useRouter';
import type { LinkProps as NLinkProps } from 'next/link';
import React from 'react';

export const SIGN_IN = 'signIn';
export const SIGN_UP = 'signUp';
export const FORGOT_PW = 'forgotPw';

const authQueries = [SIGN_IN, SIGN_UP, FORGOT_PW] as const;

export type AuthQuery = (typeof authQueries)[number];

export const authRouteLookup: Record<AuthQuery, string> = {
  signIn: ROUTE_SIGNIN,
  signUp: ROUTE_REGISTER,
  forgotPw: ROUTE_FORGOT_PASSWORD,
};

export type AuthLinkProps = Omit<LinkProps, 'href'> & { authType: AuthQuery };

export function useAuthNavigation(type: AuthQuery, options?: NLinkProps) {
  const { query, push } = useRouter();

  return () => push({ query: { ...query, authType: type } }, authRouteLookup[type], options);
}

export const AuthLink = forwardRef(({ authType, ...props }: AuthLinkProps, ref) => {
  // preserve the existing query during auth for things like ?next
  const { query, pathname } = useRouter();

  return (
    <RouteLink
      ref={ref}
      // preserve the pathname as the original page the modal was opened over. on modal close, we'll need
      // to know where we started. for instance, a user going from /gallery -> /signin -> /signup will
      // expect to land back on /gallery if they close the modal.
      href={{ pathname, query: { ...query, authType } }}
      shallow
      asPath={authRouteLookup[authType]}
      {...props}
    />
  );
});
