import {
  Alert,
  AlertDescription,
  AlertTitle,
  Center,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Input,
  Link,
  PasswordInput,
  Stack,
  Text,
} from '@playful/design_system';
import { withT, isMobile } from '@playful/utils';
import { EmailAuthProvider } from 'firebase/auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useTransferSSRState } from '../../hooks/useTransferSSRState';
import type { SignUpSchema } from '../authenticateWithCredential';
import { useUserContext } from '../UserContext';
import { AuthLink } from './AuthLink';
import { FieldError, authSchema, getErrorMsg } from './authSchema';
import { InlineAuthModalLayout } from './InlineAuthModalLayout';
import { Scrim } from './Scrim';
import { SocialButtonStack } from './SocialButtonStack';

export function SignUpDialog({
  asRoute,
  showHatchHeader,
  onCloseAuthDialog,
}: {
  asRoute?: boolean;
  showHatchHeader?: boolean;
  onCloseAuthDialog?: () => void;
}) {
  const {
    authenticateWithCredential,
    isProcessing,
    openAuthDialog,
    closeAuthDialog,
    signInOnOpen,
    signUpOnOpen,
  } = useUserContext();
  const isSignUpOpen = openAuthDialog?.authType === 'signUp';
  const [emailSelected, setEmailSelected] = useState(true);
  const [submissionErr, setSubmissionErr] = useState<FieldError<SignUpSchema> | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<SignUpSchema>({
    resolver: yupResolver(authSchema),
  });

  function handleCloseAuthDialog() {
    onCloseAuthDialog?.();
    closeAuthDialog();
  }

  async function onSubmit(values: SignUpSchema) {
    const { email, password } = values;
    setSubmissionErr(null);
    const credential = EmailAuthProvider.credential(email, password);
    const redirect = isMobile();
    const [err] = await authenticateWithCredential({
      credential,
      email,
      password,
      isNewUser: true,
      redirect,
      mobileProjectLink: openAuthDialog?.mobileProjectLink,
    });
    const formattedErr = getErrorMsg<SignUpSchema>(err);

    if (formattedErr) {
      const { field, message } = formattedErr;

      if (!field) return setSubmissionErr(formattedErr);
      setError(field, { type: field, message });
    }
  }
  const header =
    !asRoute && !showHatchHeader ? (
      <>Create a free account to save and share your site</>
    ) : undefined;

  const isOpen = useTransferSSRState({
    SSRVal: !!asRoute,
    clientVal: isSignUpOpen,
    useClientVal: isSignUpOpen,
    // don't auto-open if we're not coming from a route
    onBrowser: asRoute ? signUpOnOpen : undefined,
  });

  return (
    <InlineAuthModalLayout
      onSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      onClose={handleCloseAuthDialog}
      header={header}
      closeOnOverlayClick={openAuthDialog?.isDismissable}
      closeOnEsc={openAuthDialog?.isDismissable}
    >
      {isProcessing && <Scrim />}
      <Stack mb={5} data-cy='sign-up'>
        <SocialButtonStack isNewUser />
        {emailSelected ? (
          <Stack>
            <Box position='relative'>
              <Divider my={2} />
              <Center position='absolute' w='100%' top='-2px'>
                <Text
                  color='gray.500'
                  fontSize='xs'
                  textAlign='center'
                  backgroundColor='white'
                  px='8px'
                >
                  or sign up with email
                </Text>
              </Center>
            </Box>
            <FormControl isInvalid={!!errors.email}>
              <Input
                {...register('email')}
                autoCorrect='off'
                autoCapitalize='none'
                type='email'
                autoComplete='email'
                placeholder='Email'
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <PasswordInput
                {...register('password')}
                autoComplete='new-password'
                placeholder='Password'
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
              colorScheme='yellow'
              w='full'
              onClick={withT(setEmailSelected)}
            >
              Create Account
            </Button>
            {submissionErr?.message && (
              <Alert status='error'>
                <AlertTitle>Oops!</AlertTitle>
                <AlertDescription>{submissionErr?.message}</AlertDescription>
              </Alert>
            )}
          </Stack>
        ) : (
          <Button colorScheme='yellow' w='full' onClick={withT(setEmailSelected)}>
            Sign up with email
          </Button>
        )}
        <Text fontSize='xs'>
          By signing up, you agree to our{' '}
          <Link
            target='_blank'
            rel='noopener noreferrer'
            textDecor='underline'
            href='https://hatch.one/privacy-policy'
          >
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            target='_blank'
            rel='noopener noreferrer'
            textDecor='underline'
            href='https://hatch.one/terms-and-conditions'
          >
            Terms of use
          </Link>
        </Text>
      </Stack>
      <Divider my={5} />
      <Text>
        Already have an account? {/* When opened as a dialog, toggle state via signUpOnOpen */}
        {asRoute ? (
          <AuthLink authType='signIn' fontWeight='bold' textDecor='underline'>
            Log In
          </AuthLink>
        ) : (
          <Link fontWeight='bold' as='span' onClick={() => signInOnOpen(openAuthDialog)}>
            Log In
          </Link>
        )}
      </Text>
    </InlineAuthModalLayout>
  );
}

export default SignUpDialog;
