import { Center } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@playful/design_system';
import React from 'react';

import HatchSvg from '../../assets/hatch.svg';

type AuthModalLayoutProps = React.PropsWithChildren<{
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onSubmit?: React.FormEventHandler<HTMLDivElement>;
}>;

export function InlineAuthModalLayout({
  header: Header,
  footer: Footer,
  onSubmit,
  children,
  onClose,
  isOpen,
  ...props
}: AuthModalLayoutProps & ModalProps) {
  return (
    <Modal
      size='sm'
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      {...props}
    >
      <ModalOverlay />
      <ModalContent textAlign='center'>
        {!Header && (
          <Center w='100%' bg='black' h='138px' borderTopRadius='md'>
            <HatchSvg />
          </Center>
        )}
        {Header && (
          <ModalHeader px={12} pt={10}>
            {Header}
          </ModalHeader>
        )}
        <ModalBody
          as='form'
          px={12}
          pb={12}
          pt={5}
          noValidate
          onSubmit={onSubmit}
          data-cy='auth-form'
        >
          {children}
        </ModalBody>
        {Footer && <ModalFooter>{Footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}
