import { UIEventHandler, useEffect, useState } from 'react';

export const useIsKeyboardOpen = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const onResize = () => {
      const visualViewportHeight = window?.visualViewport?.height || 0;
      if (visualViewportHeight < document.documentElement.clientHeight) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    window?.visualViewport?.addEventListener('resize', onResize);
    return () => {
      window?.visualViewport?.removeEventListener('resize', onResize);
    };
  }, []);

  return open;
};

export const useMobileScrollHacks = () => {
  const keyboardOpen = useIsKeyboardOpen();

  useEffect(() => {
    if (!keyboardOpen) {
      window?.scroll?.(0, 0);
      setTimeout(() => {
        if (document.documentElement.scrollTop === 0) {
          window?.scroll?.(0, 0);
        }
      }, 100);
    } else if (document.activeElement) {
      /**
       * If the keyboard opens but Safari doesn't automatically scroll it into view,
       * manually scroll it into view.
       *
       * When the keyboard opens Safari sends a resize event where the visualViewport height
       * is the height of the viewport minus the keyboard.
       * If this height is equal to the client height we can deduce that Safari did not account
       * for the keyboard.
       * When this happens Safari will also send a followup event with the correct height but
       * without actually performing the scroll we desire.
       */
      const targetRect = document.activeElement.getBoundingClientRect();
      const halfWay = (window?.visualViewport?.height || 1) * 0.95;
      if (targetRect.y > halfWay && document.documentElement.scrollTop === 0) {
        window?.scroll?.(0, halfWay);
      }
    }
  }, [keyboardOpen]);

  const ignoreScroll: UIEventHandler = (event) => {
    event.preventDefault();
    const el = event.target as HTMLDivElement;
    if (el) {
      el.scrollTop = 0;
    }
  };

  return {
    ignoreScroll,
  };
};
