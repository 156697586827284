import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  Center,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuth } from '@playful/api';
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Link,
  PasswordInput,
  Stack,
  Text,
} from '@playful/design_system';
import { semanticJoin } from '@playful/frontend/utils/semanticJoin';
import { fromPromise, isMobile } from '@playful/utils';
import { EmailAuthProvider, fetchSignInMethodsForEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useTransferSSRState } from '../../hooks/useTransferSSRState';
import type { SignInSchema } from '../authenticateWithCredential';
import { useUserContext } from '../UserContext';
import { AuthLink } from './AuthLink';
import { FieldError, authSchema, getErrorMsg } from './authSchema';
import { InlineAuthModalLayout } from './InlineAuthModalLayout';
import { Scrim } from './Scrim';
import { SocialButtonStack, getProviderLabelById } from './SocialButtonStack';

type SubmissionErr = FieldError<SignInSchema> & AlertProps;

export function SignInDialog({
  asRoute,
  showHatchHeader,
  onCloseAuthDialog,
}: {
  asRoute?: boolean;
  showHatchHeader?: boolean;
  onCloseAuthDialog?: () => void;
}) {
  const [submissionErr, setSubmissionErr] = useState<SubmissionErr | null>(null);
  const {
    isProcessing,
    authenticateWithCredential,
    openAuthDialog,
    closeAuthDialog,
    signUpOnOpen,
    forgotPwOnOpen,
    signInOnOpen,
  } = useUserContext();

  const isSignInOpen = openAuthDialog?.authType === 'signIn';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<SignInSchema>({
    resolver: yupResolver(authSchema),
  });

  function handleCloseAuthDialog() {
    onCloseAuthDialog?.();
    closeAuthDialog();
  }

  async function onSubmit(values: SignInSchema) {
    const { email, password } = values;
    setSubmissionErr(null);
    const redirect = isMobile();
    const credential = EmailAuthProvider.credential(email, password);
    const [err] = await authenticateWithCredential({
      credential,
      email,
      password,
      isNewUser: false,
      redirect,
    });

    const formattedErr = getErrorMsg<SignInSchema>(err);

    // A better experience for those who have registered with an email and pw, then socially
    // authenticated, and are now attempting to sign back in with their (unverified) email and pw:
    // https://github.com/firebase/firebaseui-web/issues/122#issuecomment-291528845
    if (formattedErr) {
      if (err.code === 'auth/wrong-password') {
        const auth = getAuth();
        const [, methods] = await fromPromise(fetchSignInMethodsForEmail(auth, values.email));
        const unlinkedPassword = methods?.length && !methods.includes('password');
        const providerList = methods?.map(getProviderLabelById);

        if (unlinkedPassword && providerList?.length) {
          setSubmissionErr({
            ...formattedErr,
            title: 'Multiple Sign-In Methods',
            status: 'warning',
            message: `Please log in with ${semanticJoin(
              providerList,
              'or',
            )}, or select "Forgot password" and verify your email.`,
          });

          return;
        }
      }

      const { field, message } = formattedErr;

      if (!field) return setSubmissionErr(formattedErr);
      setError(field, { type: field, message });
    }
  }
  const header = !asRoute && !showHatchHeader ? <>Log in to save and share</> : undefined;
  const isOpen = useTransferSSRState({
    SSRVal: !!asRoute,
    clientVal: isSignInOpen,
    useClientVal: isSignInOpen,
    // don't auto-open if we're not coming from a route
    onBrowser: asRoute ? signInOnOpen : undefined,
  });

  return (
    <InlineAuthModalLayout
      onSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      onClose={handleCloseAuthDialog}
      header={header}
      closeOnOverlayClick={openAuthDialog?.isDismissable}
      closeOnEsc={openAuthDialog?.isDismissable}
    >
      {isProcessing && <Scrim />}
      <Stack mb={5} data-cy='sign-in'>
        <SocialButtonStack isNewUser={false} />
        <Stack>
          <Box position='relative'>
            <Divider my={2} />
            <Center position='absolute' w='100%' top='-2px'>
              <Text
                color='gray.500'
                fontSize='xs'
                textAlign='center'
                backgroundColor='white'
                px='8px'
              >
                or log in with email
              </Text>
            </Center>
          </Box>
          <FormControl isInvalid={!!errors.email}>
            <Input
              {...register('email')}
              aria-label='email'
              data-cy='email'
              autoCorrect='off'
              autoCapitalize='none'
              type='email'
              autoComplete='email'
              placeholder='Email'
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <PasswordInput
              {...register('password')}
              aria-label='password'
              data-cy='password'
              autoComplete='current-password'
              placeholder='Password'
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
          {submissionErr?.message && (
            <Alert
              status={submissionErr.status ?? 'error'}
              variant='left-accent'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
            >
              {submissionErr.title && (
                <AlertTitle mb={2} lineHeight={5} display='flex' alignItems='center'>
                  {submissionErr.title}
                </AlertTitle>
              )}
              <AlertDescription lineHeight={5}>{submissionErr?.message}</AlertDescription>
            </Alert>
          )}
          <Button
            type='submit'
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            colorScheme='yellow'
            w='full'
          >
            Log In
          </Button>
        </Stack>
      </Stack>
      <HStack justifyContent='space-between'>
        {asRoute ? (
          <>
            <AuthLink authType='signUp' textDecor='underline'>
              Create account
            </AuthLink>
            <AuthLink authType='forgotPw' textDecor='underline'>
              Forgot password
            </AuthLink>
          </>
        ) : (
          <>
            <Link as='span' onClick={() => signUpOnOpen(openAuthDialog)} textDecor='underline'>
              Create account
            </Link>
            <Link as='span' onClick={() => forgotPwOnOpen(openAuthDialog)} textDecor='underline'>
              Forgot password
            </Link>
          </>
        )}
      </HStack>
    </InlineAuthModalLayout>
  );
}

export default SignInDialog;
