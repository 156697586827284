import type { ProjectInfo } from '@playful/runtime';
import { joinPath } from '@playful/utils';

export function buildProjectIdEditRoute(id: string) {
  return joinPath(['/edit', id]);
}

export function buildProjectEditRoute(info: ProjectInfo) {
  if (info.slug && info.published) {
    return buildUserRoute(info.ownerName, info.slug, 'edit');
  }
  return buildProjectIdEditRoute(info.id);
}

export function buildUserRoute(
  userName: string,
  slug?: string,
  action: 'edit' | '' = '',
  base = '',
) {
  const validOnly = [`@${userName}`, slug, action].filter(Boolean) as string[];
  return joinPath([`/${base}`, ...validOnly]);
}

export function buildAnonUserRoute(slug?: string, action: 'edit' | '' = '', base = '') {
  const validOnly = [`hatchling`, slug, action].filter(Boolean) as string[];

  return joinPath([`/${base}`, ...validOnly]);
}
