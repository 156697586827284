import { ModalCloseButton, Spinner } from '@chakra-ui/react';
import type { PriceInfoObject } from '@playful/api';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useIsSmallScreen,
} from '@playful/design_system';
import { ForgotPasswordDialog } from '@playful/frontend/user/AuthFlow/ForgotPasswordDialog';
import { SignInDialog } from '@playful/frontend/user/AuthFlow/SignInDialog';
import { SignUpDialog } from '@playful/frontend/user/AuthFlow/SignUpDialog';
import { useUserContext } from '@playful/frontend/user/UserContext';
import { customEvent } from '@playful/telemetry';
import noop from 'lodash/noop';
import React, { useCallback, useEffect, useState } from 'react';

import { redirectToCheckout } from '../user/stripeRedirects';
import { PricingChoosePlan } from './PricingChoosePlan';

const AuthModal = ({ onClose }: { onClose: () => void }) => {
  const { signInOnOpen } = useUserContext();

  function handleForgotPWAfterSubmit() {
    signInOnOpen();
  }

  return (
    <>
      <SignUpDialog onCloseAuthDialog={onClose} showHatchHeader />
      <SignInDialog onCloseAuthDialog={onClose} showHatchHeader />
      <ForgotPasswordDialog onCloseAuthDialog={onClose} afterSubmit={handleForgotPWAfterSubmit} />
    </>
  );
};

export type SubscriptionFlowProps = {
  isOpen: boolean;
  isPage?: boolean;
  onClose: () => void;
  onFreePlanClicked: () => void;
  returnPath?: string;
  priceInfo?: PriceInfoObject;
  returnTo?: string[];
  successModal?: string;
};

export const SubscriptionFlow = ({
  isOpen,
  onClose,
  returnPath = '/',
  returnTo,
  onFreePlanClicked,
  isPage = false,
  priceInfo,
  successModal,
}: SubscriptionFlowProps) => {
  const { signInOnOpen, isLoggedInUser, hasActiveSubscription } = useUserContext();
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const isSmallScreen = useIsSmallScreen(true);

  const goToCheckout = useCallback(async () => {
    const origin = window.location.origin;
    const returnUrl = `${origin}${returnPath}`;
    if (selectedProduct) {
      customEvent('subscription-select', { selectedProduct });
      redirectToCheckout(selectedProduct, returnUrl, returnTo, successModal);
    }
  }, [selectedProduct, returnPath, returnTo, successModal]);

  function handleSelectProduct(priceID: string) {
    setSelectedProduct(priceID);
    if (!isLoggedInUser) {
      signInOnOpen();
    }
  }

  function handleFreeClick() {
    if (isLoggedInUser) {
      onFreePlanClicked();
    } else {
      signInOnOpen();
      onClose();
    }
  }

  useEffect(() => {
    if (isLoggedInUser && selectedProduct) {
      if (hasActiveSubscription) {
        return onClose();
      }
      goToCheckout();
    }
  }, [isLoggedInUser, selectedProduct, goToCheckout, hasActiveSubscription, onClose]);

  useEffect(() => {
    if (isPage) {
      customEvent('subscription-show', { reason: 'pricing-page' });
    }
  }, [isPage]);

  if (!isLoggedInUser && isOpen && selectedProduct) {
    return (
      <AuthModal
        onClose={() => {
          setSelectedProduct(null);
          onClose();
        }}
      />
    );
  }

  function handleClose() {
    customEvent('subscription-modal-close', { type: 'close-button-clicked' });
    onClose();
  }

  function handleFreePlanClicked() {
    customEvent('subscription-modal-close', { type: 'free-plan-clicked' });
    onFreePlanClicked();
  }

  // Loading screen?
  if (selectedProduct && isLoggedInUser && !hasActiveSubscription)
    return (
      <Modal size='lg' isOpen isCentered onClose={noop}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box h='300px' display='flex' alignItems='center' justifyContent='center'>
              <Spinner />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

  if (isPage) {
    return (
      <Box
        display='inline-block'
        bgColor='white'
        pt={5}
        px={{ base: 0, lg: 16 }}
        pb={{ base: 5, lg: 16 }}
        borderRadius={{ base: 'none', lg: 'lg' }}
        width='100%'
        maxWidth={{ base: '100%', lg: '856px' }}
      >
        <PricingChoosePlan
          handleClickFreePlan={handleFreePlanClicked}
          handleSelectProduct={handleSelectProduct}
          priceInfo={priceInfo}
        />
      </Box>
    );
  }

  return (
    <Modal
      size={isSmallScreen ? 'full-no-padding' : '2xl'}
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior={'inside'}
      blockScrollOnMount={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <PricingChoosePlan
            handleSelectProduct={handleSelectProduct}
            handleClickFreePlan={handleFreeClick}
            priceInfo={priceInfo}
          />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
