import {
  Alert,
  AlertDescription,
  AlertTitle,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuth } from '@playful/api';
import { Button, HStack, Heading, Input, Link, Stack } from '@playful/design_system';
import { useLoader } from '@playful/frontend/workbench/useLoader';
import { fromPromise } from '@playful/utils';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useTransferSSRState } from '../../hooks/useTransferSSRState';
import { useUserContext } from '../UserContext';
import { AuthLink, useAuthNavigation } from './AuthLink';
import { AuthSchema, FieldError, getErrorMsg } from './authSchema';
import { InlineAuthModalLayout } from './InlineAuthModalLayout';

type ForgotPasswordSchema = Pick<AuthSchema, 'email'>;

const schema = yup
  .object({
    email: yup.string().email('Please enter a valid email').required('Email is required'),
  })
  .required();

export function ForgotPasswordDialog({
  asRoute,
  onCloseAuthDialog,
  afterSubmit,
}: {
  asRoute?: boolean;
  onCloseAuthDialog?: () => void;
  afterSubmit?: () => void;
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(schema),
  });
  const { openAuthDialog, closeAuthDialog, signUpOnOpen, signInOnOpen, forgotPwOnOpen } =
    useUserContext();

  const isForgotPwOpen = openAuthDialog?.authType === 'forgotPw';
  const [submissionErr, setSubmissionErr] = useState<FieldError<ForgotPasswordSchema> | null>(null);
  const navigateToSignIn = useAuthNavigation('signIn');
  const { isLoading, makeRequest: onSubmit } = useLoader(async (values: any) => {
    const [resetErr] = await fromPromise(sendPasswordResetEmail(getAuth(), values.email));
    const err = getErrorMsg<ForgotPasswordSchema>(resetErr);

    if (err) {
      const { field, message } = err;

      if (!field) return setSubmissionErr(err);
      return setError(field, { type: field, message });
    }
    if (afterSubmit) {
      afterSubmit();
    } else {
      navigateToSignIn();
    }
  });

  function handleCloseAuthDialog() {
    onCloseAuthDialog?.();
    closeAuthDialog();
  }

  const isOpen = useTransferSSRState({
    SSRVal: !!asRoute,
    clientVal: isForgotPwOpen,
    useClientVal: isForgotPwOpen,
    // don't auto-open if we're not coming from a route
    onBrowser: asRoute ? forgotPwOnOpen : undefined,
  });

  return (
    <InlineAuthModalLayout
      isOpen={isOpen}
      onClose={handleCloseAuthDialog}
      onSubmit={handleSubmit(onSubmit)}
      closeOnOverlayClick={openAuthDialog?.isDismissable}
      closeOnEsc={openAuthDialog?.isDismissable}
    >
      <Stack mb={5}>
        <Heading as='h1' fontSize='xl' mt={5}>
          Enter your email and we&apos;ll help you reset your password in&nbsp;a&nbsp;jiffy
        </Heading>
        <FormControl isInvalid={!!errors.email}>
          <Input {...register('email')} autoComplete='email' placeholder='Email' />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        {submissionErr?.message && (
          <Alert status='error'>
            <AlertTitle>Oops!</AlertTitle>
            <AlertDescription>{submissionErr?.message}</AlertDescription>
          </Alert>
        )}
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          type='submit'
          colorScheme='yellow'
          w='full'
        >
          Reset Password
        </Button>
      </Stack>
      <HStack justifyContent='space-between'>
        {asRoute ? (
          <>
            <AuthLink authType='signUp'>Create account</AuthLink>
            <AuthLink authType='signIn'>Log in</AuthLink>
          </>
        ) : (
          <>
            <Link as='span' onClick={() => signUpOnOpen(openAuthDialog)} textDecor='underline'>
              Create account
            </Link>
            <Link as='span' onClick={() => signInOnOpen(openAuthDialog)} textDecor='underline'>
              Log in
            </Link>
          </>
        )}
      </HStack>
    </InlineAuthModalLayout>
  );
}

export default ForgotPasswordDialog;
