import { useRouter } from '@playful/frontend/hooks/useRouter';
import { createGenericContext } from '@playful/utils';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

export type RouteCtx = {
  prevPath: string;
};

const [useRoute, RouteProvider] = createGenericContext<RouteCtx>();

export const useRouteCtx = useRoute;

export function RouteContext({ children }: { children: ReactNode }) {
  const [prevPath, setPrevPath] = useState<string>('');
  const currPath = useRef('');
  const { asPath } = useRouter();

  useEffect(() => {
    setPrevPath(currPath.current);
    currPath.current = asPath;
  }, [asPath]);

  return <RouteProvider value={{ prevPath }}>{children}</RouteProvider>;
}
