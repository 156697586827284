import { isMobile, isSafari } from '@playful/utils';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { EmailAuthProvider, browserLocalPersistence, browserPopupRedirectResolver, getIdToken, inMemoryPersistence, indexedDBLocalPersistence, initializeAuth, reauthenticateWithCredential, signInWithCustomToken, updatePassword, } from 'firebase/auth';
export function initFirebase(cfg) {
    // Firebase app config
    const config = {
        apiKey: cfg.firebaseApiKey,
        authDomain: cfg.firebaseAuthDomain,
        databaseURL: cfg.firebaseDatabaseUrl,
        projectId: cfg.googleProjectId,
        appId: cfg.firebaseAppId,
    };
    initializeApp(config);
}
export function isFirebaseInitialized() {
    return getApps().length > 0;
}
export async function getFirebaseAuthToken() {
    const auth = getAuth();
    if (auth.currentUser === null) {
        return undefined;
    }
    return await getIdToken(auth.currentUser);
}
let auth;
// Define some auth 'presets'
// https://firebase.google.com/docs/auth/web/custom-dependencies
// Normal auth
export function getAuth() {
    let popupRedirectResolver;
    // safari *desktop* needs popupRedirectResolver pre-loaded so that popups can work
    if (isSafari() && !isMobile()) {
        popupRedirectResolver = browserPopupRedirectResolver;
    }
    if (!auth) {
        auth = initializeAuth(getApp(), {
            persistence: [indexedDBLocalPersistence, browserLocalPersistence], //default
            popupRedirectResolver,
        });
    }
    return auth;
}
// Auth without the need to sign in
export function getSimpleAuth() {
    if (!auth) {
        auth = initializeAuth(getApp(), {
            persistence: [indexedDBLocalPersistence, browserLocalPersistence], //default
            // No popupRedirectResolver defined
        });
    }
    return auth;
}
// Auth when we use a custom token, uses inMemoryPersistence which basically means 'none'
export function getTemporaryAuth() {
    if (!auth) {
        auth = initializeAuth(getApp(), {
            persistence: [inMemoryPersistence],
            // No popupRedirectResolver defined
        });
    }
    return auth;
}
// Used when signing in a with custom token
export async function signInWithToken(token) {
    return new Promise((resolve) => {
        if (token && token.length > 0) {
            signInWithCustomToken(getTemporaryAuth(), token)
                .then(async (userCredential) => {
                resolve(userCredential.user);
            })
                .catch((e) => {
                console.warn(`Error with custom token: `, e.message);
                resolve(null);
            });
        }
    });
}
export async function reauthenticateUser(email, password) {
    const fbUser = getAuth().currentUser;
    if (!fbUser ||
        !fbUser.email ||
        fbUser.email !== email ||
        !fbUser.providerData.find(({ providerId }) => providerId === 'password'))
        throw new Error('user not authenticated or not using password auth');
    const credential = EmailAuthProvider.credential(fbUser.email, password);
    const authUser = await reauthenticateWithCredential(fbUser, credential);
    return authUser.user;
}
export async function updateUserPassword(email, password, newPassword) {
    const user = await reauthenticateUser(email, password);
    await updatePassword(user, newPassword);
}
