import type { User } from '@playful/api';

// the bare required properties for our default users
export const anonymousUser: User = {
  id: '',
  name: '',
  email: '',
  userType: 'user',
  uploadsResourceId: '',
  isAnonymous: true, // isAnonymous is true for Firebase Anonymous Users
};

export const signedOutUser: User = {
  id: '',
  name: '',
  email: '',
  userType: 'signedout',
  uploadsResourceId: '',
  isAnonymous: false, // isAnonymous is true for Firebase Anonymous Users
};
