import {
  User,
  UserFlags,
  copyFromUser,
  getAuth,
  getUser,
  getUserFlags,
  isFirebaseInitialized as isFbInit,
  reauthenticateUser,
  updateUser,
  updateUserPassword,
  userFlagDefaults,
} from '@playful/api';
import { customEvent, identify, shareSession } from '@playful/telemetry';
import { Status, fromPromise } from '@playful/utils';
import { deleteCookie, setCookie } from 'cookies-next';
import {
  AuthError,
  User as FBUser,
  GoogleAuthProvider,
  signOut as fbSignOut,
  getIdTokenResult,
  onAuthStateChanged,
  onIdTokenChanged,
} from 'firebase/auth';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PREV_SIGNED_IN } from '../cookies';
import { useLocalStorageState } from '../hooks/handyHooks';
import { useRouter } from '../hooks/useRouter';
import { buildProjectIdEditRoute } from '../paths';
import { ROUTE_HOME } from '../routes';
import { useLoader } from '../workbench/useLoader';
import type { EmailUpdateErrorResponse } from './Account/EmailModalForm';
import { anonymousUser, signedOutUser } from './anonymousUser';
import {
  AuthPayload,
  authenticateWithCredential as _authenticateWithCredential,
} from './authenticateWithCredential';
import type { AuthQuery } from './AuthFlow/AuthLink';
import { keyCopyFromToken } from './AuthFlow/localStorage';
import { checkRedirectResult } from './checkRedirectResult';
import { initialUser } from './initialUser';
import { signUpAnonymously as _signUpAnonymously } from './signUpAnonymously';
import {
  parseInitialFlags,
  persistInitialFlags,
  resetInitialFlags,
  useFlags,
} from './useUserFlags';

declare global {
  interface Window {
    googleIdToken: string | undefined;
  }
}

export enum LoginStatus {
  None = 'none',
  User = 'user',
  Anonymously = 'anonymously',
}
const getLoginStatus = (user: User): LoginStatus => {
  if (user?.isAnonymous) {
    return LoginStatus.Anonymously;
  } else if (user?.id) {
    return LoginStatus.User;
  } else {
    return LoginStatus.None;
  }
};

export type UserCtx = {
  user: User;
  isProcessing: boolean;
  userFlags: UserFlags;
  setUserFlags: Dispatch<SetStateAction<UserFlags>>;
  hasFlag: (flagName: keyof UserFlags) => boolean;
  toggleUserFlag: (flagName: keyof UserFlags) => void;
  updateUserFlags: (flags: Partial<UserFlags>) => void;
  authenticateWithCredential: (ap: AuthPayload) => Promise<Status<void>>;
  signUpAnonymously: () => Promise<Status<void>>;
  loginStatus: LoginStatus;
  isLoggedIn: boolean;
  isLoggedInUser: boolean;
  isLoggedInAnonymously: boolean;
  hasActiveSubscription: boolean;
  hoverVoucherState: string;
  signOut: () => void;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  previousUserEmail: string | null;
  authToken?: string;
  // Auth Modals:
  openAuthDialog: (AuthOpts & { authType: AuthQuery }) | undefined;
  signUpOnOpen: (opts?: AuthOpts) => void;
  signInOnOpen: (opts?: AuthOpts) => void;
  forgotPwOnOpen: (opts?: AuthOpts) => void;
  closeAuthDialog: () => void;
  isInitializing: boolean;
  getFbUser: () => FBUser | null;
  updateEmail: (email: string, password: string) => Promise<EmailUpdateErrorResponse | void>;
  updatePassword: (password: string, newPassword: string) => Promise<AuthError | void>;
  refreshUser: () => Promise<User | undefined>;
};

// this is a hack for now.
// later, we'll need to go through and just add assertions and null checks for these across the codebase.
// this is safe, for now, as we don't render the app at all if `user` isn't true, but that may not
// always be the case.
export type UseUserCtx = Omit<UserCtx, 'user'> & {
  user: User;
};

export const UserContext = createContext<UserCtx>(undefined as any);

export const useUserContext = () => useContext(UserContext);

export type UserProviderProps = {
  children: ReactNode;
};

type AuthOpts = {
  isDismissable?: boolean;
  mobileProjectLink?: string;
};

// expose pre-filled provider
// the `initialized` prop is a total hack for next atm. we could have smushed the config stuff in here
// but this bad boy is already pretty big. once we move to the app directory style we can get rid of this.
export function UserProvider({ children }: UserProviderProps) {
  // TODO: hold the active user id, not the user itself. swr should provide that.
  const [user, setUser] = useState<User>(initialUser);
  const [previousUserEmail, setPreviousUserEmail] = useLocalStorageState<string | null>(
    null,
    'previousUserEmail',
  );
  const [authToken, setAuthToken] = useState<string>();
  const { userFlags, updateUserFlags, setUserFlags, hasFlag, toggleUserFlag } = useFlags({ user });
  const [openAuthDialog, setOpenAuthDialog] = useState<AuthOpts & { authType: AuthQuery }>();
  const { push, query } = useRouter();
  const loginStatus = getLoginStatus(user);
  const isLoggedInUser = loginStatus == LoginStatus.User;
  const isLoggedIn = loginStatus == LoginStatus.User || loginStatus == LoginStatus.Anonymously;
  const isLoggedInAnonymously = loginStatus == LoginStatus.Anonymously;
  const justSubscribedRef = useRef<any>();
  const [justSubscribed, setJustSubscribed] = useState(false);

  const hoverVoucherState = user.hoverVoucherState || '';

  // optimistically update the user's subscription status if they just got back from subscribing
  // past_due is included because user can still access the app until the grace period ends,
  // after which the subscription will be canceled
  const hasActiveSubscription =
    user.subscriptionStatus === 'active' ||
    user.subscriptionStatus === 'past_due' ||
    justSubscribed;

  useEffect(() => {
    if (query.subscribeSuccess === 'true') {
      customEvent('subscription-success');
      setJustSubscribed(true);
    }
  }, [query.subscribeSuccess]);

  const fetchUser = useCallback(async () => {
    const [err, updatedUser] = await fromPromise(getUser(user.id));
    if (err) {
      throw err;
    }
    return updatedUser;
  }, [user]);

  const refreshUser = useCallback(async () => {
    const updatedUser = await fetchUser();
    if (updatedUser) {
      setUser(updatedUser);
    }
    return updatedUser;
  }, [fetchUser]);

  useEffect(() => {
    // this code optimistically updates the user's subscription status if they just got back from subscribing
    // then 15 seconds later it pulls a fresh user from the server to get the real status
    if (!justSubscribed) return;
    if (!user.id) return;
    if (user.subscriptionStatus === 'active') return;

    if (!justSubscribedRef.current) {
      justSubscribedRef.current = setTimeout(() => {
        setJustSubscribed(false);
        fetchUser()
          .then((updatedUser) => {
            setUser((currentUser) => {
              if (
                !updatedUser ||
                currentUser.subscriptionStatus === updatedUser.subscriptionStatus
              ) {
                return currentUser;
              }
              return {
                ...currentUser,
                subscriptionStatus: updatedUser.subscriptionStatus,
                subscriptionId: updatedUser.subscriptionId,
                stripeCustomerId: updatedUser.stripeCustomerId,
              };
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }, 10 * 1000);
    }
  }, [justSubscribed, fetchUser, user.id, user.subscriptionStatus]);

  const isFirebaseInitialized = isFbInit();

  const closeAuthDialog = useCallback(() => {
    setOpenAuthDialog(undefined);
  }, [setOpenAuthDialog]);

  const signUpOnOpen = useCallback(
    (opts?: AuthOpts) => {
      closeAuthDialog();
      setOpenAuthDialog({
        ...opts,
        authType: 'signUp',
      });
    },
    [setOpenAuthDialog, closeAuthDialog],
  );

  const signInOnOpen = useCallback(
    (opts?: AuthOpts) => {
      closeAuthDialog();
      setOpenAuthDialog({
        ...opts,
        authType: 'signIn',
      });
    },
    [setOpenAuthDialog, closeAuthDialog],
  );

  const forgotPwOnOpen = useCallback(
    (opts?: AuthOpts) => {
      closeAuthDialog();
      setOpenAuthDialog({ ...opts, authType: 'forgotPw' });
    },
    [setOpenAuthDialog, closeAuthDialog],
  );

  // if not logged in, load the initial flags from the url (or fallback to localStorage) asap, as we want
  // to persist this before we potentially lose the state
  useEffect(() => {
    if (isLoggedInUser) return;

    const flags = parseInitialFlags();
    if (flags.length) persistInitialFlags(flags);
  }, [isLoggedInUser]);

  const signOut = useCallback<UserCtx['signOut']>(async () => {
    isFirebaseInitialized && fbSignOut(getAuth());
    setUser(anonymousUser); // Do this here vs waiting for onAuthStateChanged so that subsquent navigation sees a signed out state
    setUserFlags(userFlagDefaults);
    deleteCookie(PREV_SIGNED_IN);
    push(ROUTE_HOME);
  }, [setUserFlags, push, isFirebaseInitialized]);

  const memoValues = useMemo(
    () => ({
      user,
      userFlags,
    }),
    [user, userFlags],
  );

  const { isLoading: isAuthPending, makeRequest: handleAuth } = useLoader(
    useCallback(
      async (p) => {
        const [err] = await fromPromise(p);

        if (!isFirebaseInitialized) return p;

        // here's where we can clean up the user if there's an error during authentication.
        if (err && getAuth().currentUser && !getAuth().currentUser?.isAnonymous) {
          await signOut();
        }
        if (!err) {
          await closeAuthDialog();
        }
        return p;
      },
      [signOut, closeAuthDialog, isFirebaseInitialized],
    ),
  );

  const authenticateWithCredential = useCallback<UserCtx['authenticateWithCredential']>(
    (payload) => {
      return handleAuth(_authenticateWithCredential(payload));
    },
    [handleAuth],
  );

  // Scenario ux_mode:'redirect'
  // After redirect from google login, check for a token, and complete authtentication
  const { isLoading: isCheckingGoogleIdToken, makeRequest: checkForGoogleIdToken } = useLoader(
    useCallback(async () => {
      if (window?.googleIdToken && window?.googleIdToken?.length > 1) {
        const credential = GoogleAuthProvider.credential(window.googleIdToken);
        const isNewUser = false; // this will gracefully fallback to signIn if the user-exists
        window.googleIdToken = '';
        await authenticateWithCredential({ credential, isNewUser });
      }
    }, [authenticateWithCredential]),
  );

  const [isAuthSettled, setAuthSettled] = useState(false);
  const _processAuth = useCallback(
    async (firebaseUser: FBUser | null) => {
      if (isAuthPending) return;

      if (!firebaseUser) {
        setUser(signedOutUser);
        deleteCookie(PREV_SIGNED_IN);
        identify();
        checkForGoogleIdToken();
        setAuthSettled(true);
        return;
      }

      let user = initialUser;
      try {
        user = await getUser(firebaseUser.uid);
      } catch (e) {
        // ignore the error if there is one...on initial registration, the user might not
        // exist yet
      }
      const updatedUser: User = {
        ...user,
        id: firebaseUser.uid,
        email: firebaseUser.email!,
        isAnonymous: firebaseUser.isAnonymous,
      };

      const serverFlags = await getUserFlags(updatedUser.id);

      // Merge in default flags
      const flags = { ...userFlagDefaults, ...serverFlags };

      setUserFlags(flags);
      resetInitialFlags();
      setUser(updatedUser);

      identify(updatedUser.id, {
        username: updatedUser.name,
        email: updatedUser.email || '',
        visitor_type: updatedUser.userType || 'user',
      });
      shareSession();
      setPreviousUserEmail(user?.email ?? null);
      checkForGoogleIdToken();

      const copyFromToken = localStorage.getItem(keyCopyFromToken);

      if (!updatedUser.isAnonymous) {
        setCookie(PREV_SIGNED_IN, true, {
          path: '/',
          // expire the cookie in a week
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
      } else {
        deleteCookie(PREV_SIGNED_IN);
      }

      if (!updatedUser.isAnonymous && copyFromToken) {
        const [err, copiedProjects] = await fromPromise(
          copyFromUserTo(updatedUser.id, copyFromToken),
        );

        if (err) return console.error('Error copying projects', err);

        if (copiedProjects) {
          // keep the token until we've successfully copied from a user
          localStorage.removeItem(keyCopyFromToken);

          const updatedId = copiedProjects[query.projectId as string];

          if (window && updatedId) {
            window.location.href = buildProjectIdEditRoute(updatedId as string);
          }
        }
      }
      setAuthSettled(true);
    },
    [isAuthPending, setUserFlags, checkForGoogleIdToken, setPreviousUserEmail, query.projectId],
  );

  const { isLoading: isProcessingAuth, makeRequest: processAuth } = useLoader(_processAuth);

  const { isLoading: isCheckingRedirectResult, makeRequest: checkForRedirect } = useLoader(
    useCallback(async () => {
      const creds = await checkRedirectResult();

      // on a redirected authentication, this happens before onAuthStateChanged!
      // let's not wait for that then...
      if (creds?.user) await processAuth(creds.user);
    }, [processAuth]),
  );

  // See if we have any auth results we need to take care of from a returned
  // redirect via federated authentication
  useEffect(() => {
    checkForRedirect();
  }, [checkForRedirect]);

  useEffect(() => {
    return isFirebaseInitialized ? onAuthStateChanged(getAuth(), processAuth) : undefined;
  }, [processAuth, isFirebaseInitialized]);

  // Update claims when the token updates
  useEffect(() => {
    if (!isFirebaseInitialized) return;

    return onIdTokenChanged(getAuth(), async (firebaseUser) => {
      if (!firebaseUser) {
        setAuthToken(undefined);
        return;
      }

      const tokenResult = await getIdTokenResult(firebaseUser);
      if (tokenResult) {
        setAuthToken(tokenResult.token);
      } else {
        setAuthToken(undefined);
      }
    });
  }, [isFirebaseInitialized]);

  const signUpAnonymously = useCallback<UserCtx['signUpAnonymously']>(() => {
    return handleAuth(_signUpAnonymously());
  }, [handleAuth]);

  const updateEmail = async (newEmail: string, password: string) => {
    if (!isLoggedInUser || !user.email) return new Error('User is not logged in');

    const [updateErr] = await fromPromise(updateEmailPromise(user.email, newEmail, password));

    if (updateErr) return updateErr;

    setUser({ ...user, email: newEmail });
  };

  const updateEmailPromise = async (email: string, newEmail: string, password: string) => {
    // Authenticate with firebase before changing email
    const [authError, user] = await fromPromise(reauthenticateUser(email, password));
    if (authError) throw { data: authError };

    if (!user) return new Error('User is not logged in');

    const [updateError] = await fromPromise(updateUser(user.uid, { email: newEmail }));
    if (updateError) throw updateError;

    // Reload user to get new email
    await user.reload();
  };

  const updatePassword = async (password: string, newPassword: string) => {
    if (!isLoggedInUser || !user.email) return new Error('User is not logged in');

    const [updateErr] = await fromPromise(updateUserPassword(user.email, password, newPassword));

    if (updateErr) return updateErr;
  };

  const isInitializing = !isAuthSettled;

  return (
    <UserContext.Provider
      value={{
        ...memoValues,
        authenticateWithCredential,
        signUpAnonymously,
        setUserFlags,
        updateUserFlags,
        toggleUserFlag,
        isInitializing,
        isLoggedIn,
        isLoggedInUser,
        isLoggedInAnonymously,
        loginStatus,
        hasFlag,
        hasActiveSubscription,
        hoverVoucherState,
        signOut,
        setUser,
        previousUserEmail,
        isProcessing: isCheckingRedirectResult || isProcessingAuth || isCheckingGoogleIdToken,
        authToken,
        // Auth Modals:
        openAuthDialog,
        signUpOnOpen,
        signInOnOpen,
        forgotPwOnOpen,
        closeAuthDialog,
        getFbUser: () => (!isInitializing ? getAuth().currentUser : null),
        updateEmail,
        updatePassword,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

async function copyFromUserTo(id: string, token: string) {
  customEvent('copy-proj-from-anon-user');
  return await copyFromUser(id, token);
}
