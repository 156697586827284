import { isRelativelyPositioned, isAncestor, isSection, isLayoutComponent, } from './component.js';
import { getCornersFromViewState, getRectangleFromCorners, getViewState, unionAndNormalizeRect, } from './geometryHelpers.js';
import { applyToCorners, applyToPoint, compose, decomposeTSR, flipX, flipY, identity, inverse, rotate, rotateDEG, roundToPrecision, scale, toMatrixString, translate, } from './matrix.js';
import { isPage } from './runtime.js';
export const MIN_SCALE = 0.0001;
export class ViewGeometry {
    project;
    view;
    constructor(view) {
        this.view = view;
        // TODO: Code Smell, we only know this is a ProjectComponent through convention
        this.project = view.project;
    }
    /**
     * Return a Matrix that describes the component's transformation relative to its parent.
     * This is a Matrix constructed by the attributes stored on the component itself (x, y, scale, rotation)
     * Applying this matrix to a point in a view's coordinate space will transform it to the view's parent's
     * coordinate space.
     */
    static composeViewMatrix(view, options) {
        options = Object.assign({
            centerOrigin: true,
        }, options);
        if (isRelativelyPositioned(view) && !isLayoutComponent(view)) {
            // relatively positioned views are ignored by the matrix builder.
            // This means their positioned is considered to be the same as their parent.
            // so their matrix is the identity matrix... i.e. no transformations.
            return {
                scope: 'view',
                ...identity(),
            };
        }
        const matrices = [];
        const { x = 0, y = 0, rotation = 0, scale: _scale = 1, width, height, rotateX, rotateY } = view;
        let cx = 0, cy = 0;
        if (options?.centerOrigin) {
            cx = width / 2;
            cy = height / 2;
        }
        // The order here matters.
        // Changing the order of the matrices will change how every component is displayed.
        if (!view.parent?.isFlex() || !options.omitTranslate) {
            matrices.push(translate(x ?? 0, y ?? 0));
        }
        matrices.push(rotateDEG(rotation ?? 0, cx, cy));
        // If we ever allow rotateX/rotateY to be something other than 180 | 0 we'll need to revisit this.
        // Ideally with a dev that knows more trig than me.
        if (rotateX && rotateX === 180 && !options.ignoreFlips) {
            matrices.push(flipX());
            if (options?.centerOrigin) {
                matrices.push(translate(0, -height));
            }
        }
        if (rotateY && rotateY === 180 && !options.ignoreFlips) {
            matrices.push(flipY());
            if (options?.centerOrigin) {
                matrices.push(translate(-width, 0));
            }
        }
        const safeScale = Math.max(MIN_SCALE, _scale);
        matrices.push(scale(safeScale ?? 1, safeScale ?? 1, cx, cy));
        const matrix = compose(matrices);
        matrix.scope = 'view';
        return matrix;
    }
    /**
     * Return the x,y,scale,rotation,height, and width of a viewMatrix.
     * This can be used to convert a Matrix to the values we store on a component.
     * It is the inverse of `composeViewMatrix`
     */
    static decomposeViewMatrix(matrix, centerPoint) {
        let { rotation: { degrees, angle }, scale: { sx, sy }, translate: { tx, ty }, } = decomposeTSR(matrix);
        const newScale = roundToPrecision(sx, 100);
        const { x: cx, y: cy } = centerPoint;
        if (Math.abs(degrees) > 0 || newScale !== 1) {
            // The tx and ty that is decomposed from the Matrix takes the rotation and scale into account.
            // The x and y stored on the component does *not* take rotation or scale into account.
            // This code "reverts" the rotation and scale applied to the tx/ty.
            // The rotation, scale, and "un-rotated/scaled" x/y are then stored on the component.
            const { x, y } = applyToPoint(compose(matrix, inverse(rotate(angle, centerPoint.x, centerPoint.y)), inverse(scale(sx, sx, centerPoint.x, centerPoint.y))), {
                x: 0,
                y: 0,
            });
            tx = x;
            ty = y;
        }
        return {
            width: cx * 2,
            height: cy * 2,
            x: tx,
            y: ty,
            scale: Math.max(roundToPrecision(sx, 100), MIN_SCALE),
            scaleX: Math.max(roundToPrecision(sx, 100), MIN_SCALE),
            scaleY: Math.max(roundToPrecision(sy, 100), MIN_SCALE),
            rotation: degrees,
        };
    }
    /**
     * Given a start View and an end View, build a matrix up the Reactor Hierarchy to until the end is reached.
     * The matrix returned transforms coordinates from the end View's coordinate space to the start View's
     * coordinate space. If the end is never found the Matrix returned will transform from the "global" scope.
     * When we want the reverse, to convert into a particular View's coordinate space from the space of one of
     * its ancestors or the "global scope", we use `inverse(buildMatrixUp(...))`.
     */
    static buildMatrixUp(start, end, options) {
        let component = start;
        let matrix = identity();
        if (!start?.parent) {
            // The start has no parent which is either a big mistake or it's the project.
            // In either case, we can't build a matrix up the hierarchy, so build its own matrix and bail.
            // It is possible that a view might want it's own matrix before it's been mounted and before it is in the hierarchy
            // so we still want to return a valid matrix.
            return compose(ViewGeometry.composeViewMatrix(component, options), matrix);
        }
        let endFound = false;
        let previous = component;
        while (component) {
            if (end === component) {
                endFound = true;
                break;
            }
            matrix = compose(ViewGeometry.composeViewMatrix(component, options), matrix);
            previous = component;
            component = component?.parent;
        }
        if (!endFound && !!end) {
            // The end View was not found in the hierarchy, but an end view was provided.
            // This shouldn't happen, and might result in unexpected math.
            console.error(`End View ${end?.name} was provided but not found in the hierarchy starting at ${start.name}. ${previous?.name} was used instead.
        Is the end View a child of the start View?`);
        }
        return matrix;
    }
    /**
     * Given a View, and a point in rootView scope of the View's parent, determine if the point is within
     * the bounds of the view.
     */
    static isPointWithinViewBounds(view, rootViewScopePoint) {
        const w = view.width || 0;
        const h = view.height || 0;
        const { x: adjustedX, y: adjustedY } = applyToPoint(view.geometry.getRootViewMatrix(), // Convert the rootViewScopePoint to local scope
        rootViewScopePoint);
        return adjustedX >= 0 && adjustedX < w && adjustedY >= 0 && adjustedY < h;
    }
    /**
     * Given an array of Views, return a rectangle bounding all of them.
     * If the array length === 1, the rectangle will hug the View closely and account for rotation.
     * If the array length > 1, the rectangle will not be rotated and will be "normalized".
     */
    static getViewBounds(views, options) {
        if (views.length === 1) {
            return ViewGeometry.getViewRect(views[0], undefined, options);
        }
        else {
            return ViewGeometry.unionViewRects(views);
        }
    }
    /**
     * Given a View, return a rectangle bounding the view and account for scale and rotation
     */
    static getViewRect(view, matrix, options) {
        if (!matrix) {
            matrix = view.geometry.getGlobalMatrix(options);
        }
        const { x, y } = applyToPoint(matrix, { x: 0, y: 0 });
        return {
            x: x ?? 0,
            y: y ?? 0,
            w: view?.width,
            h: view?.height,
            matrix: matrix,
        };
    }
    /**
     * Given an array of Views, return a normalized rectangle bounding all of them.
     */
    static unionViewRects(views, options) {
        let bounds = undefined;
        for (const view of views) {
            const corners = ViewGeometry.getViewCorners(view.geometry.getGlobalMatrix(options), Object.assign(getViewState(view), { x: 0, y: 0 }));
            const rect = getRectangleFromCorners(corners);
            const mergedRect = unionAndNormalizeRect(bounds, rect);
            const newMatrix = translate(mergedRect.x, mergedRect.y);
            newMatrix.scope = 'global';
            bounds = Object.assign(mergedRect, { matrix: newMatrix });
        }
        return bounds;
    }
    /**
     * Given a point in rootView scope, return all Views that are "hit" by that point
     */
    getViewsAtPoint(rootViewScopePoint, sameProject) {
        if (!this.view.visible && !this.project.designMode) {
            return [];
        }
        if (!isSection(this.view) && !isPage(this.view) && isRelativelyPositioned(this.view)) {
            // relatively positioned view's children are not hit tested
            return [];
        }
        let hitComponents = [];
        if (this.view.children) {
            // Hit test components in the reverse order they are painted.
            for (let i = this.view.children.length - 1; i >= 0; i--) {
                const component = this.view.children[i];
                // Caller can specify that they're only interested in same-project components.
                if (!component.geometry || (sameProject && this.project !== component?.project)) {
                    continue;
                }
                const childComponents = component.geometry.getViewsAtPoint({ x: rootViewScopePoint.x, y: rootViewScopePoint.y }, sameProject);
                if (childComponents) {
                    hitComponents = [...hitComponents, ...childComponents];
                }
            }
        }
        if (ViewGeometry.isPointWithinViewBounds(this.view, rootViewScopePoint)) {
            return [...hitComponents, this.view];
        }
        return hitComponents;
    }
    /**
     * Given a Matrix and a View, return the Corners for the View as Points/
     * Be aware! The corners account for rotation and scale!
     * The "top left" corner is a relative term, and may not actually be the top left when rendered.
     * For example, the view is rotated 180 degrees the "top left" will actually point to what would appear to be
     * the bottom right.
     */
    static getViewCorners(matrix, view) {
        return applyToCorners(matrix, getCornersFromViewState(view));
    }
    static viewCornersToVecLike(corners) {
        return [
            { x: corners.tl.x, y: corners.tl.y },
            { x: corners.tr.x, y: corners.tr.y },
            { x: corners.br.x, y: corners.br.y },
            { x: corners.bl.x, y: corners.bl.y },
        ];
    }
    // given an array of vectors, return a point in the center of them
    static vecLikeToCenterPoint(vectors) {
        if (vectors.length === 0) {
            throw new Error('Cannot calculate center point of an empty array');
        }
        let sumX = 0;
        let sumY = 0;
        for (const vec of vectors) {
            sumX += vec.x;
            sumY += vec.y;
        }
        return {
            x: sumX / vectors.length,
            y: sumY / vectors.length,
        };
    }
    static rectToViewState(rect) {
        const { x, y, w, h } = rect;
        return {
            x,
            y,
            width: w,
            height: h,
            rotation: 0,
            scale: 1,
        };
    }
    /**
     * Transforms a view's coordinate space to a new parent's coordinate space.
     */
    transformViewCoordinateSpace(oldParent, newParent, options) {
        // Get the rootView Matrix for the old parent
        const oldMatrix = compose(oldParent.geometry.getRootViewMatrix(options));
        // Get the rootView Matrix for the new parent
        const newMatrix = compose(newParent.geometry.getRootViewMatrix(options));
        // inverse the old parent's matrix. This converts the coordinate space to the rootView coordinate space.
        // Then multiply it by the new parent's rootView matrix, this converts the coordinate space to the local coordinate
        // space for the new parent.
        // Then multiply it by the view's matrix.
        return {
            ...compose(newMatrix, inverse(oldMatrix), ViewGeometry.composeViewMatrix(this.view, options)),
            scope: 'rootView',
        };
    }
    /**
     * Convert a Point from its local View-relative space to a Point in the global scope. If no Point is
     * provided, the transformed origin Point (0, 0) of the current View is returned.
     * Only InteractionDesigner uses this function to get the "global" coordinates of the rootView
     * to base connecting lines (when dragging from interation bolt to a target) on.
     */
    getGlobalCoordinates(point, options) {
        const matrix = this.getGlobalMatrix(options);
        if (!point) {
            point = { x: 0, y: 0 }; // TODO: no MatrixScope. but nobody cares?
        }
        return { ...applyToPoint(matrix, point), matrix };
    }
    /**
     * This turns any View-relative Point into a Point relative to the rootView. Useful for finding the
     * position of a View relative to the rootView instead of its relative position to its parent container.
     */
    localToRootViewCoordinates(point, options) {
        const matrix = this.getRootViewMatrix(options);
        return applyToPoint(inverse(matrix), point ?? { x: 0, y: 0 });
    }
    /**
     * Given a point in client coordinates (e.g. as received via PointerEvent), return the point in
     * local view coordinates taking all transforms and scrolling into account.
     */
    clientToLocalCoordinates(point) {
        const project = this.project;
        let scale = project.rootView.getEffectiveScale();
        // Convert from client coordinates to viewport coordinates.
        let viewportPoint = { x: point.x - project.viewportX, y: point.y - project.viewportY };
        if (project.designMode) {
            // Take PanZoomSurface translation and scale into account.
            // TODO: Fragile. Pass this information from Designer to Project.
            const panZoomSurface = document.getElementById('PanZoomSurface');
            if (panZoomSurface) {
                const rect = panZoomSurface.getBoundingClientRect();
                viewportPoint = { x: point.x - rect.left, y: point.y - rect.top };
                const style = window.getComputedStyle(panZoomSurface);
                const transform = style.transform;
                if (transform.startsWith('matrix(')) {
                    // Remove the 'matrix(' prefix and ')' suffix, then split by comma.
                    const values = transform.slice(7, -1).split(', ');
                    // Parse the scaleX from the matrix.
                    scale = parseFloat(values[0]);
                }
            }
        }
        // Convert from viewport coordinates to rootView coordinates by applying the viewport fit
        // scaling (if any) and rootViewContainter centering. Take scrolling into account too.
        const rootViewPoint = {
            x: viewportPoint.x / scale - project._rootViewContainer.x + project.scrollLeft,
            y: viewportPoint.y / scale - project._rootViewContainer.y + project.scrollTop,
        };
        // Convert from rootView coordinates to local coordinates of the component this `ViewGeometry` is on.
        const matrix = this.getRootViewMatrix();
        return applyToPoint(matrix, rootViewPoint);
    }
    // Convert a RootView coordinate to a local coordinate of the View
    getLocalCoordinatesFromRootView(point) {
        const matrix = this.getRootViewMatrix();
        return applyToPoint(matrix, point);
    }
    /**
     * Return a matrix that transforms this View local coordinates to "global" coordinates.
     */
    getGlobalMatrix(options) {
        const globalMatrix = ViewGeometry.buildMatrixUp(this.view, undefined, options);
        return { ...globalMatrix, scope: 'global' };
    }
    /**
     * Return a Matrix describing the transformation from the rootView to this View.
     */
    getRootViewMatrix(options) {
        if (!isAncestor(this.view, this.project.rootView)) {
            // The view is not a descendant of the rootView, so the matrix is the identity matrix.
            // This can happen if you call getRootViewMatrix on a view that is above the rootView, like
            // project or rootViewContainer
            return {
                ...identity(),
                scope: 'rootView',
            };
        }
        return {
            ...inverse(ViewGeometry.buildMatrixUp(this.view, this.project.rootView, options)),
            scope: 'rootView',
        };
    }
    getViewMatrix(options) {
        return ViewGeometry.composeViewMatrix(this.view, options);
    }
    /**
     * Return a CSS transformation string
     */
    getTransformationString(options) {
        return toMatrixString(ViewGeometry.composeViewMatrix(this.view, options));
    }
    /**
     * Returns the center point of the view
     */
    getCenterPoint() {
        return {
            x: this.view.width / 2,
            y: this.view.height / 2,
        };
    }
}
