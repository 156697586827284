import { forEachObject, newSortKey } from '@playful/utils';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'initializes / re-numbers sortkeys of containers before we ship ALC',
    draft: false,
    // This is safe to do because we haven't given sortKeys to any other components yet.
    // This is prior to enabling auto-layout containers for users.
    // Auto-layout containers allow users to sort the container WITH sortkeys,
    // and WITHOUT changing the order of the children array.
    async migrate(state) {
        forEachObject(state, (container) => {
            if (container.componentType === 'Play Kit/Container') {
                let before = '';
                container.children.forEach((child, i) => {
                    child.sortKey = newSortKey(before);
                    before = child.sortKey;
                });
            }
        });
    },
};
registerMigration(migration, import.meta.url);
