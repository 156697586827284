import { defineStyleConfig } from '@chakra-ui/react';
export const linkTheme = defineStyleConfig({
    defaultProps: {
        colorScheme: 'light',
    },
    baseStyle: ({ colorScheme }) => {
        let linkColor = 'inherit';
        let linkHoverColor = 'inherit';
        let linkFocusColor = 'inherit';
        let linkDisabledColor = 'inherit';
        // Light color scheme is for when a link is on a light background like white,
        // dark is when a link is on a dark background like black
        if (colorScheme === 'light') {
            linkColor = 'purple.500';
            linkHoverColor = 'purple.600';
            linkFocusColor = 'purple.800';
            linkDisabledColor = 'purple.100';
        }
        if (colorScheme === 'dark') {
            linkColor = 'purple.100';
            linkHoverColor = 'purple.200';
            linkFocusColor = 'purple.400';
            linkDisabledColor = 'purple.600';
        }
        return {
            transitionProperty: 'common',
            transitionDuration: 'fast',
            transitionTimingFunction: 'ease-out',
            cursor: 'pointer',
            textDecoration: 'none',
            outline: 'none',
            color: linkColor,
            _hover: {
                textDecoration: 'underline',
                color: linkHoverColor,
            },
            _focus: { boxShadow: 'none', color: linkFocusColor },
            _focusVisible: {
                outline: '0.125rem solid',
                outlineColor: 'gray.800',
                outlineOffset: '0.125rem',
            },
            _disabled: {
                color: linkDisabledColor,
            },
        };
    },
    variants: {
        underline: {
            textDecoration: 'underline',
        },
    },
    sizes: {
        lg: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'medium',
        },
    },
});
